import React from 'react'
import { PageLayoutUpdated } from 'app/views/components/layout/page-layout-updated'
import { EntityDrillPage } from './entity-drill-page'


export function EntityLandingPage(tab: { tab: string }) {
    return (
        <PageLayoutUpdated>
            <EntityDrillPage tab={tab.tab} />
        </PageLayoutUpdated>
    )
}