import { Group, Highlight, Skeleton, Stack, Text } from '@mantine/core'
import React from 'react'

export type KpiProps = {
  title: string
  reportDescription?: string
  amount: number | JSX.Element | string
  percentage?: number
  activeFilter: boolean
  isLoading?: boolean
  event?: () => void
}

const KPI: React.FC<KpiProps> = (
  { title, amount, percentage, activeFilter, event, reportDescription, isLoading = false }: KpiProps,
  key: string | number
) => {
  return (
    <Stack w="100%" key={key} className='summarySection kpiSection' onClick={event ? () => event() : undefined}>
      <Group noWrap w="100%" position='apart'>
        <Skeleton
          animate
          visible={isLoading}
        >
          <Group noWrap className={activeFilter ? 'summaryActivitySelected' : 'summaryActivity'}>
            <Stack>
              <Text className={activeFilter ? 'summaryActivityTitleSelected' : 'summaryActivityTitle'}>{title}</Text>
              <Text className={activeFilter ? 'summaryActivityValueSelected' : 'summaryActivityValue'}>{amount}</Text>
              {percentage !== undefined ? (
                <Group w="100%" align="flex-end" position="center" noWrap>
                </Group>
              ) : reportDescription ? (
                <Highlight
                  color="white"
                  highlight={['above', 'below', 'average']}
                  highlightStyles={() => ({
                    fontWeight: 800,
                    backgroundColor: 'transparent',
                  })}
                >
                  {reportDescription}
                </Highlight>
              ) : null}
            </Stack>
          </Group>

        </Skeleton>
      </Group>
    </Stack>
  )
}

export default KPI
