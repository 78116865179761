import { Dispatch } from 'redux'
import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { addFeePayment, getFeePayments, approveFeePayment, deleteFeePayment, submitFeePayment, rejectFeePayment, editFeePayment } from './actions'
import { FeePaymentParams } from 'app/models/fee-payment-params'


export const loadApprovedFeePayments = async (facilityId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (facilityId) {
    return await client
      .get(`${apiServerUrl}/api/approved-fee-payments/facility?id=${facilityId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/approved-fee-payments`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}
export const loadDraftFeePayment = async (facilityId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (facilityId) {
    return await client
      .get(`${apiServerUrl}/api/draft-fee-payments/facility?id=${facilityId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/draft-fee-payments`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadSubmittedFeePayment = async (
  facilityId: string | undefined
) => {
  const apiServerUrl = config.backendUrl
  if (facilityId) {
    return await client
      .get(`${apiServerUrl}/api/submitted-fee-payments/facility?id=${facilityId}`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/submitted-fee-payments`)
      .then(response => {
        return response.data
      }).catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadFeePayments =
  (facilityId?: string) => async (dispatch: Dispatch) => {
    let allFeePayments: any[] = []

    const draftFeePayments = await loadDraftFeePayment(facilityId)
    const submittedFeePayments = await loadSubmittedFeePayment(facilityId)
    const feePayments = await loadApprovedFeePayments(facilityId)

    const arr = submittedFeePayments.map(
      (a: { feePayment: FeePaymentParams }) => a.feePayment
    )
    const subFeePayments = arr.map((a: FeePaymentParams) => ({
      ...a,
      status: 'Submitted',
    }))

    allFeePayments = draftFeePayments
      .map((feePayment: FeePaymentParams) => ({
        ...feePayment,
        status: 'Draft',
      }))
      .concat(subFeePayments)
      .concat(
        feePayments.map((feePayment: FeePaymentParams) => ({
          ...feePayment,
          status: 'Approved',
        }))
      )

    return dispatch(getFeePayments(allFeePayments))
  
  }

export const saveFeePayment = (newFeePayment: FeePaymentParams, oldFeePayment?:FeePaymentParams) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  if(oldFeePayment){

    return client.put(`${apiServerUrl}/api/draft-fee-payments`, newFeePayment)
    .then(feePayment => {
      dispatch(editFeePayment({ ...feePayment.data }))
      return {success: true, payload: feePayment.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })

  }
  return client
    .post(`${apiServerUrl}/api/draft-fee-payments`, newFeePayment)
    .then(feePayment => {
      dispatch(addFeePayment({ ...feePayment.data, status: 'Draft' }))
      return {success: true, payload: feePayment.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}
export const submitDraftFeePayment = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/draft-fee-payments/submit`, { id })
    .then(feePayment => {
      dispatch(submitFeePayment(feePayment.data))
      return {success: true, payload: feePayment.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}
export const rejectSubmittedFeePayment = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-fee-payments/reject`, { id })
    .then(feePayment => {
      dispatch(rejectFeePayment(feePayment.data))
      return {success: true, payload: feePayment.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const approveSubmittedFeePayment = (id: string) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .post(`${apiServerUrl}/api/submitted-fee-payments/approve`, { id })
    .then(feePayment => {
      dispatch(approveFeePayment(feePayment.data))
      return {success: true, payload: feePayment.data}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}

export const deleteSelectedFeePayment = (feePayment: any) => async (dispatch: Dispatch) => {
  const apiServerUrl = config.backendUrl
  return client
    .delete(`${apiServerUrl}/api/draft-fee-payments?ids[]=${feePayment.id}`)
    .then(() => {
      dispatch(deleteFeePayment(feePayment))
      return {success: true, payload: feePayment}
    }).catch(e => {
      return {success: false, payload: e.response.data.error}
    })
}
