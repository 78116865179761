import { Group } from '@mantine/core'
import PageTitleUpdated from 'app/views/components/Headers&Text/PageTitleUpdated'

export default function MyWorkHeader() {

  return (
    <Group position="apart">
      <PageTitleUpdated>My Work</PageTitleUpdated>
    </Group>
  )
}
