import React, { } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import ServicingHeader from '../servicing-header'
import { ServicingPageData } from '../servicing-page-data'


export function ServicingUnscheduledPage() {

  return (
    <PageLayout>
      <ServicingHeader />
      <ServicingPageData tab='unscheduled' />
    </PageLayout>
  )
}
