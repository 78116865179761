import {
    Group,
    Stack,
} from '@mantine/core'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import LockedDealManagmentPage from '../deals/locked-deals/locked-deals-management-page'
import HistoryToggleButton from 'app/views/components/buttons/HistoryToggleButton'
import LockedFacilitiesPage from '../facilities/locked-facilities/locked-facility-page'


interface props {
    tab: string
}

export function LockedDealDrillPage({ tab }: props) {
    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element; historyLink: string } } = {
        dealsLocked: { route: `/dealmanagement/terminated`, label: 'DEALS', active: tab === 'dealsLocked' ? true : false, pageRender: <LockedDealManagmentPage />, historyLink: '/dealmanagement' },
        facilitiesLocked: { route: `/dealmanagement/facilities/terminated`, label: 'FACILITIES', active: tab === 'facilitiesLocked' ? true : false, pageRender: <LockedFacilitiesPage />, historyLink: '/dealmanagement/facilities' },
    }

    return (
        <Stack w="100%">
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} lockedTabs={{}} />
                </Group>
                <Group noWrap>
                    <HistoryToggleButton navLink={tabs[tab].historyLink} active={true} />
                </Group>
            </Group>
            {tabs[tab].pageRender}
        </Stack>
    )
}