import * as types from './types'
import { createReducer } from 'app/state/utils'

const initialState: {
  approvedLoanRateSettings: any[]
  loanRateSettings: any[]
} = { approvedLoanRateSettings: [], loanRateSettings: [] }

const loanRateSettingsReducer = createReducer(initialState)({
  [types.GET_LOAN_RATE_SETTINGS]: (state: any, payload: any) => {
    return { ...state, loanRateSettings: payload.payload }
  },

  [types.GET_APPROVED_LOAN_RATE_SETTINGS]: (state: any, payload: any) => {
    return { ...state, approvedLoanRateSettings: payload.payload }
  },

  [types.EDIT_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const index = state.loanRateSettings.findIndex(
      (loanRateSetting: { id: any }) =>
        loanRateSetting.id === payload.payload.data.id
    )
    const newArray = [...state.loanRateSettings]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      loans: newArray,
    }
  },

  [types.ADD_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    return {
      ...state,
      loanRateSettings: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.loanRateSettings,
      ],
    }
  },

  [types.SUBMIT_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const obj = state.loanRateSettings.find(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    obj.status = 'Submitted'
    return {
      ...state,
      loanRateSettings: [...state.loanRateSettings],
    }
  },

  [types.APPROVE_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const obj = state.loanRateSettings.find(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    obj.status = 'Approved'

    return {
      ...state,
      approvedLoanRateSettings: [...state.approvedLoanRateSettings, obj],
      loanRateSettings: [...state.loanRateSettings],
    }
  },

  [types.REJECT_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const obj = state.loanRateSettings.find(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    obj.status = 'Draft'
    return {
      ...state,
      loanRateSettings: [...state.loanRateSettings],
    }
  },

  [types.DELETE_LOAN_RATE_SETTING]: (state: any, payload: any) => {
    const index = state.loanRateSettings.findIndex(
      (loanRateSetting: { id: any }) => loanRateSetting.id == payload.payload.id
    )
    if (index >= 0) {
      state.loanRateSettings.splice(index, 1)
    }
    return {
      ...state,
      loanRateSettings: [...state.loanRateSettings],
    }
  },
})

export default loanRateSettingsReducer
