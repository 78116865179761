import { PikParams } from 'app/models/pik-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'


export interface PIKState {
  paymentInKind: PikParams[]
}
const initialState: PIKState = {paymentInKind:[]}

const piksReducer = createReducer(initialState)({
  [types.GET_PIKS]: (state: any, payload: any) => {
    return { ...state, paymentInKind: payload.payload }
  },

  [types.ADD_PIK]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }
    return {
      ...state,
      paymentInKind: [payload.payload, ...state.paymentInKind],
    }
  },

  [types.APPROVE_PIK]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }
    const index = state.paymentInKind.findIndex(
      (paymentInKind: { id: any }) => paymentInKind.id == payload.payload.id
    )
    if (index >= 0) {
      state.paymentInKind.splice(index, 1)
    }
    return {
      ...state,
      paymentInKind: [...state.paymentInKind],
    }
  },
  [types.EDIT_PIK]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }
    const index = state.paymentInKind.findIndex(
      (paymentInKind: { id: any }) => paymentInKind.id == payload.payload.id
    )
    const newArray = [...state.paymentInKind]
    if (index >= 0) {
      newArray[index] = {...payload.payload, status: 'Draft'}
    }
    return {
      ...state,
      paymentInKind: newArray,
    }
  },
  
  [types.REJECT_PIK]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }

    const updatedPik = state.paymentInKind.find(
      (pik: { id: any }) => pik.id === payload.payload.id
    )
    if (updatedPik) {
      updatedPik.status = 'Draft'
    }
    return {
      ...state,
      paymentInKind: [...state.paymentInKind],

    }
  },
  [types.SUBMIT_PIK]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }

    const updatedPik = state.paymentInKind.find(
      (pik: { id: any }) => pik.id === payload.payload.id
    )
    if (updatedPik) {
      updatedPik.status = 'Submitted'
    }
    return {
      ...state,
    }
  },

  [types.DELETE_PIK]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }

    const index = state.paymentInKind.findIndex(
      (paymentInKind: { id: any }) => paymentInKind.id == payload.payload.id
    )
    if (index >= 0) {
      state.paymentInKind.splice(index, 1)
    }
    return {
      ...state,
      paymentInKind: [...state.paymentInKind],
    }
  },
})

export default piksReducer
