import config from 'app/config/config'
import { client } from 'app/shared/api/client'
import { Dispatch } from 'redux'
import {
  getLoanRollovers,
  addLoanRollover,
  editLoanRollover,
  submitLoanRollover,
  approveLoanRollover,
  rejectLoanRollover,
  getApprovedLoanRollovers,
  deleteLoanRollover,
} from './actions'
import { LoanRolloverParams } from 'app/models/loan-rollover-params'

export const saveLoanRollover =
  (savedLoanRollover: LoanRolloverParams, loanRollover?: any) =>
  async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    if (savedLoanRollover.allInRate == '') savedLoanRollover.allInRate = 0.0
    if (savedLoanRollover.margin == '') savedLoanRollover.margin = 0.0
    if (savedLoanRollover.interestBaseRate == '')
      savedLoanRollover.interestBaseRate = 0.0
    if (savedLoanRollover.roundedBaseRate == '')
      savedLoanRollover.roundedBaseRate = 0.0
    if (savedLoanRollover.rounding == '') savedLoanRollover.rounding = null
    if (savedLoanRollover.dayBasis == '') savedLoanRollover.dayBasis = null
    const updated_savedLoanRollover: LoanRolloverParams = {
      ...savedLoanRollover,
    }
    if (loanRollover) {
      return client
        .put(
          `${apiServerUrl}/api/draft-loan-rollovers`,
          updated_savedLoanRollover,
        )
        .then(response => {
          const payload = dispatch(editLoanRollover(response))
          return { success: true, payload: payload.payload }
        })
        .catch(e => {
          return { success: false, payload: e.response.data.error }
        })
    }

    return client
      .post(
        `${apiServerUrl}/api/draft-loan-rollovers`,
        updated_savedLoanRollover,
      )
      .then(response => {
        const payload = dispatch(addLoanRollover(response))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadLoanRolloverId =
  (loanRolloverId: string) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(
        `${apiServerUrl}/api/approved-loan-rollovers/one?id=${loanRolloverId}`,
      )
      .then(response => {
        return dispatch(getApprovedLoanRollovers(response.data))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadLoanRollovers =
  (loanId: string | undefined) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .get(`${apiServerUrl}/api/approved-loan-rollovers/loan?id=${loanId}`)
      .then(response => {
        return dispatch(getApprovedLoanRollovers(response.data))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }
export const loadApprovedLoanRollovers = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-rollovers/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/approved-loan-rollovers`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadDraftLoanRollovers = async (loanId: string | undefined) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-rollovers/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/draft-loan-rollovers`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
}

export const loadSubmittedLoanRollovers = async (
  loanId: string | undefined,
) => {
  const apiServerUrl = config.backendUrl
  if (loanId) {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-rollovers/loan?id=${loanId}`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  } else {
    return await client
      .get(`${apiServerUrl}/api/submitted-loan-rollovers`)
      .then(response => {
        return response.data
      })
      .catch(e => {
        console.log(e)
        return []
      })
  }
}

export const submitForApproval =
  (loanRollover: LoanRolloverParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/draft-loan-rollovers/submit`, {
        id: loanRollover.id,
      })
      .then(response => {
        const payload = dispatch(submitLoanRollover(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const approveSelectedLoanRollover =
  (loanRollover: LoanRolloverParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-rollovers/approve`, {
        id: loanRollover.id,
      })
      .then(response => {
        const payload = dispatch(approveLoanRollover(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const rejectSelectedLoanRollover =
  (loanRollover: LoanRolloverParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .post(`${apiServerUrl}/api/submitted-loan-rollovers/reject`, {
        id: loanRollover.id,
      })
      .then(response => {
        const payload = dispatch(rejectLoanRollover(response.data))
        return { success: true, payload: payload.payload }
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const deleteSelectedLoanRollover =
  (loanRollover: LoanRolloverParams) => async (dispatch: Dispatch) => {
    const apiServerUrl = config.backendUrl
    return client
      .delete(
        `${apiServerUrl}/api/draft-loan-rollovers?ids[]=${loanRollover.id}`,
      )
      .then(() => {
        return dispatch(deleteLoanRollover(loanRollover))
      })
      .catch(e => {
        return { success: false, payload: e.response.data.error }
      })
  }

export const loadAllLoanRollovers =
  (loanId?: string | undefined) => async (dispatch: Dispatch) => {
    let all_loan_rollovers: any[] = []

    const draftLoanRollovers = await loadDraftLoanRollovers(loanId)
    const submittedLoanRollovers = await loadSubmittedLoanRollovers(loanId)
    const loanRollovers = await loadApprovedLoanRollovers(loanId)

    const arr = submittedLoanRollovers.map(
      (a: { loanRollover: LoanRolloverParams }) => a.loanRollover,
    )
    const sub_loan_rollovers = arr.map((a: LoanRolloverParams) => ({
      ...a,
      status: 'Submitted',
    }))

    all_loan_rollovers = draftLoanRollovers
      .map((loanRollover: LoanRolloverParams) => ({
        ...loanRollover,
        status: 'Draft',
      }))
      .concat(sub_loan_rollovers)
      .concat(
        loanRollovers.map((loanRollover: LoanRolloverParams) => ({
          ...loanRollover,
          status: 'Approved',
        })),
      )

    return dispatch(getLoanRollovers(all_loan_rollovers))
  }
