import * as types from './types'

export const getLoanIncreases = (payload: any) => ({
  type: types.GET_LOAN_INCREASES,
  payload,
})

export const addLoanIncrease = (payload: any) => ({
  type: types.ADD_LOAN_INCREASE,
  payload,
})

export const editLoanIncrease = (payload: any) => ({
  type: types.EDIT_LOAN_INCREASE,
  payload,
})

export const submitLoanIncrease = (payload: any) => ({
  type: types.SUBMIT_LOAN_INCREASE,
  payload,
})

export const approveLoanIncrease = (payload: any) => ({
  type: types.APPROVE_LOAN_INCREASE,
  payload,
})

export const rejectLoanIncrease = (payload: any) => ({
  type: types.REJECT_LOAN_INCREASE,
  payload,
})

export const deleteLoanIncrease = (payload: any) => ({
  type: types.DELETE_LOAN_INCREASE,
  payload,
})
