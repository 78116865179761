import { PageLayout } from 'app/views/components/layout/page-layout'
import DealContentPage from './deal-content-page'

export default function SingleDealPage() {
  return (
    <PageLayout>
      <DealContentPage />
    </PageLayout>
  )
}
