import { Stack } from '@mantine/core'
import { NoticePage } from '../../notices/+notices-page'

export enum Tab {
  payments = 'payments',
  notices = 'notices',
}

export enum StatusTab {
  active = 'active',
  archive = 'archive',
}

export default function NoticesPageData() {

  return (
    <Stack>
      <NoticePage />
    </Stack>
  )
}
