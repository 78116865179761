import { PageLayout } from 'app/views/components/layout/page-layout'
import ReleaseQueueHeader from '../notices/release-queue-header'
import { ReleaseQueue } from '../release-queue'

export function LockedReleaseQueuePage() {

  return (
    <PageLayout>
      <ReleaseQueueHeader />
      <ReleaseQueue tab='archived' />
    </PageLayout>
  )
}
