export const tabs = {
  payments: '/releasequeue',
  notices: '/releasequeue/notices',
}

export const lockedTabs = {
  active: '',
  archived: '/archived',
}

export const activeTabs = {
  tab: tabs.notices,
  locked: lockedTabs.active,
}
