import {
  Anchor,
  Breadcrumbs,
  Group,
  Stack,
  Col,
  Grid,
  Table,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { FacilityParams } from 'app/models/facility-params'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { isNotEmpty, useForm } from '@mantine/form'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { lenderEligibilityCriteriaOptions } from 'app/models/dropdown-options'
import { useListState } from '@mantine/hooks'
import TotalAmount from './lender/total-amount.component'
import LenderRow from './lender/lender-row.component'
import { WireInstruction, Entity, Contact, Lender, Assignment } from './params'
import AssignmentRow from './assignment/assignment-row.component'
import ActionButtons from './action-button.component'
import AssignmentSelectBox from './assignment/assignment-selectbox'
import LenderTableHeader from './lender/lender-table-header.component'
import DealFacilityDateSelectBox from './lender/lender-selectbox'
import AssignmentTableHeader from './assignment/assignment-table-header.component'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { AssignmentParams } from 'app/models/assignment-params'
import {
  loadOneAssignment,
  saveAssignment,
  submitForApproval,
} from 'app/state/ducks/assignments/thunks'
import config from 'app/config/config'
import { getAssignment } from 'app/state/ducks/assignments/selectors'
import { v4 as uuidv4 } from 'uuid'
import { getFacilityLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import {
  getAmount,
  loadLendersPositionsByFacility,
} from 'app/state/ducks/lenders-positions/thunks'
import { LendersPosition } from 'app/models/lenders-position-params'
import { resetFacilityLendersPositions } from 'app/state/ducks/lenders-positions/actions'
import useDeleteAssignmentAdjust from './adjust-lender-position/use-adjust-lender-position-delete'
import useAdjustLenderPositionEdit from './adjust-lender-position/use-adjust-lender-position-edit'
import useAdjustLenderPositionAdd from './adjust-lender-position/use-adjust-lender-position-add'
import { useCheckAmount } from './hooks/use-check-amount'
import {
  showErrorMessage,
  findNegativeAmountLenderEdit,
  hasNegativeAmountAfterDeletion,
} from './helpers' // adjust the import path accordingly
import {
  ErrorNotification,
  SuccessNotification,
} from 'app/views/components/notifications/notification'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'

export default function TradeOneHeader() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const deals: DealParams[] = useSelector(getDeals)
  const entities = useSelector(getEntities)
  const pulledWireInstructions: WireInstructionParams[] =
    useSelector(getWireInstructions)
  const contacts = useSelector(getContacts)
  const { data: lendersPositions, isLoading: isLoadingLenders } = useSelector(getFacilityLendersPositionsWithLoading)
  const assignment: AssignmentParams = useSelector(getAssignment)
  const facilities: FacilityParams[] = useSelector(getFacilities)
  const businessDate = useSelector(getBusinessDate)

  const dealsOptions = deals
    ? deals
      .filter(deal => deal.status === 'Approved')
      .map(deal => ({ label: deal.dealName, value: deal.id ?? '' }))
    : []
  const [selectedFacility, setSelectedFacility] =
    useState<FacilityParams | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(businessDate)
  const [updatedLendersPositions, updatedLendersPositionsHandler] =
    useListState<LendersPosition>([])
  const facilitiesOptions = facilities
    ? facilities
      .filter(facility => facility.status === 'Approved')
      .map(facility => ({
        label: facility.name,
        value: facility.id ?? '',
        dealid: facility.dealId.id,
      }))
    : []
  const [assignmentsList, assignmentsListHandler] = useListState(
    [] as Assignment[]
  )
  const [selectedRow, setSelectedRow] = useState(null)
  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { status, id } = useParams()
  const { checkAmountBiggerEdit, checkAmountBiggerAdd } = useCheckAmount({
    updatedLendersPositions,
    assignmentsList,
    selectedRow,
  })

  const cleanForm = () => ({
    id: undefined,
    dealId: '',
    facilityId: '',
    effectiveDate: businessDate,
    assignment: {
      from: '',
      to: '',
      amount: 0,
      eligibility: '',
      id: '',
    },
    hasError: false,
    ErrorMessage: '',
    customer_token: config.company.toUpperCase() ?? '',
  })

  const items = [
    { title: 'Trade Closing', href: '/tradeclosing' },
    { title: assignment?.id },
  ].map((item, index) => (
    <Anchor
      onClick={item.href ? () => navigate(item.href) : () => null}
      key={index}
      color="#374151"
      fw={500}
      fz="14px"
    >
      {item.title}
    </Anchor>
  ))

  useEffect(() => {
    if (id) {
      dispatch(loadOneAssignment(id ?? '', status ?? ''))
    }
  }, [id])

  useEffect(() => {
    if (!assignment || !id) {
      form.setValues(cleanForm())
      assignmentsListHandler.setState([])
      return
    }

    const {
      id: assignmentId,
      dealId,
      facilityId,
      effectiveDate,
      trades,
    } = assignment

    const formAssignment = {
      id: assignmentId,
      dealId: dealId.id,
      facilityId: facilityId.id,
      effectiveDate: stringToDate(effectiveDate),
    }
    setSelectedDate(stringToDate(effectiveDate))
    form.setValues(formAssignment)

    const tradesList = trades.map(trade => ({
      from: trade.from.id,
      to: trade.to.id,
      amount: Number(trade.amount),
      eligibility: trade.eligibility,
      id: trade.id as string,
    }))

    assignmentsListHandler.setState(tradesList)

    dispatch(loadLendersPositionsByFacility(facilityId.id))
  }, [assignment, id])

  useEffect(() => {
    if (!assignment || !facilities) return
    const facility = facilities.find(
      facility => facility.id === assignment.facilityId.id
    )
    if (facility) setSelectedFacility(facility)
  }, [assignment, facilities])

  useEffect(() => {
    dispatch(loadEntities())
    dispatch(loadWireInstructions())
    dispatch(loadContacts())
    dispatch(resetFacilityLendersPositions())
    updatedLendersPositionsHandler.setState([])
    adjustTableHeight()
    // Handle window resize event
    window.addEventListener('resize', adjustTableHeight)
    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', adjustTableHeight)
    }
  }, [])

  const adjustTableHeight = () => {
    if (tableContainerRef.current) {
      const offsetFromTop =
        tableContainerRef.current.getBoundingClientRect().top
      const availableHeight = (window.innerHeight - offsetFromTop) / 1.5
      tableContainerRef.current.style.maxHeight = `${availableHeight}px`
    }
  }

  useEffect(() => {
    if (!lendersPositions || isLoadingLenders) {
      updatedLendersPositionsHandler.setState([])
      return
    }
    const newLenderList = lendersPositions.map(lp => {
      return {
        ...lp,
        amount: lp.proRatas ? '' + getAmount(lp, selectedDate) : '0',
      }
    })

    if (assignmentsList && status !== 'Approved') {
      assignmentsList.forEach(trade => {
        const toId = trade.to
        const existsInLendersPosition = lendersPositions.some(
          position => position.lender.id === toId
        )
        if (!existsInLendersPosition) {
          newLenderList.push(createNewLenderPosition(trade, trade.to))
        }
        for (let j = 0; j < newLenderList.length; j++) {
          if (trade.from === newLenderList[j].lender.id) {
            newLenderList[j].amount = (
              Number(newLenderList[j].amount) - Number(trade.amount)
            ).toString()
          }
          if (
            trade.to === newLenderList[j].lender.id &&
            existsInLendersPosition
          ) {
            newLenderList[j].amount = (
              Number(newLenderList[j].amount) + Number(trade.amount)
            ).toString()
          }
        }
      })
    }
    updatedLendersPositionsHandler.setState(newLenderList)
  }, [lendersPositions, selectedDate, isLoadingLenders])

  const form = useForm({
    initialValues: {
      id: assignment?.id ?? undefined,
      dealId: assignment?.dealId?.id ?? '',
      facilityId: assignment?.facilityId?.id ?? '',
      effectiveDate: assignment?.effectiveDate
        ? stringToDate(assignment?.effectiveDate)
        : businessDate,
      assignment: cleanForm().assignment,
      hasError: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase() ?? '',
    },
    transformValues: values => {
      return {
        ...values,
        dealId: {
          id: values.dealId,
          admin: deals.find(deal => deal.id === values.dealId)
            ?.accountManagementAdmin,
        },
        facilityId: {
          id: selectedFacility?.id ?? '',
          admin: selectedFacility?.accountManagementAdmin ?? '',
        },
        effectiveDate: formatDateToUTC(values.effectiveDate),
        trades: assignmentsList.map(assignment => ({
          from: {
            id: assignment.from,
            admin: entities.find(entity => entity.id === assignment.from)
              ?.maker,
          },
          to: {
            id: assignment.to,
            admin: entities.find(entity => entity.id === assignment.to)?.maker,
          },
          amount: assignment.amount,
          eligibility: assignment.eligibility,
          id: assignment.id,
        })),
      }
    },
    validate: {
      facilityId: isNotEmpty('Facility cannot be empty'),
      dealId: isNotEmpty('Deal cannot be empty'),
      effectiveDate: isNotEmpty('Deal cannot be empty'),
    },
  })

  const findEntityName = (id: string) => {
    return entities?.find(entity => entity.id === id)?.entityName ?? ''
  }

  const findEntityPublic = (id: string) => {
    return entities?.find(entity => entity.id === id)?.maker ?? ''
  }

  const findContactId = (id: string) => {
    return contacts?.find(contact => contact.entity.id === id)?.id ?? ''
  }

  const findWireInstructionId = (id: string) => {
    return (
      pulledWireInstructions?.find(
        wireInstruction => wireInstruction.entity.id === id
      )?.id ?? ''
    )
  }

  const handleDealChange = (dealId: string) => {
    form.setFieldValue('dealId', dealId)
    form.setFieldValue('facilityId', '')
    resetLendersPosition()
  }
  const handleDateChange = (date: Date) => {
    form.setFieldValue('effectiveDate', date)
    setSelectedDate(date)
    resetLendersPosition()
  }

  const handleFromChange = (entityId: string) => {
    form.setFieldValue('assignment.from', entityId)
    if (form.values.assignment.to === entityId) {
      form.setFieldValue('assignment.to', '')
    }
  }

  const handleFacilityChange = (facilityId: string) => {
    const facility = facilities.find(facility => facility.id === facilityId)
    if (!facility) return
    dispatch(loadLendersPositionsByFacility(facility.id))
    form.setFieldValue('facilityId', facilityId)
    setSelectedFacility(facility)
    resetLendersPosition()
  }

  const resetLendersPosition = () => {
    form.setFieldValue('assignment.from', '')
    form.setFieldValue('assignment.to', '')
    form.setFieldValue('assignment.amount', 0)
    form.setFieldValue('assignment.eligibility', '')
    form.setFieldValue('assignment.id', '')
  }

  const createNewLenderPosition = (trade: any, id: string) => {
    const toId = id
    return {
      lender: { id: toId, admin: findEntityPublic(toId) ?? '' },
      lenderName: findEntityName(toId),
      amount: trade.amount.toString(),
      percentage: '',
      effectiveDate: formatDateToUTC(form.values.effectiveDate),
      id: uuidv4(),
      referenceId: '',
      creditContactId: null,
      adminContactId: null,
      wireInstructions: [],
    }
  }

  const hanldeLendersPosition = () => {
    if (!lendersPositions) {
      return null
    }
    return updatedLendersPositions.map((lender, index) => (
      <LenderRow
        key={index}
        lender={lender as unknown as Lender}
        entities={entities as Entity[]}
        pulledWireInstructions={pulledWireInstructions as WireInstruction[]}
        contacts={contacts as Contact[]}
        currency={selectedFacility?.baseCurrency ?? 'USD'}
      />
    ))
  }

  const handleAssignments = () => {
    return assignmentsList.map((item: any, index: any) => {
      return (
        <AssignmentRow
          key={index}
          index={index}
          item={item}
          selectedRow={selectedRow}
          currency={selectedFacility?.baseCurrency ?? 'USD'}
          onRowClick={() => {
            handleRowClick(index)
          }}
          entities={entities as Entity}
        />
      )
    })
  }

  const checkFields = () => {
    if (
      form.values.assignment.from === '' ||
      form.values.assignment.to === '' ||
      form.values.assignment.amount === 0 ||
      form.values.assignment.eligibility === '' ||
      form.values.assignment.id === ''
    ) {
      return false
    } else {
      return true
    }
  }

  const adjustLenderPositionAdd = useAdjustLenderPositionAdd({
    updatedLendersPositions: updatedLendersPositions,
    updatedLendersPositionsHandler: updatedLendersPositionsHandler,
    createNewLenderPosition: createNewLenderPosition,
  })

  const addAssignmentAdjust = (newAssignment: Assignment) => {
    adjustLenderPositionAdd(newAssignment)
  }

  const createAssignment = () => {
    const { assignment } = form.values

    if (!checkFields()) {
      showErrorMessage('Add all fields', 'Please add all fields')
      return
    }

    if (checkAmountBiggerAdd(assignment)) {
      showErrorMessage(
        'Add failed',
        'Amount is bigger than the lender position amount'
      )
      return
    }

    assignmentsListHandler.append(assignment)
    addAssignmentAdjust(assignment)
    resetLendersPosition()
    setSelectedRow(null)
    form.setValues({ assignment: cleanForm().assignment })
  }

  const adjustLenderPositionEdit = useAdjustLenderPositionEdit({
    updatedLendersPositions: updatedLendersPositions,
    updatedLendersPositionsHandler: updatedLendersPositionsHandler,
    assignmentsList: assignmentsList,
    selectedRow: selectedRow,
    createNewLenderPosition: createNewLenderPosition,
  })

  const editAssignment = () => {
    const { assignment } = form.values
    const currentAssignment = assignmentsList[selectedRow ?? 0]

    const negativeAmount = findNegativeAmountLenderEdit(
      updatedLendersPositions,
      assignmentsList,
      selectedRow,
      assignment
    )

    if (negativeAmount) {
      showErrorMessage(
        'Error edit',
        'This Row cannot be edited, lender cannot have negative amount'
      )
      return
    }

    if (assignment.from === currentAssignment.to) {
      showErrorMessage('Edited Failed', `This Lender Can't be edited`)
      return
    }

    if (!checkFields()) {
      showErrorMessage('Add all fields', 'Please add all fields')
      return
    }

    if (checkAmountBiggerEdit(assignment)) {
      showErrorMessage(
        'Edit failed',
        'Amount is bigger than the lender position amount'
      )
      return
    }

    if (selectedRow !== null) {
      assignmentsListHandler.setItem(selectedRow, assignment)
      adjustLenderPositionEdit(assignment)
      setSelectedRow(null)
    }

    form.setValues({ assignment: cleanForm().assignment })
  }

  const deleteAssignmentAdjust = useDeleteAssignmentAdjust({
    updatedLendersPositions: updatedLendersPositions,
    updatedLendersPositionsHandler: updatedLendersPositionsHandler,
    assignmentsList: assignmentsList,
    selectedRow: selectedRow,
    findEntityName: findEntityName,
    findContactId: findContactId,
    findWireInstructionId: findWireInstructionId,
    createNewLenderPosition: createNewLenderPosition,
  })

  const deleteAssignment = () => {
    const selectedAssignment = assignmentsList[selectedRow ?? 0]
    const { assignment } = form.values

    if (
      hasNegativeAmountAfterDeletion(
        updatedLendersPositions,
        selectedAssignment,
        Number(assignment.amount)
      )
    ) {
      showErrorMessage(
        'Error delete',
        'This Row cannot be deleted; the lender cannot have a negative amount.'
      )
    } else if (selectedRow !== null) {
      assignmentsListHandler.remove(selectedRow)
      deleteAssignmentAdjust(selectedAssignment)
      form.setValues({ assignment: cleanForm().assignment })
      setSelectedRow(null)
    }
  }

  const handleRowClick = (index: any) => {
    if (assignmentsList[index]?.from) {
      form.setFieldValue('assignment', assignmentsList[index])
      form.values.assignment = assignmentsList[index]
      setSelectedRow(index)
    }
  }

  const createDraftAssignment = async () => {
    try {
      const assignmentParams: any = form.getTransformedValues()
      const isNewAssignment = !id // Check if it's a new assignment based on the existence of id
      const response = isNewAssignment
        ? await dispatch(saveAssignment(assignmentParams, []))
        : await dispatch(saveAssignment(assignmentParams, assignment))

      const successMessage = isNewAssignment
        ? 'Successful Assignment Created'
        : 'Successful Assignment Edited'

      SuccessNotification({
        title: successMessage,
        message: successMessage,
      })
      return response
    } catch {
      ErrorNotification({
        title: 'Error',
        message: 'Please Check Inputs',
      })
      return null
    }
  }

  const onSubmit = async () => {
    setIsLoading(true)
    const result = await createDraftAssignment()
    setIsLoading(false)
    if (result) {
      close()
      navigate('/tradeclosing')
    }
  }

  const saveAndSubmit = async () => {
    setIsLoading(true)
    const response: any = await createDraftAssignment()
    try {
      if (!response) {
        return
      }
      const result: any = await dispatch(submitForApproval(response.payload))
      if (result.success) {
        SuccessNotification({
          title: 'Successful Assignment Submitted',
          message: 'Assignment subbmitted succesfuly',
        })
      } else {
        ErrorNotification({
          title: 'Submitted failed',
          message: result.payload ?? 'Assignment created but not submitted. Please Check Inputs',
        })
      }
      close()
    } catch {
      ErrorNotification({
        title: 'Submitted failed',
        message: 'Assignment created but not submitted. Please Check Inputs',
      })
    } finally {
      setIsLoading(false)
      navigate('/tradeclosing')
    }
  }

  return (
    <Stack>
      <Breadcrumbs
        separator={<IonIcon name="chevron-forward-outline" />}
        mt="xs"
      >
        {items}
      </Breadcrumbs>
      <Group noWrap position="apart">
        <PageTitle
          text={
            status === 'Submitted' || status === 'Approved'
              ? 'View Assignment'
              : assignment && status
                ? 'Edit Assignment'
                : 'Create Assignment'
          }
        />
      </Group>

      <div className="content">
        <form onSubmit={form.onSubmit(() => onSubmit())}>
          <div className="create-new">
            <Grid gutter="lg">
              <Col span={6}>
                <div style={{ height: '100%' }}>
                  <DealFacilityDateSelectBox
                    form={form}
                    dealsOptions={dealsOptions}
                    facilitiesOptions={facilitiesOptions}
                    onDealChange={handleDealChange}
                    onFacilityChange={handleFacilityChange}
                    onDateChange={handleDateChange}
                    disabled={assignmentsList.length > 0}
                  />
                  {updatedLendersPositions &&
                    updatedLendersPositions.length > 0 ? (
                    <Table fontSize="xl" style={{ marginTop: '10px' }}>
                      <LenderTableHeader />
                      <tbody>{hanldeLendersPosition()}</tbody>
                      <tbody>
                        <TotalAmount
                          total={
                            updatedLendersPositions.reduce(
                              (sum, lender) => sum + Number(lender.amount),
                              0
                            ) || 0
                          }
                          currency={selectedFacility?.baseCurrency ?? 'USD'}
                        />
                      </tbody>
                    </Table>
                  ) : null}
                </div>
              </Col>
              <Col span={6}>
                <div style={{ marginLeft: '10px' }}>
                  <p style={{ fontSize: '2vw', marginTop: '15px' }}>
                    {' '}
                    Assignment Creation
                  </p>
                  <AssignmentSelectBox
                    form={form}
                    lendersPosition={updatedLendersPositions}
                    entities={entities}
                    onFromChange={handleFromChange}
                    currency={selectedFacility?.baseCurrency ?? 'USD'}
                    lenderEligibilityCriteriaOptions={
                      lenderEligibilityCriteriaOptions
                    }
                    disabled={status === 'Submitted' || status === 'Approved'}
                  />
                  <ActionButtons
                    createAssignment={createAssignment}
                    editAssignment={editAssignment}
                    deleteAssignment={deleteAssignment}
                    disabled={status === 'Submitted' || status === 'Approved'}
                    disabledEditDelete={selectedRow === null}
                  />
                  <Group noWrap position="apart">
                    <p
                      style={{
                        marginTop: '15px',
                        fontSize: '2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Summary of Assignments
                    </p>
                  </Group>
                  <div style={{ overflowY: 'auto' }} ref={tableContainerRef}>
                    <Table fontSize="xl" style={{ marginTop: '10px' }}>
                      <AssignmentTableHeader />
                      <tbody>{handleAssignments()}</tbody>
                    </Table>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '15px',
                    }}
                  >
                    <PrimaryButton
                      loading={isLoading}
                      type="submit"
                      disabled={
                        !form.isValid() ||
                        status === 'Submitted' ||
                        status === 'Approved' ||
                        assignmentsList.length === 0
                      }
                      text="Save"
                    />
                    <PrimaryButton
                      loading={isLoading}
                      onClick={() => saveAndSubmit()}
                      disabled={
                        !form.isValid() ||
                        status === 'Submitted' ||
                        status === 'Approved' ||
                        assignmentsList.length === 0
                      }
                      text="Submit"
                    />
                  </div>
                </div>
              </Col>
            </Grid>
          </div>
        </form>
      </div>
    </Stack>
  )
}
