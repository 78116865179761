import { Accordion, Anchor, Stack, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { useNavigate, useParams } from 'react-router-dom'

export default function RelatedFacilitiesAccordion() {
  const dispatch = useDispatch()
  const { facilityId } = useParams()
  const { dealId } = useParams()

  const facilities: FacilityParams[] = useSelector(getFacilities)
  const [relatedFacilities, setRealatedFacilities] = useState<FacilityParams[]>(
    []
  )
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(loadAllFacilities())
  }, [])

  useEffect(() => {
    if (Array.isArray(facilities)) {
      if (facilityId) {
        const facility = facilities?.find(f => f.id === facilityId)
        setRealatedFacilities(
          facilities?.filter(
            f => f.dealId.id === facility?.dealId.id && f.id !== facilityId
          )
        )
      }
      if (dealId) {
        setRealatedFacilities(facilities?.filter(f => f.dealId.id === dealId))
      }
    }
  }, [facilities, facilityId, dealId])

  return (
    <Accordion.Item value="related Facilities">
      <div></div>
      <Accordion.Control bg="#A4CAFE" color="black" className="accordionLight">
        Related Facilities
      </Accordion.Control>

      <Accordion.Panel>
        <Stack>
          {relatedFacilities?.map((f, index) => {
            return (
              <Anchor
                key={'related-facilities-accordion-' + index}
                onClick={() =>
                  navigate(
                    '/dealmanagement/facilities/' + f.id + '/' + f.status
                  )
                }
              >
                <Text fw={600} fz="16px" color="black">
                  {index + 1}. {f.name ?? f.id}
                </Text>
              </Anchor>
            )
          })}
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
