export const GET_LOAN_RATE_SETTINGS =
  'loan_rate_settings/GET_LOAN_RATE_SETTINGS'
export const ADD_LOAN_RATE_SETTING = 'loan_rate_settings/ADD_LOAN_RATE_SETTING'
export const EDIT_LOAN_RATE_SETTING =
  'loan_rate_settings/EDIT_LOAN_RATE_SETTING'
export const SUBMIT_LOAN_RATE_SETTING =
  'loan_rate_settings/SUBMIT_LOAN_RATE_SETTING'
export const APPROVE_LOAN_RATE_SETTING =
  'loan_rate_settings/APPROVE_LOAN_RATE_SETTING'
export const REJECT_LOAN_RATE_SETTING =
  'loan_rate_settings/REJECT_LOAN_RATE_SETTING'
export const GET_APPROVED_LOAN_RATE_SETTINGS =
  'loan_rate_settings/GET_APPROVED_LOAN_RATE_SETTINGS'
export const DELETE_LOAN_RATE_SETTING =
  'loan_rate_settings/DELETE_LOAN_RATE_SETTING'
