import { Accordion, Text } from '@mantine/core'
import { CleanedBorrowersData, OneWireInstructionProps } from './borrowers-wireinstructions-params'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { WireInstructionParams } from 'app/models/wire-instruction-params';
import { getColumnDefs } from './table-columnDefs-borrower';

export default function OneWireInstructionAccordion({
  item,
}: OneWireInstructionProps) {

  function cleanBorrowers(
    borrower: WireInstructionParams,
  ): CleanedBorrowersData[] {
    const cleanedBorrower: CleanedBorrowersData = {
      'Currency': borrower.currency,
      'Bank Aba#': borrower.accountWithInstitutionBankAba,
      'AcctInstitutionName': borrower.accountWithInstitutionName,
      'Customer Name': borrower.beneficiaryCustomerName,
      'Acct#': borrower.beneficiaryAccountNumber,
      'Payment Reference': borrower.paymentReference,
      'Special Instructions': borrower.specialInstructions,
    };
    return [cleanedBorrower];
  }
  return (
    <>
      <Accordion.Control fw={400} fz="12px">
        <Text
          fz="14px"
          fw={800}
          sx={{
            fontFamily: 'Plus Jakarta Sans',
          }}
          style={{ backgroundColor: 'rgb(164, 202, 254)' }}
        >
          {item.currency + ` - ` + item.accountWithInstitutionName}
        </Text>
      </Accordion.Control>
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
        <Accordion.Panel>
          <TableColumnClickExclusion
            tableName='Borrowers'
            csvExportEnabled={false}
            columnDefs={getColumnDefs()}
            data={cleanBorrowers(item)}
            withPagination={false}
            minHeight={'100px'}
            setRow={() => {
            }}
          />
        </Accordion.Panel>
      </div>
    </>
  )
}