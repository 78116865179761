export const GET_PRINCIPAL_PAYMENTS = 'principalPayments/GET_PRINCIPAL_PAYMENTS'
export const ADD_PRINCIPAL_PAYMENT = 'principalPayments/ADD_PRINCIPAL_PAYMENT'
export const EDIT_PRINCIPAL_PAYMENT = 'principalPayments/EDIT_PRINCIPAL_PAYMENT'
export const SUBMIT_PRINCIPAL_PAYMENT =
  'principalPayments/SUBMIT_PRINCIPAL_PAYMENT'
export const APPROVE_PRINCIPAL_PAYMENT =
  'principalPayments/APPROVE_PRINCIPAL_PAYMENT'
export const REJECT_PRINCIPAL_PAYMENT =
  'principalPayments/REJECT_PRINCIPAL_PAYMENT'
export const GET_APPROVED_PRINCIPAL_PAYMENTS =
  'principalPayments/GET_APPROVED_PRINCIPAL_PAYMENTS'
export const DELETE_PRINCIPAL_PAYMENT =
  'principalPayments/DELETE_PRINCIPAL_PAYMENT'
