export const GET_FACILITIES = 'facilities/GET_FACILITIES'
export const GET_FACILITY = 'facilities/GET_FACILITY'
export const ADD_FACILITIES = 'facilities/ADD_FACILITIES'
export const EDIT_FACILITIES = 'facilities/EDIT_FACILITIES'
export const SUBMIT_FACILITY = 'facilities/SUBMIT_FACILITY'
export const REJECT_FACILITY = 'facilities/REJECT_FACILITY'
export const APPROVE_FACILITY = 'facilities/APPROVE_FACILITY'
export const DELETE_FACILITY = 'facilities/DELETE_FACILITY'
export const GET_FACILITY_HISTORY = 'facilities/GET_HISTORY'
export const GET_LOCKED_FACILITIES = 'facilities/GET_LOCKED_FACILITIES'
export const START_LOADING_FACILITIES = 'facilities/START_LOADING_FACILITIES'
export const STOP_LOADING_FACILITIES = 'facilities/STOP_LOADING_FACILITIES'
