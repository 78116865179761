import { NumberInput, Stack, Group } from '@mantine/core'
import React, { useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import Select from '../inputs/Select'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { EntityParams } from 'app/models/entity-params'
import { getFacilityLendersPositionsWithLoading } from 'app/state/ducks/lenders-positions/selectors'
import { changeAllLendersForFacility } from 'app/state/ducks/lenders-positions/thunks'
import { StableKey } from 'app/models/common-types'
import { getFacilityAmendment } from 'app/state/ducks/facility-amendments/selectors'
import { FacilityParams } from 'app/models/facility-params'
import { saveFacilityAmendment } from 'app/state/ducks/facility-amendments/thunks'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { formatDateToUTC, formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'
import FormWrapper from '../Form/FormWrapper'
import IonIcon from '@reacticons/ionicons'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

type Props = {
  facility: any
  setFacility: any
  amendment?: string | null
}

interface ILendersArray {
  label: string
  value: string
  disabled: boolean
}

export interface ChildManageLendersRef {
  handleClick: () => void
}

const defaultValue = {
  id: null,
  percentage: '',
  amount: 0,
  lenderName: '',
  lenders: { id: '' },
  creditContactId: null,
  adminContactId: null,
  wireInstructions: [],
}

const ManageLenders: React.ForwardRefRenderFunction<
  ChildManageLendersRef,
  Props
> = ({ facility, amendment, setFacility }, ref) => {
  const dispatch = useDispatch()
  const businessDate = useSelector(getBusinessDate)
  const entities: EntityParams[] = useSelector(getEntities)
  const facilityAmendment: FacilityParams = useSelector(getFacilityAmendment)
  const { data: lendersPositionsData, isLoading: isLoadingLenders } = useSelector(
    getFacilityLendersPositionsWithLoading
  )
  let lendersPositions = lendersPositionsData
  let disableLenderPositions = false

  if (
    facility &&
    facility.amendmentLenders &&
    facility.amendmentLenders.length > 0
  ) {
    lendersPositions = facility.amendmentLenders
  } else {
    lendersPositions.map(lp => {
      if (lp.proRatas) {
        const prs = lp.proRatas
        prs.sort(function (a, b) {
          const arr1 = a.effectiveDate.split('-')
          const arr2 = b.effectiveDate.split('-')
          const date1 = new Date(
            parseInt(arr1[0]),
            parseInt(arr1[1]),
            parseInt(arr1[2])
          )
          const date2 = new Date(
            parseInt(arr2[0]),
            parseInt(arr2[1]),
            parseInt(arr2[2])
          )
          return date1.getTime() - date2.getTime()
        })
        const latestProRata = prs[prs.length - 1]
        if (stringToDate(facility?.startDate) < stringToDate(latestProRata?.effectiveDate))
          disableLenderPositions = true
        lp.amount = latestProRata.amount
      }
    })
  }

  const [lendersArray, setLendersArray] = useState<ILendersArray[]>([])

  const handleClick = async () => {
    const transformedPositions = form.getTransformedValues().lendersPosition
    if (disableLenderPositions) return
    if (form.validate().hasErrors === true) {
      throw Error
    } else {
      let response: any
      if (amendment) {
        const amendmentLenders = transformedPositions.map(
          (lp: { lender: StableKey; amount: string; id?: string }) => {
            return { lender: lp.lender, amount: lp.amount, id: lp.id }
          }
        )
        response = await dispatch(
          saveFacilityAmendment(
            {
              ...facility,
              amendmentDate: facility.amendmentDate ?? formatDateToUTC(businessDate),
              amendmentLenders: amendmentLenders,
              amendmentAmortization: facility.amendmentAmortization ?? null,
              amendmentFees: facility.amendmentFees ?? [],
            },
            facilityAmendment
          )
        )
        if (response?.success === false) {
          ErrorNotification({
            title: ' Facility Amendment Failed',
            message:
              response?.payload || 'Check Lender Allocations and try again',
          })
          return
        }
        setFacility({
          ...facility,
          amendmentLenders: amendmentLenders,
        })
        SuccessNotification({
          title: 'Successful Facility Amendment',
          message:
            'You can amend Lender Allocations, Interest Rate Options, Fees, and Amortization Schedules',
        })
        return
      }
      try {
        await dispatch(
          changeAllLendersForFacility(transformedPositions, {
            id: facility.id,
            admin: facility.accountManagementAdmin,
          })
        )
        SuccessNotification({
          title: 'Successful Facility Creation',
          message:
            'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
        })
      } catch (error) {
        console.error(error)
        ErrorNotification({
          title: ' Facility Creation Failed',
          message: 'Check Lender Allocations and try again',
        })
      }
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  React.useEffect(() => {
    const lendersPosition =
      lendersPositions?.length > 0
        ? lendersPositions.map(
          (lp: { lender: StableKey; amount: string; id?: string }) => ({
            lenders: lp.lender,
            amount: Number(lp.amount),
            id: lp.id,
          })
        )
        : [{ ...defaultValue }]
    const subtotal =
      lendersPositions.length > 0
        ? lendersPositions.reduce(
          (accumulator: number, lp: { amount: string }) =>
            accumulator + Number(lp.amount),
          0
        )
        : 0
    form.setFieldValue('lendersPosition', lendersPosition)
    form.setFieldValue('subtotal', subtotal)
  }, [lendersPositions])

  React.useEffect(() => {
    const lenderallocations =
      entities && entities.length > 0
        ? entities
          .filter(entity =>
            entity.entityProfile.some(profile =>
              [
                'LenderAgency',
                'LenderCounterParty',
                'LenderCounterSub',
                'LenderCreditBenficiary',
              ].includes(profile)
            )
          )
          .map(entity => ({
            value: entity.id || '',
            label: entity.entityName,
            disabled: false,
          }))
        : []
    setLendersArray(lenderallocations)
  }, [facility, lendersPositions])

  const form = useForm({
    initialValues: {
      lendersPosition:
        lendersPositions?.length > 0
          ? lendersPositions.map(
            (lp: { lender: StableKey; amount: string; id?: string }) => ({
              lenders: lp.lender,
              amount: Number(lp.amount),
              id: lp.id,
            })
          )
          : [{ ...defaultValue }],
      subtotal:
        lendersPositions.length > 0
          ? lendersPositions.reduce(
            (accumulator: number, lp: { amount: string }) =>
              accumulator + Number(lp.amount),
            0
          )
          : 0,
    },

    transformValues: values => ({
      ...values,
      lendersPosition: values.lendersPosition.map(
        (a: { lenders: { id: string }; amount: number }) => {
          return {
            ...a,
            lender: {
              id: a.lenders.id,
              admin:
                entities.find(entity => entity.id === a.lenders.id)?.maker ??
                '',
            },
            amount: a.amount.toString(),
          }
        }
      ),
    }),

    validate: {
      lendersPosition: {
        amount: value => (value >= 0 ? null : 'Invalid amount'),
        lenders: { id: isNotEmpty(' Choose a lender') },
      },
      subtotal: value => (value <= facility?.amount ? null : 'Cannot be greater than Facility'),
    },
  })

  form.values.subtotal = form.values?.lendersPosition
    .map(lp => (lp.amount ? lp.amount : 0))
    .reduce(
      (accumulator: number, currentValue: number) => accumulator + currentValue,
      0
    )
  const facilityAmount = Number(facility?.amount)
  const selectedOptions = form.values.lendersPosition?.map(
    (item: { lenders: { id: string } }) => item.lenders.id
  )
  const fields = form.values.lendersPosition?.map(
    (
      item: { lenders: { id: string }; newField?: boolean | null },
      index: number
    ) => {
      const selectedOption = item.lenders.id
      const updatedOptions = lendersArray.map(option => {
        return {
          ...option,
          disabled:
            selectedOptions.includes(option.value) &&
            option.value !== selectedOption,
        }
      })

      return (
        <Stack key={index}>
          <Group noWrap w="100%">
            <Select
              w="100%"
              searchable
              withAsterisk
              label="Lender"
              data={updatedOptions}
              placeholder="Select a Lender"
              disabled={
                (disableLenderPositions || amendment) && !item.newField
                  ? true
                  : false
              }
              {...form.getInputProps(`lendersPosition.${index}.lenders.id`)}
            />
            <NumberInput
              w="100%"
              withAsterisk
              label="Amount"
              precision={2}
              placeholder="Enter amount"
              disabled={disableLenderPositions}
              min={0}
              defaultValue={0}
              parser={value => value.replace(/[^0-9.]/g, '')}
              formatter={value =>
                !Number.isNaN(parseFloat(value))
                  ? formatNumberToCurrency(
                    Number(
                      parseFloat(
                        value.replace(/\B(?<!\d*)(?=(\d{3})+(?!\d))/g, ',')
                      )
                    ),
                    facility?.baseCurrency ?? 'USD'
                  )
                  : '$ 0'
              }
              {...form.getInputProps(`lendersPosition.${index}.amount`)}
              sx={{
                label: {
                  fontFamily: 'Plus Jakarta Sans',
                  color: '#111928',
                  fontSize: '1em',
                },
                input: {
                  backgroundColor: '#F9FAFB',
                  height: '3em',
                  borderRadius: '0.7em',
                  color: '#6B7280',
                  borderWidth: '1px',
                  borderColor: '#D1D5DB',
                  fontSize: '1em',
                  fontFamily: 'Plus Jakarta Sans',
                },
              }}
            />
            {disableLenderPositions ? (
              <Group noWrap w="100%"></Group>
            ) : (
              <Group noWrap w="100%" mt="10px" position='center'>
                <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                  onClick={() =>
                    form.removeListItem('lendersPosition', index)
                  } />
                <span
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#111928',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >
                  Remove
                </span>
              </Group>
            )}
          </Group>

        </Stack>
      )
    }
  )

  return !isLoadingLenders ?
    (
      <FormWrapper title={''}>
        <Stack>
          {disableLenderPositions ? (
            <Group noWrap w="100%" className="topFormErrorText">
              Lender Positions cannot be changed due to outstanding transactions.
            </Group>
          ) : null}
          {fields}
          <Group noWrap w="100%">
            {disableLenderPositions ? (
              <Group noWrap w="100%"></Group>
            ) : (
              <Group noWrap w="100%">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IonIcon name="add-circle-outline" className="click-ion-icon"
                    onClick={() =>
                      form.insertListItem('lendersPosition', {
                        ...defaultValue,
                        newField: true,
                      })
                    }
                  />
                  <span
                    style={{
                      marginLeft: '8px',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: 'bold',
                      fontSize: '1em',
                    }}
                  >
                    Add lenders and amounts
                  </span>
                </div>
              </Group>
            )}

            <Group noWrap w="100%">
              <NumberInput
                w="100%"
                label="Subtotal"
                placeholder="Allocation Total"
                min={0}
                defaultValue={0}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                disabled
                formatter={value =>
                  !Number.isNaN(parseFloat(value))
                    ? formatNumberToCurrency((Number(value) ?? 0), (facility?.baseCurrency ?? 'USD'))
                    : facility?.baseCurrency ?? 'USD'
                }
                {...form.getInputProps(`subtotal`)}
                sx={{
                  label: {
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#111928',
                    fontSize: '1em',
                    width: '102%',
                  },
                  input: {
                    backgroundColor: '#F9FAFB',
                    height: '3em',
                    borderRadius: '0.7em',
                    color: '#6B7280',
                    borderWidth: '1px',
                    borderColor: '#D1D5DB',
                    fontSize: '1em',
                    fontFamily: 'Plus Jakarta Sans',
                    width: '102%',
                  },
                }}
              />
            </Group>
            <Group noWrap w="100%"></Group>
          </Group>
          {form.values.subtotal > facilityAmount ? (
            <Group noWrap w="100%" position="center">
              <span style={{ color: '#dc4d01' }}>
                Total Lender Allocations should not exceed Facility Amount.
              </span>
            </Group>
          ) : null}
        </Stack>
      </FormWrapper>
    ) : <Stack></Stack>
}
export default React.forwardRef(ManageLenders)
