import IonIcon from '@reacticons/ionicons'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFacility } from 'app/state/ducks/facilities/selectors'
import TextInput from 'app/views/components/inputs/TextInput'
import {
    Accordion,
    Anchor,
    Breadcrumbs,
    Group,
    Stack,
    Grid,
    Table,
    Modal,
} from '@mantine/core'
import StatusPill from '../../pages/deals-management/common/pill-status'
import { useNavigate, useParams } from 'react-router-dom'
import { AttachmentsAccordion } from '../Accordion/attachments-accordion/attachments-accordion'
import { NotesAccordion } from '../Accordion/notes-accordion'
import { ContractType } from 'app/models/common-types'
import { Fees } from 'app/models/fees-params'
import { getFacilityFees, getFeeScheduler } from 'app/state/ducks/fees/selectors'
import { dayBasisOptions, frequencyOptions } from 'app/models/dropdown-options'
import { calculateFeeScheduler, loadFeesByFacility } from 'app/state/ducks/fees/thunks'
import { loadOneFacility, setOneFacility } from 'app/state/ducks/facilities/thunks'
import PrimaryButton from '../buttons/PrimaryButton'
import { UpdatedFacilitiesScreen } from 'app/views/pages/deals-management/facilities/updated-facilities-screen'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { loadAllDeals } from 'app/state/ducks/deals/thunks'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { loadWireInstructions } from 'app/state/ducks/wire-instructions/thunks'
import { loadAllFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { getFacilityAmendments } from 'app/state/ducks/facility-amendments/selectors'
import { loadSchedulerAmortizationByFacility } from 'app/state/ducks/amortization/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import SegmentControl from '../segments/segment-control'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'


export default function FeesPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { facilityId, status, feeType } = useParams()
    const facility = useSelector(getFacility)
    const facilityAmendments = useSelector(getFacilityAmendments)
    const feeScheduler = useSelector(getFeeScheduler)
    const facilityFees: Fees[] = useSelector(getFacilityFees)
    const [openEdit, setOpenEdit] = useState<boolean>(false)
    const deals = useSelector(getDeals)
    const [deal, setDeal] = useState<DealParams>()
    const [fees, setFees] = useState<Fees[]>([]);
    const fee = fees.find(fee => fee.feeType === feeType)

    const tabs: { [key: string]: (() => string) | undefined } = {};
    fees.forEach(fee => {
        tabs[fee.feeType + 'Fee'] = () => `/dealmanagement/facilities/${facilityId}/${status}/fees/${fee.feeType}`;
    });
    const activeTabs = {
        tab: Object.prototype.hasOwnProperty.call(tabs, feeType + 'Fee') ? tabs[feeType + 'Fee'] : '',
        locked: '',
    }

    useEffect(() => {
        dispatch(loadEntities())
        dispatch(loadContacts())
        dispatch(loadWireInstructions())
        dispatch(loadAllDeals())
        dispatch(loadAllFacilityAmendments())
    }, [])

    useEffect(() => {
        if (facilityAmendments && facilityAmendments.length > 0) {
            const amendment = facilityAmendments.find(
                (amendment: any) => amendment.id === facilityId
            )
            if (amendment) {
                dispatch(setOneFacility(amendment))
            }
        }
    }, [facilityAmendments, facilityId])

    useEffect(() => {
        if (facility &&
            facility.amendmentFees &&
            facility.amendmentFees.length > 0) {
            setFees(facility.amendmentFees);
        } else {
            setFees(facilityFees);
        }
    }, [facilityFees, facility])

    useEffect(() => {
        if (!facilityId) {
            return
        }
        dispatch(loadFeesByFacility(facilityId))
        dispatch(loadSchedulerAmortizationByFacility(facilityId))
        dispatch(loadOneFacility(facilityId, status ?? ''))

    }, [facilityId, status])

    useEffect(() => {
        if (!fee?.id) {
            return
        }
        dispatch(loadNoteByContract(fee.id, ContractType.Fees))
        dispatch(calculateFeeScheduler(fee.id, facilityId ?? ''))
    }, [fee])

    useEffect(() => {
        if (!facility || !deals) {
            return
        }
        const selectedDeal = deals.find(
            (deal: DealParams) => deal.id === facility.dealId.id
        )
        if (!selectedDeal) {
            return
        }
        setDeal(selectedDeal)
    }, [facility, deals])

    const items = [
        { title: 'Deal Management', action: () => navigate('/dealmanagement') },
        {
            title: 'Facilities',
            action: () => navigate('/dealmanagement/facilities'),
        },
        {
            title: facility?.name ?? '',
            action: () =>
                navigate(
                    `/dealmanagement/facilities/${facility?.id}/${facility?.status}`
                ),
        },
    ].map((item, index) => (
        <Anchor
            onClick={() => item.action()}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))

    const rows = feeScheduler?.map(({ amount, date }, index) => {
        const previousPaymentDate = index === 0 ? fee?.previousPaymentDate ?? facility?.startDate : feeScheduler[index - 1].date
        return (
            <tr key={index}>
                <td colSpan={1}>{previousPaymentDate ?? ''}</td>
                <td colSpan={1}>{date}</td>

                <td colSpan={1}>
                    {formatNumberToCurrency((Number(amount) ?? 0), (facility?.baseCurrency ?? 'USD'))}
                </td>
                <td colSpan={1}>{''}</td>
                <td colSpan={1}>{'Active'}</td>
            </tr>
        )
    })

    return (
        <PageLayout>
            <Breadcrumbs
                separator={<IonIcon name="chevron-forward-outline" />}
                mt="xs"
            >
                {items}
            </Breadcrumbs>
            <Group position="apart" align="center">
                <PageTitle text="Fees" />
                <Group noWrap>
                    {facility?.status === 'Draft' && (
                        <PrimaryButton text="Edit Fees" onClick={() => setOpenEdit(true)} />
                    )}
                </Group>
            </Group>
            <Group h="100%">
                {
                    <StatusPill
                        state={facility?.status ?? 'Draft'}
                        additionalState={facility?.amendmentType ?? ''}
                    />
                }
                <SegmentControl tabs={tabs} activeTabs={activeTabs} lockedTabs={{}} />
            </Group>
            <Group noWrap position="apart" align="flex-start" h="100vh">
                <Stack className="data-header-part">
                    <div style={{ fontSize: 'large', fontWeight: '500' }}>
                        Fee Details
                    </div>

                    <>
                        <>
                            <Group noWrap w="100%">
                                <Grid grow>
                                    {/* Fee Rate */}
                                    <Grid.Col span={2}>
                                        <TextInput
                                            w="100%"
                                            label="Fee Rate"
                                            placeholder="Empty"
                                            value={fee?.feeRate}
                                            readOnly
                                        />
                                    </Grid.Col>

                                    {/* Day Basis */}
                                    <Grid.Col span={2}>
                                        <TextInput
                                            w="100%"
                                            label="Day Basis"
                                            placeholder="Empty"
                                            value={
                                                dayBasisOptions.find(
                                                    option => option.value === fee?.dayBasis
                                                )?.label ?? ''
                                            }
                                            readOnly
                                        />
                                    </Grid.Col>

                                    {/* Frequency */}
                                    <Grid.Col span={3}>
                                        <TextInput
                                            w="100%"
                                            label="Frequency"
                                            placeholder="Empty"
                                            value={
                                                frequencyOptions.find(
                                                    option => option.value === fee?.frequency
                                                )?.label ?? ''
                                            }
                                            readOnly
                                        />
                                    </Grid.Col>

                                    {/* First Payment Date */}
                                    <Grid.Col span={5}>
                                        <TextInput
                                            w="100%"
                                            label="First Payment Date"
                                            placeholder="Empty"
                                            value={fee?.firstPaymentDate}
                                            readOnly
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Group>
                            <div style={{ fontSize: 'large', fontWeight: '500' }}>
                                Schedule
                            </div>
                        </>
                        <Table highlightOnHover fontSize="xl">
                            <thead>
                                <tr>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Amount Due</th>
                                    {/* <th>Due Date</th> */}
                                    <th>Paid Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </>
                </Stack>

                <Stack className="accordions-header-part">
                    <Stack h="100%" bg="#F9F8F8" w="100%" p="md">
                        <Accordion
                            multiple
                            defaultValue={['DealDeatils', 'pdfs', 'notes']}
                            variant="contained"
                            bg="white"
                            styles={{
                                control: {
                                    fontFamily: 'Plus Jakarta Sans',
                                    '&[data-active]': {
                                        backgroundColor: '#A4CAFE',
                                    },
                                },
                            }}
                        >
                            <AttachmentsAccordion
                                id={facilityId ?? ''}
                                type={ContractType.Facilities}
                            />
                            <NotesAccordion
                                contractId={fee?.id ?? ''}
                                contractAdmin={fee?.agencyAdmin ?? ''}
                                type={ContractType.Fees}
                            />
                        </Accordion>
                    </Stack>
                </Stack>
            </Group>
            {facility?.status === 'Draft' && (
                <Modal
                    className="modal-body modal-body-template fit-content-modal"
                    opened={openEdit}
                    onClose={() => setOpenEdit(false)}
                    centered={true}
                    size="77%"
                >
                    <UpdatedFacilitiesScreen
                        deal={deal}
                        amendment={facility.amendmentType ? 'amend' : null}
                        facility={facility}
                        onClose={() => setOpenEdit(false)}
                        step={5}
                    />
                </Modal>
            )}
        </PageLayout>
    )
}
