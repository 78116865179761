import React, { useEffect, useState } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { useDispatch, useSelector } from 'react-redux'
import TradeClosingHeader from './trade-closingHeader'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import getColumnDefs from './trade-closing-columnDefs'
import { loadAllAssignments } from 'app/state/ducks/assignments/thunks'
import { getAssignmentLoader, getAssignments } from 'app/state/ducks/assignments/selectors'
import { AssignmentParams } from 'app/models/assignment-params'
import { getFacilities } from 'app/state/ducks/facilities/selectors'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { loadAllFacilities } from 'app/state/ducks/facilities/thunks'
import { MRT_ColumnDef } from 'mantine-react-table'
import { StableKey } from 'app/models/common-types'
import { useListState } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { getKPIData } from './trade-closing-kpi-data'
import SegmentControl from 'app/views/components/segments/segment-control'
import { lockedTabs, tabs } from './tade-closing-segment-data'
import { Group } from '@mantine/core'

export type TradeClosingPageData = {
  assignment: AssignmentParams
  facilityId: StableKey
  from: StableKey[]
  to: StableKey[]
  amount: number
  effectiveDate: string
  id: string
  status: string
}

export function TradeClosingPage() {
  const dispatch = useDispatch()
  const assignments: AssignmentParams[] | undefined =
    useSelector(getAssignments)
  const [tableData, tableDataHander] = useListState<TradeClosingPageData>()
  const [columnDefs, setColumnDefs] = useState<
    MRT_ColumnDef<TradeClosingPageData>[]
  >([])

  const [filteredTableData, setFilteredTableData] = useState<TradeClosingPageData[]>(tableData)
  const kpiData = getKPIData(tableData, setFilteredTableData)

  const facilities = useSelector(getFacilities)
  const entities = useSelector(getEntities)
  const assignmentLoader = useSelector(getAssignmentLoader)
  const [globalLoading, setGlobalLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setGlobalLoading(assignmentLoader)
  }, [assignmentLoader])

  useEffect(() => {
    const loadData = async () => Promise.all([
      dispatch(loadEntities()),
      dispatch(loadAllFacilities()),
      dispatch(loadAllAssignments()),
    ])
    loadData()
  }, [dispatch])

useEffect(() => {
  if (!facilities || !entities || facilities.length === 0 || entities.length === 0) {
    return
  }
  setColumnDefs(getColumnDefs(entities, facilities))
}, [entities, facilities])

useEffect(() => {
  if (!assignments) {
    return
  }
  const assingmentMap = assignments.map(assignment => ({
    facilityId: assignment.facilityId ?? '',
    effectiveDate: assignment.effectiveDate,
    id: assignment.id,
    status: assignment.status ?? '',
    from: assignment.trades.map(trade => trade.from),
    to: assignment.trades.map(trade => trade.to),
    amount: assignment.trades.reduce(
      (acc, trade) => acc + Number(trade.amount),
      0
    ),
    assignment: assignment,
    trades: assignment.trades,
  }))
  tableDataHander.setState(assingmentMap)
  setFilteredTableData(assingmentMap)
}, [assignments])

function handleRowClicked(e: AssignmentParams) {
  navigate(`/tradeclosing/assignment/` + e.status + `/` + e.id + ``)
}

const activeTab = () => {
  if (window.location.pathname === '/tradeclosing') {
    return tabs.allTrades
  } else if (window.location.pathname === '/tradeclosing/parent') {
    return tabs.parent
  } else if (window.location.pathname === '/tradeclosing/subtrade') {
    return tabs.subTrade
  } else {
    return tabs.allTrades
  }
}

const activeTabs = {
  tab: activeTab,
  locked: '',
}

return (
  <PageLayout>
    <TradeClosingHeader />
    <KPI_Section
      kpiData={kpiData}
      isLoading={globalLoading}
    />
    <Group position="right">
      <SegmentControl tabs={tabs} lockedTabs={lockedTabs} activeTabs={activeTabs} />
    </Group>
    {columnDefs.length != 0 ? (
      <TableColumnClickExclusion
        tableName='Trade_Closing Table'
        isLoading={globalLoading}
        columnDefs={columnDefs}
        data={filteredTableData}
        setRow={(e: TradeClosingPageData, cellName: string) => {
          if (cellName != 'id') {
            handleRowClicked(e.assignment)
          }
        }}
      />
    ) : null}
  </PageLayout>
)
}
