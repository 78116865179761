import { Group, Stack } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'

interface props {
  button?: string
}

export default function ReleaseQueueHeader({ button }: props) {
  return (
    <Stack>
      <Group position="apart" style={{ marginBottom: '15px' }}>
        <PageTitle>Release Queue</PageTitle>
        {button && (
          <Group>
            <PrimaryButton
              text={button}
              onClick={() => console.log(true)}
            />
          </Group>
        )}

      </Group>
    </Stack>
  )
}
