import { Stack } from '@mantine/core'
import { LendersPosition } from 'app/models/lenders-position-params'
import { LoanParams } from 'app/models/loan-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getPercentage } from 'app/state/ducks/lenders-positions/thunks'
import React from 'react'
import { useSelector } from 'react-redux'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { getColumnDefs } from './table-columnDefs-lender-breakdown'
import { LenderPaymentBreakdown } from 'app/models/principal-payments-params'

interface Props {
  form: any
  loan: LoanParams
  activeLenders: LendersPosition[]
  lenderBreakdown: LenderPaymentBreakdown[]
}
export const LenderBreakdown = ({ form, loan, activeLenders, lenderBreakdown }: Props) => {

  const entities = useSelector(getEntities)
  function cleanLenderBreakdown(lenders: LendersPosition[]) {
    return lenders.map((lender: LendersPosition) => {
      const paymentEffectiveDate = form.values.paymentDate
      const percentage = getPercentage(lender, paymentEffectiveDate)
      const lenderWithAmount = lenderBreakdown.find(l => l.lenderId.id === lender.lender.id)
      const finalAmount = form.values.interestPaid === 'true' ? Number(lenderWithAmount?.amount ?? 0.0) + Number(lenderWithAmount?.interest ?? 0.0) : Number(lenderWithAmount?.amount ?? 0.0)
      const entity = entities?.find(entity => entity.id === lender.lender.id)

      return {
        'Lender': entity?.entityName ?? '',
        'Payment Amount': formatNumberToCurrency(Number(finalAmount), loan.currency),
        'Percentage': `${percentage}%`,
      }
    })
  }

  return (
    <Stack w="100%" spacing={0}>
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Lender Breakdown
      </div>
      <TableColumnClickExclusion
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        data={cleanLenderBreakdown(activeLenders)}
        setRow={() => {
        }}
        withPagination={false}
        minHeight={'100px'}
      />
    </Stack>

  )
}
