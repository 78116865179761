import { Divider, ScrollArea, Stack, Group } from '@mantine/core'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import Select from '../inputs/Select'
import CustomDatePicker from '../date-picker/date-picker-logic'
import NumberInput from '../inputs/NumericalInput'
import {
  dayBasisOptions,
  feeTypeOptions,
  frequencyOptions,
} from 'app/models/dropdown-options'
import { stringToDate, formatDateToUTC } from 'app/utils/util-functions'
import Table2 from '../Table2/Table2'
import { getColumnDefs } from './facility-fees-colum-def'
import { changeAllFeesForFacilityAmendments } from 'app/state/ducks/facility-amendments/thunks'
import { changeAllFeesForFacility } from 'app/state/ducks/fees/thunks'
import { getFacilityFees } from 'app/state/ducks/fees/selectors'
import { Fees } from 'app/models/fees-params'
import { ChangeFeeData } from 'app/models/fees-params'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import FormWrapper from '../Form/FormWrapper'
import IonIcon from '@reacticons/ionicons'

type Props = {
  facility: any
  setFacility: any
  amendment?: string | null | undefined
}

export interface ChildManageFeesRef {
  handleClick: () => void
}

const ManageFees: React.ForwardRefRenderFunction<ChildManageFeesRef, Props> = (
  { facility, setFacility, amendment },
  ref
) => {
  const dispatch = useDispatch()
  const [facilityFees, setFacilityFees] = useState<Fees[]>([]);
  const fees = useSelector(getFacilityFees);
  const businessDate = useSelector(getBusinessDate)
  const noBulletTypes = ["Facility", "Commitment", "Utilization", "LetterOfCredit"];


  useEffect(() => {
    if (facility &&
      facility.amendmentFees &&
      facility.amendmentFees.length > 0) {
      setFacilityFees(facility.amendmentFees);
    } else {
      setFacilityFees(fees);
    }
  }, [fees, facility])



  const handleClick = async () => {
    const updatedForm: ChangeFeeData[] = []
    form.values.fees.forEach((fee) => {
      if (fee.feeType) {
        updatedForm.push({
          ...fee,
          firstPaymentDate: formatDateToUTC(fee.firstPaymentDate),
        })
      }
    })
    const checkIfFeeTypes = form
      .getTransformedValues()
      .fees.map(
        (fee) => fee.feeType == '' || fee.feeType == null
      )
    let response: any
    const transformedPositions = form.getTransformedValues().fees
    if (checkIfFeeTypes.includes(false)) {
      if (form.validate().hasErrors === true) {
        throw Error
      }
      if (amendment) {
        response = await dispatch(changeAllFeesForFacilityAmendments(transformedPositions,
          { admin: facility.accountManagementAdmin, id: facility.id }));
        setFacility(response.payload)
      } else {
        response = await dispatch(
          changeAllFeesForFacility(transformedPositions, {
            id: facility.id,
            admin: facility.accountManagementAdmin,
          })
        )
      }
    } else if (facility.accrualFee?.length > 0) {
      if (amendment) {
        response = dispatch(changeAllFeesForFacilityAmendments([],
          { admin: facility.accountManagementAdmin, id: facility.id }));
        setFacility(response.payload)
      } else {
        response = await dispatch(
          changeAllFeesForFacility([], {
            id: facility.id,
            admin: facility.accountManagementAdmin,
          })
        )

      }
    }
    if (response?.success === false) {
      ErrorNotification({
        title: ' Facility Action Failed',
        message: response?.payload || 'Check Lender Allocations and try again',
      })
      return
    }
    if (
      !response ||
      Object.keys(response.payload).length !== 0 ||
      response?.success === true
    ) {
      setFacility({
        ...facility,
        accrualFee: form.getTransformedValues().fees,
      })
      SuccessNotification({
        title: 'Successful Facility Creation',
        message:
          'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
      })
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const form = useForm({
    initialValues: {
      fees:
        facilityFees?.length > 0
          ? facilityFees.map((fee: Fees) => ({
            ...fee,
            firstPaymentDate: stringToDate(fee.firstPaymentDate),
            feeRate: Number(fee.feeRate),
          }))
          : [
            {
              feeRate: 0,
              feeType: '',
              firstPaymentDate: businessDate,
              dayBasis: '',
              frequency: '',
            },
          ],
    },
    transformValues: values => ({
      ...values,
      fees: values.fees.map((a) => {
        return {
          dayBasis: a.dayBasis,
          feeType: a.feeType,
          frequency: a.frequency,
          feeRate: Number(a.feeRate),
          firstPaymentDate: formatDateToUTC(a.firstPaymentDate),
        }
      }),
    }),
    validate: {
      fees: {
        feeType: isNotEmpty(' Choose a fee'),
        feeRate: value => (value > 0 ? null : 'Invalid Rate'),
        dayBasis: isNotEmpty(' Choose a day basis'),
        frequency: isNotEmpty('Choose a frequency'),
      },
    },
  })

  useEffect(() => {
    form.setValues({
      fees:
        facilityFees?.length > 0
          ? facilityFees.map((fee: Fees) => ({
            ...fee,
            firstPaymentDate: stringToDate(fee.firstPaymentDate),
            feeRate: Number(fee.feeRate),
          }))
          : [
            {
              feeRate: 0,
              feeType: '',
              firstPaymentDate: businessDate,
              dayBasis: '',
              frequency: '',
            },
          ],
    })
  }, [facilityFees])


  const selectedOptions = form.values.fees?.map(
    (item: { feeType: string }) => item.feeType
  )
  const fields = form.values.fees?.map(
    (item: { feeType: string }, index: any) => {
      const selectedOption = item.feeType
      const updatedOptions = feeTypeOptions.map(option => {
        return {
          ...option,
          disabled:
            selectedOptions.includes(option.value) &&
            option.value !== selectedOption,
        }
      })

      return (
        <FormWrapper title={''}>
          <Stack w="100%" key={index}>
            <Stack>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  searchable
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  label="Fee"
                  data={updatedOptions}
                  placeholder="Select a fee and define start date and frequency"
                  {...form.getInputProps(`fees.${index}.feeType`)}
                />
                <NumberInput
                  w="100%"
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  precision={5}
                  defaultValue={0.0}
                  min={0.0}
                  pb={5}
                  label="Fee Rate"
                  placeholder="Insert rate"
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  {...form.getInputProps(`fees.${index}.feeRate`)}
                />
                <Group noWrap w="100%"></Group>
              </Group>
              <Group noWrap w="100%">
                <Select
                  w="100%"
                  searchable
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  label="Day Basis"
                  data={dayBasisOptions}
                  placeholder="Select day basis"
                  {...form.getInputProps(`fees.${index}.dayBasis`)}
                />

                <Select
                  w="100%"
                  searchable
                  withAsterisk={form.values.fees[index].feeType ? true : false}
                  label='Frequency'
                  placeholder="Select frequency"
                  data={noBulletTypes.includes(form.values.fees[index].feeType) ? frequencyOptions.filter(option => option.value !== 'Bullet') : frequencyOptions}
                  {...form.getInputProps(`fees.${index}.frequency`)}
                />
                <CustomDatePicker
                  w="100%"
                  required={form.values.fees[index].feeType ? true : false}
                  label={'First Payment Date'}
                  date={
                    form.values.fees[index].firstPaymentDate ?? businessDate
                  }
                  setDate={(value: any) =>
                    form.setFieldValue(
                      `fees.${index}.firstPaymentDate`,
                      value
                    )
                  }
                  holidayCalendars={facility?.holidayCalendar ?? []}
                />
              </Group>
            </Stack>
            <Stack>
              <Group position="center" noWrap w="100%" mt="10px">
                <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                  onClick={() => form.removeListItem('fees', index)} />
                <span>
                  Remove
                </span>
              </Group>
            </Stack>
            <Divider my="sm" />
          </Stack>
        </FormWrapper>
      )
    }
  )

  return (
    <Stack>
      <Stack>{fields}</Stack>
      <Stack>
        <Stack style={{ marginTop: '5px' }}>
          <Group noWrap w="100%">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon name="add-circle-outline" className="click-ion-icon"
                onClick={() =>
                  form.insertListItem('fees', {
                    feeRate: 0,
                    feeType: '',
                    firstPaymentDate: businessDate,
                    dayBasis: '',
                    frequency: '',
                  })
                } />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Add Fees
              </span>
            </div>
          </Group>
        </Stack>
        <Group>
          <ScrollArea h={400} type="always">
            <Table2
              withPagination={false}
              enableColumnOrdering={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              columnDefs={getColumnDefs()}
              data={form.getTransformedValues().fees}
              setRow={() => { }}
            />
          </ScrollArea>
        </Group>
      </Stack>
    </Stack>
  )
}
export default React.forwardRef(ManageFees)
