import { PageLayout } from 'app/views/components/layout/page-layout'
import NoticesPageData from './release-queue-notices-page-data'
import ReleaseQueueHeader from './release-queue-header'

export function NoticesPage() {

  return (
    <PageLayout>
      <ReleaseQueueHeader button='Create Notice' />
      <NoticesPageData />
    </PageLayout>
  )
}
