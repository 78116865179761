import { Flex, ScrollArea, Stack } from '@mantine/core'
import React, { useImperativeHandle } from 'react'
import { saveDeal } from 'app/state/ducks/deals/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { isNotEmpty, useForm } from '@mantine/form'
import { Group, Box } from '@mantine/core'
import Select from '../inputs/Select'
import CustomDatePicker from '../date-picker/date-picker-logic'
import NumberInput from '../inputs/NumericalInput'
import {
  dayBasisOptions,
  dealFeeTypeOptions,
  frequencyOptions,
  AmortizationAdministrativeAgencyFeeTypes,
} from 'app/models/dropdown-options'
import {
  DealFeeParams,
  DealParams,
  DraftDealParams,
} from 'app/models/deal-params'
import { formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import { saveDealAmendment } from 'app/state/ducks/deal-amendments/thunks'
import Table2 from '../Table2/Table2'
import { getColumnDefs } from './deal-fees-colum-def'
import { ErrorNotification, SuccessNotification } from '../notifications/notification'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import IonIcon from '@reacticons/ionicons'

type Props = {
  deal: any
  setDeal: any
  setAmendment: any
  amendment?: string | null | undefined
  dealAmendment?: DraftDealParams | DealParams
}

export interface ChildDealManageFeesRef {
  handleClick: () => void
}

const DealManageFees: React.ForwardRefRenderFunction<
  ChildDealManageFeesRef,
  Props
> = ({ deal, setDeal, setAmendment, amendment, dealAmendment }, ref) => {
  const dispatch = useDispatch()
  const businessDate = useSelector(getBusinessDate)
  const bulletFees = ['ArrangementFee', 'UpfrontFee', 'AmendmentFee', 'SyndicationFee', 'AdHocFee']

  deal = dealAmendment ? (dealAmendment) : deal
  const handleClick = async () => {
    const updatedForm: DealFeeParams[] = []
    form.values.fees.forEach((fee: DealFeeParams) => {
      if (fee.feeType) {
        updatedForm.push(fee)
      }
    })
    form.values.fees = updatedForm
    const checkIfFeeTypes = form
      .getTransformedValues()
      .fees.map(
        (fee: DealFeeParams) => fee.feeType == '' || fee.feeType == null
      )
    let dealSubmission
    let submit = false
    let response: any
    if (checkIfFeeTypes.includes(false)) {
      if (form.validate().hasErrors === true) {
        throw Error
      }
      submit = true
      const dealValues = {
        ...deal,
        fees: form.getTransformedValues().fees,
      }
      if (amendment) {
        const dealAmendmentId = dealAmendment?.id ? dealAmendment?.id : null
        dealSubmission = saveDealAmendment(dealValues, dealAmendmentId)
      } else {
        dealSubmission = saveDeal(dealValues, deal.id)
      }
    } else if (deal.fees.length > 0) {
      submit = true
      const dealValues = {
        ...deal,
        fees: [],
      }
      if (amendment) {
        submit = true
        const dealAmendmentId = dealAmendment?.id ? dealAmendment?.id : null
        dealSubmission = saveDealAmendment(dealValues, dealAmendmentId)
      } else {
        submit = true
        dealSubmission = saveDeal(dealValues, deal.id)
      }
    }

    if (submit) {
      response = await dispatch(dealSubmission)
      if (response.success) {
        const submitDealValues = {
          ...deal,
          fees: form.getTransformedValues().fees,
        }
        if (amendment) {
          setAmendment(submitDealValues)
        } else {
          setDeal(submitDealValues)
        }
        SuccessNotification({
          title: 'Successful Deal Creation',
          message: 'You can now add Fees',
        })
      } else {
        ErrorNotification({
          title: 'Failed Deal Creation',
          message: response.payload ?? 'Check deal inputs and try again',
        })
      }
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const calculateRateAmount = async (
    value: number | "" | null,
    index: number,
    type: string
  ) => {
    if (type === 'rate' && value) {
      const result = (value / 100) * deal.amount
      form.setFieldValue(`fees.${index}.feeAmount`, result)
    } else if (value) {
      const result = (value / deal.amount) * 100
      form.setFieldValue(`fees.${index}.feeRate`, result)
    }
  }

  const feeTypeChange = async (
    value: string | null, // Provide a valid type for the value parameter
    index: number,
  ) => {
    if (value && bulletFees.includes(value)) {
      form.setFieldValue(`fees.${index}.frequency`, 'Bullet')
    }
  }


  const form = useForm({
    initialValues: {
      fees:
        deal?.fees?.length > 0
          ? deal?.fees.map((fee: DealFeeParams) => ({
            ...fee,
            feeRate: Number(fee.feeRate),
            feeAmount: (Number(fee.feeRate) / 100) * deal.amount,
            firstPaymentDate: stringToDate(fee.firstPaymentDate)
          }))
          : [
            {
              feeRate: 0,
              feeAmount: 0,
              feeType: '',
              firstPaymentDate: businessDate,
              dayBasis: '',
              frequency: '',
              amortization: '',
            },
          ],
    },
    transformValues: values => ({
      ...values,
      fees: values.fees.map((a: any) => {
        return {
          ...a,
          firstPaymentDate: formatDateToUTC(a.firstPaymentDate),
          amortization: a.amortization != '' ? a.amortization : null,
        }
      }),
    }),
    validate: {
      fees: {
        feeType: isNotEmpty(' Choose a fee'),
        feeRate: value => (value > 0 ? null : 'Invalid Rate'),
        feeAmount: value => (value > 0 ? null : 'Invalid Amount'),
        frequency: isNotEmpty('Choose a frequency'),
        amortization: (_value, allValues) => {
          let error = null
          if (allValues && allValues.fees && allValues.fees.length > 0) {
            allValues.fees.map(
              (fee: {
                feeType: string
                amortization: string
                dayBasis: string
              }) => {
                if (
                  fee.feeType === 'AdministrativeAgencyFee' &&
                  (!fee.amortization ||
                    fee.amortization === '' ||
                    !fee.dayBasis ||
                    fee.dayBasis === '')
                ) {
                  error = true
                }
              }
            )
          }
          if (error) {
            return isNotEmpty('Choose a Amoritization')
          }
        },
        dayBasis: (_value, allValues) => {
          let error = null
          if (allValues && allValues.fees && allValues.fees.length > 0) {
            allValues.fees.map(
              (fee: {
                feeType: string
                amortization: string
                dayBasis: string
              }) => {
                if (
                  fee.feeType === 'AdministrativeAgencyFee' &&
                  fee.dayBasis === ''
                ) {
                  error = true
                }
              }
            )
          }
          if (error) {
            return isNotEmpty('Choose a DayBasis')
          }
        },
      },
    },
  })

  const selectedOptions = form.values.fees?.map(
    (item: { feeType: string }) => item.feeType
  )
  const fields = form.values.fees?.map(
    (item: { feeType: string }, index: any) => {
      const selectedOption = item.feeType
      const updatedOptions = dealFeeTypeOptions.map(option => {
        return {
          ...option,
          disabled:
            selectedOptions.includes(option.value) &&
            option.value !== selectedOption,
        }
      })

      return (
        <Group grow key={index}>
          <Flex direction={'column'}>
            <Stack>
              <Group noWrap>
                <Select
                  w={'100%'}
                  searchable
                  withAsterisk={
                    form.values.fees[index].feeType ? true : false
                  }
                  label="Fee"
                  data={updatedOptions}
                  placeholder="Select a fee and define start date and frequency"
                  clearable
                  {...form.getInputProps(`fees.${index}.feeType`)}
                  onChange={e => {
                    form.getInputProps(`fees.${index}.feeType`).onChange(e)
                    feeTypeChange(e, index)
                  }}
                />
                <NumberInput
                  w={'100%'}
                  withAsterisk={
                    form.values.fees[index].feeType ? true : false
                  }
                  precision={5}
                  defaultValue={0.0}
                  min={0.0}
                  pb={5}
                  label="Fee Rate"
                  placeholder="Insert rate"
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  // disabled={form?.values?.fees[index].frequency === 'Bullet' ? true : false}
                  {...form.getInputProps(`fees.${index}.feeRate`)}
                  onChange={e => {
                    form.getInputProps(`fees.${index}.feeRate`).onChange(e)
                    calculateRateAmount(e, index, 'rate')
                  }}
                />
                <NumberInput
                  w={'100%'}
                  withAsterisk={
                    form.values.fees[index].feeType ? true : false
                  }
                  pb={5}
                  label="Fee Amount"
                  placeholder="Select Amount"
                  parser={value =>
                    value ? value.replace(/\$\s?|(,*)/g, '') : ''
                  }
                  formatter={value =>
                    !Number.isNaN(parseFloat(value ?? ''))
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : ''
                  }
                  {...form.getInputProps(`fees.${index}.feeAmount`)}
                  onChange={e => {
                    form.getInputProps(`fees.${index}.feeAmount`).onChange(e)
                    calculateRateAmount(e, index, 'amount')
                  }}
                />
              </Group>
              <Group noWrap>
                <Select
                  w={'50%'}
                  searchable
                  withAsterisk={
                    form.values.fees[index].feeType ? true : false
                  }
                  label="Frequency"
                  placeholder="Select frequency"
                  data={frequencyOptions}
                  disabled={bulletFees.includes(form?.values?.fees[index].feeType) ? true : false}
                  {...form.getInputProps(`fees.${index}.frequency`)}
                />
                <CustomDatePicker
                  w={'50%'}
                  required={form.values.fees[index].feeType ? true : false}
                  label={'First Payment Date'}
                  date={
                    form.values.fees[index].firstPaymentDate ?? businessDate
                  }
                  setDate={(value: any) =>
                    form.setFieldValue(
                      `fees.${index}.firstPaymentDate`,
                      value
                    )
                  }
                  holidayCalendars={deal?.holidayCalendar ?? []}
                />
              </Group>
              {form.values.fees[index].feeType ===
                'AdministrativeAgencyFee' && (
                  <Group noWrap>
                    <Select
                      w={'33%'}
                      searchable
                      withAsterisk={
                        form.values.fees[index].feeType ? true : false
                      }
                      label="Amortization"
                      placeholder="Select amortization"
                      data={AmortizationAdministrativeAgencyFeeTypes}
                      {...form.getInputProps(`fees.${index}.amortization`)}
                    />
                    <Select
                      w={'33%'}
                      searchable
                      withAsterisk={
                        form.values.fees[index].feeType ? true : false
                      }
                      label="Day Basis"
                      data={dayBasisOptions}
                      placeholder="Select day basis"
                      {...form.getInputProps(`fees.${index}.dayBasis`)}
                    />
                  </Group>

                )}
            </Stack>
            <Stack>
              <Group noWrap style={{ marginTop: "10px" }}>
                <IonIcon name="trash-outline" className="click-ion-icon" style={{ color: "red" }}
                  onClick={() => form.removeListItem('fees', index)} />
                <span
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: '#111928',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    display: 'flex',
                  }}
                >
                  Remove
                </span>
              </Group>
            </Stack>
          </Flex>
        </Group >
      )
    }
  )

  return (
    <Box>
      <Stack>{fields}</Stack>
      <Stack>
        <Stack>
          <Group noWrap>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IonIcon name="add-circle-outline" className="click-ion-icon"
                onClick={() =>
                  form.insertListItem('fees', {
                    feeRate: '',
                    feeType: '',
                    firstPaymentDate: businessDate,
                    dayBasis: '',
                    frequency: '',
                  })
                }
              />
              <span
                style={{
                  marginLeft: '8px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: 'bold',
                  fontSize: '1em',
                }}
              >
                Add Fees
              </span>
            </div>
          </Group>
        </Stack>
        <Group>
          <ScrollArea h={400} type="always">
            <Table2
              enableColumnOrdering={false}
              withPagination={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              columnDefs={getColumnDefs(deal ?? null)}
              data={form.getTransformedValues().fees}
              setRow={() => { }}
            />
          </ScrollArea>
        </Group>
      </Stack>
    </Box>
  )
}
export default React.forwardRef(DealManageFees)
