import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { useDispatch, useSelector } from 'react-redux'
import { saveWireInstruction } from 'app/state/ducks/wire-instructions/thunks'
import {
  PaymentMethod,
  currencyOptions,
  paymentInstructionTypeOptions,
} from 'app/models/dropdown-options'
import { useEffect, useState } from 'react'
import { EntityParams } from 'app/models/entity-params'
import config from 'app/config/config'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { Group, Stack, Text } from '@mantine/core'
import TextInput from 'app/views/components/inputs/TextInput'
import Select from 'app/views/components/inputs/Select'
import TextArea from 'app/views/components/inputs/TextArea'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { useForm } from '@mantine/form'
import FormWrapper from 'app/views/components/Form/FormWrapper'
import { ErrorNotification, SuccessNotification } from 'app/views/components/notifications/notification'
import IonIcon from '@reacticons/ionicons'
import cleanUUID from 'app/views/components/functions/cleanUUID'
// import { wireInstructions } from 'app/state/ducks'

type WireInstructionsFormProps = {
  closeModal: () => void
  paymentInstruction?: WireInstructionParams
  entityId?: string
  isStepper?: boolean
  onHandleSubmit?: any
  multipleWireInstructions?: WireInstructionParams[]
}

function WireInstructionsForm({
  closeModal,
  paymentInstruction,
  entityId,
  isStepper,
  onHandleSubmit,
  multipleWireInstructions,
}: WireInstructionsFormProps) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const entities: EntityParams[] = useSelector(getEntities)
  const entity =
    entities?.find(
      e => e.id === entityId || e.id === cleanUUID(paymentInstruction?.entity.id)
    ) ?? null
  const form = useForm({
    initialValues: {
      wireInstructions: [{
        id: paymentInstruction?.id ?? undefined,
        currency: paymentInstruction?.currency ?? '',
        entity: { admin: entity?.maker ?? '', id: entity?.id ?? '' },
        entityName: paymentInstruction?.entityName ?? '',
        paymentInstructionType: paymentInstruction?.paymentInstructionType ?? '',
        paymentReference: paymentInstruction?.paymentReference ?? '',
        specialInstructions: paymentInstruction?.specialInstructions ?? '',
        accountWithInstitutionName:
          paymentInstruction?.accountWithInstitutionName ?? undefined,
        accountWithInstitutionBankAba:
          paymentInstruction?.accountWithInstitutionBankAba ?? '',
        accountWithInstitutionSwiftBic:
          paymentInstruction?.accountWithInstitutionSwiftBic ?? '',
        accountWithInstitutionIban:
          paymentInstruction?.accountWithInstitutionIban ?? '',
        beneficiaryCustomerName:
          paymentInstruction?.beneficiaryCustomerName ?? undefined,
        beneficiaryAccountNumber:
          paymentInstruction?.beneficiaryAccountNumber ?? undefined,
        beneficiaryIban: paymentInstruction?.beneficiaryIban ?? '',
        correspondentBank:
          paymentInstruction?.paymentInstructionType !== 'Intermediary'
            ? null
            : {
              bankName: paymentInstruction?.correspondentBank?.bankName ?? '',
              bankBic: paymentInstruction?.correspondentBank?.bankBic ?? '',
              bankAba: paymentInstruction?.correspondentBank?.bankAba ?? '',
            },
        intermediaryBank:
          paymentInstruction?.paymentInstructionType !== 'Intermediary'
            ? null
            : {
              bankName: paymentInstruction?.intermediaryBank?.bankName ?? '',
              bankBic: paymentInstruction?.intermediaryBank?.bankBic ?? '',
              bankAba: paymentInstruction?.intermediaryBank?.bankAba ?? '',
            },
        status: paymentInstruction?.status ?? 'Inactive',
        paymentMethod: paymentInstruction?.paymentMethod ?? undefined,
        receiverBic: paymentInstruction?.receiverBic ?? undefined,
        creditorAgent: paymentInstruction?.creditorAgent ?? undefined,
        creditorAgentBic: paymentInstruction?.creditorAgentBic ?? undefined,
        creditor: paymentInstruction?.creditor ?? undefined,
        creditorAccount: paymentInstruction?.creditorAccount ?? undefined,
        iban: paymentInstruction?.iban ?? undefined,
      }]
    },
    validate: {
      wireInstructions: {
        currency: value => (value === '' ? 'Required' : null),
        // entity: (value) => value && value.admin === "" && value.id === "" ? 'Required' : null,
        paymentInstructionType: value => (value === '' ? 'Required' : null),
        accountWithInstitutionBankAba: value =>
          value && value !== '' && value.length !== 9
            ? 'Required length of 9 characters'
            : null,
        accountWithInstitutionSwiftBic: value =>
          value && value !== '' && (value.length < 8 || value.length > 11)
            ? 'Required length between 8-11 characters'
            : null,
        correspondentBank: {
          bankBic: value =>
            value && value !== '' && (value.length < 8 || value.length > 11)
              ? 'Required length between 8-11 characters'
              : null,
          bankAba: value =>
            value && value !== '' && value.length !== 9
              ? 'Required length of 9 characters'
              : null,
        },
        intermediaryBank: {
          bankBic: value =>
            value && value !== '' && (value.length < 8 || value.length > 11)
              ? 'Required length between 8-11 characters'
              : null,
          bankAba: value =>
            value && value !== '' && value.length !== 9
              ? 'Required length of 9 characters'
              : null,
        },
      },
    },
    validateInputOnBlur: true,
  })

  useEffect(() => {
    if (multipleWireInstructions && multipleWireInstructions.length > 0) {
      const multiContactForm: { id: string; currency: string; entity: { admin: string; id: string }; entityName: string; paymentInstructionType: string; paymentReference: string; specialInstructions: string; accountWithInstitutionName: string | undefined; accountWithInstitutionBankAba: string; accountWithInstitutionSwiftBic: string; accountWithInstitutionIban: string; beneficiaryCustomerName: string | undefined; beneficiaryAccountNumber: string | undefined; beneficiaryIban: string; correspondentBank: { bankName: string; bankBic: string; bankAba: string } | null; intermediaryBank: { bankName: string; bankBic: string; bankAba: string } | null; status: string; paymentMethod: string | undefined; receiverBic: string | undefined; creditorAgent: string | undefined; creditorAgentBic: string | undefined; creditor: string | undefined; creditorAccount: string | undefined; iban: string | undefined }[] = []
      multipleWireInstructions.map((paymentInstruction) => {
        multiContactForm.push(
          {
            id: paymentInstruction?.id ?? '',
            currency: paymentInstruction?.currency ?? '',
            entity: { admin: entity?.maker ?? '', id: entity?.id ?? '' },
            entityName: paymentInstruction?.entityName ?? '',
            paymentInstructionType: paymentInstruction?.paymentInstructionType ?? '',
            paymentReference: paymentInstruction?.paymentReference ?? '',
            specialInstructions: paymentInstruction?.specialInstructions ?? '',
            accountWithInstitutionName:
              paymentInstruction?.accountWithInstitutionName ?? undefined,
            accountWithInstitutionBankAba:
              paymentInstruction?.accountWithInstitutionBankAba ?? '',
            accountWithInstitutionSwiftBic:
              paymentInstruction?.accountWithInstitutionSwiftBic ?? '',
            accountWithInstitutionIban:
              paymentInstruction?.accountWithInstitutionIban ?? '',
            beneficiaryCustomerName:
              paymentInstruction?.beneficiaryCustomerName ?? undefined,
            beneficiaryAccountNumber:
              paymentInstruction?.beneficiaryAccountNumber ?? undefined,
            beneficiaryIban: paymentInstruction?.beneficiaryIban ?? '',
            correspondentBank:
              paymentInstruction?.paymentInstructionType !== 'Intermediary'
                ? null
                : {
                  bankName: paymentInstruction?.correspondentBank?.bankName ?? '',
                  bankBic: paymentInstruction?.correspondentBank?.bankBic ?? '',
                  bankAba: paymentInstruction?.correspondentBank?.bankAba ?? '',
                },
            intermediaryBank:
              paymentInstruction?.paymentInstructionType !== 'Intermediary'
                ? null
                : {
                  bankName: paymentInstruction?.intermediaryBank?.bankName ?? '',
                  bankBic: paymentInstruction?.intermediaryBank?.bankBic ?? '',
                  bankAba: paymentInstruction?.intermediaryBank?.bankAba ?? '',
                },
            status: paymentInstruction?.status ?? 'Inactive',
            paymentMethod: paymentInstruction?.paymentMethod ?? undefined,
            receiverBic: paymentInstruction?.receiverBic ?? undefined,
            creditorAgent: paymentInstruction?.creditorAgent ?? undefined,
            creditorAgentBic: paymentInstruction?.creditorAgentBic ?? undefined,
            creditor: paymentInstruction?.creditor ?? undefined,
            creditorAccount: paymentInstruction?.creditorAccount ?? undefined,
            iban: paymentInstruction?.iban ?? undefined,
          }
        )
      })
      form.setValues({ wireInstructions: multiContactForm });
    }
  }, [multipleWireInstructions])

  useEffect(() => {
    dispatch(loadEntities())
  }, [])

  async function onSubmit(values: any) {
    setIsLoading(true)
    const multiWireInstructionUpdate = multipleWireInstructions?.find(
      p => cleanUUID(p?.id) === values?.id
    )
    const wireInstruction: WireInstructionParams = {
      ...values,
      entityName: entity?.entityName ?? '',
      hasError: false,
      hasSuccessfulEntitySave: false,
      ErrorMessage: '',
      customer_token: config.company.toUpperCase(),
    }

    const response: any = await dispatch(saveWireInstruction(wireInstruction, paymentInstruction ?? multiWireInstructionUpdate))

    setIsLoading(false)
    if (response?.success) {
      SuccessNotification({
        title: 'Successfully Saved Wire Instructions',
      })
      form.reset()
      closeModal()
    } else {
      ErrorNotification({
        title: 'Failed to Save Wire Instruction',
        message: response?.payload ?? 'Check fields and try again'
      })
    }
  }

  const submitForm = (event: any) => {
    event.preventDefault()
    form.values.wireInstructions.map((c) => {
      onSubmit(c)
    })
    onHandleSubmit(form.values.wireInstructions[0])
  }


  const fields = form.values?.wireInstructions?.map((wireInstructions, index) => {
    form.values?.wireInstructions?.map((wireInstructions, index) => {
      // if (wireInstructions.paymentInstructionType == 'Intermediary') {
      form.values.wireInstructions[index].correspondentBank = {
        bankName: form.values.wireInstructions[index].correspondentBank?.bankName ?? '',
        bankBic: form.values.wireInstructions[index].correspondentBank?.bankBic ?? '',
        bankAba: form.values.wireInstructions[index].correspondentBank?.bankAba ?? '',
      }
      form.values.wireInstructions[index].intermediaryBank = {
        bankName: form.values.wireInstructions[index].intermediaryBank?.bankName ?? '',
        bankBic: form.values.wireInstructions[index].intermediaryBank?.bankBic ?? '',
        bankAba: form.values.wireInstructions[index].intermediaryBank?.bankAba ?? '',
      }
      // }
    })
    return (
      <div key={'wireInstructionsForm' + index}>
        <form>
          <Stack w="100%">
            <Group w="100%">
              <Select
                w="49%"
                label="Currency"
                withAsterisk
                searchable
                data={currencyOptions}
                {...form.getInputProps(`wireInstructions.${index}.currency`)}
              />

              <Select
                searchable
                w="49%"
                label="Type"
                withAsterisk
                data={paymentInstructionTypeOptions}
                {...form.getInputProps(`wireInstructions.${index}.paymentInstructionType`)}
              />
            </Group>

            {wireInstructions.paymentInstructionType == 'Standard' && (
              <Stack>
                <Text className="wire-instruction-subtitle">
                  Account with Institution
                </Text>
                <Group noWrap>
                  <TextInput
                    w="100%"
                    label="Institution Name"
                    name="accountWithInstitutionName"
                    id="accountWithInstitutionName"
                    placeholder="Enter Institution Name"
                    {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionName`)}
                  />
                  {wireInstructions.currency == 'USD' || wireInstructions.currency == '' ? (
                    <TextInput
                      w="100%"
                      label="Bank ABA"
                      name="accountWithInstitutionBankAba"
                      id="accountWithInstitutionBankAba"
                      placeholder="Enter Bank ABA"
                      maxLength={9}
                      minLength={9}
                      {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionBankAba`)}
                    />
                  ) : null}
                  {wireInstructions.currency != 'USD' ? (
                    <TextInput
                      w="100%"
                      label="Swift BIC"
                      name="accountWithInstitutionSwiftBic"
                      id="accountWithInstitutionSwiftBic"
                      maxLength={11}
                      minLength={8}
                      placeholder="Enter Swift BIC"
                      {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionSwiftBic`)}
                    />
                  ) : null}
                </Group>
                <Stack>
                  <Text className="wire-instruction-subtitle">Beneficiary</Text>
                  <Group noWrap>
                    <TextInput
                      w="100%"
                      label="Customer Name"
                      name="beneficiaryCustomerName"
                      id="beneficiaryCustomerName"
                      placeholder="Enter Customer Name"
                      {...form.getInputProps(`wireInstructions.${index}.beneficiaryCustomerName`)}
                    />

                    <TextInput
                      w="100%"
                      label="Account Number"
                      name="beneficiaryAccountNumber"
                      id="beneficiaryAccountNumber"
                      placeholder="Enter Account Number"
                      {...form.getInputProps(`wireInstructions.${index}.beneficiaryAccountNumber`)}
                    />
                  </Group>
                  {wireInstructions.currency != 'USD' ? (
                    <TextInput
                      w="49.5%"
                      label="Account IBAN"
                      name="beneficiaryIban"
                      id="beneficiaryIban"
                      placeholder="Enter IBAN"
                      {...form.getInputProps(`wireInstructions.${index}.beneficiaryIban`)}
                    />
                  ) : null}
                </Stack>
              </Stack>
            )}

            {wireInstructions.paymentInstructionType == 'Intermediary' && (
              <Stack>
                <Text className="wire-instruction-subtitle">Correspondent</Text>
                <Group noWrap>
                  <TextInput
                    w="100%"
                    label="Bank Name"
                    name="correspondentBank.bankName"
                    id="correspondentBank.bankName"
                    placeholder="Enter Bank Name"
                    {...form.getInputProps(`wireInstructions.${index}.correspondentBank.bankName`)}
                  />
                  {wireInstructions.currency == 'USD' || wireInstructions.currency == '' ? (
                    <TextInput
                      w="100%"
                      label="Bank ABA"
                      name="correspondentBank.bankAba"
                      id="correspondentBank.bankAba"
                      placeholder="Enter Bank ABA"
                      maxLength={9}
                      minLength={9}
                      {...form.getInputProps(`wireInstructions.${index}.correspondentBank.bankAba`)}
                    />
                  ) : null}
                  {wireInstructions.currency != 'USD' ? (
                    <TextInput
                      w="100%"
                      label="Swift BIC"
                      name="correspondentBank.bankBic"
                      id="correspondentBank.bankBic"
                      placeholder="Enter Swift BIC"
                      maxLength={11}
                      minLength={8}
                      {...form.getInputProps(`wireInstructions.${index}.correspondentBank.bankBic`)}
                    />
                  ) : null}
                </Group>

                <Stack>
                  <Text className="wire-instruction-subtitle">Intermediary</Text>
                  <Group noWrap>
                    <TextInput
                      w="100%"
                      label="Bank Name"
                      name="intermediaryBank.bankName"
                      id="intermediaryBank.bankName"
                      placeholder="Enter Bank Name"
                      {...form.getInputProps(`wireInstructions.${index}.intermediaryBank.bankName`)}
                    />
                    {wireInstructions.currency == 'USD' ||
                      wireInstructions.currency == '' ? (
                      <TextInput
                        w="100%"
                        label="Bank ABA"
                        name="intermediaryBank.bankAba"
                        id="intermediaryBank.bankAba"
                        placeholder="Enter Bank ABA"
                        maxLength={9}
                        minLength={9}
                        {...form.getInputProps(`wireInstructions.${index}.intermediaryBank.bankAba`)}
                      />
                    ) : null}
                    {wireInstructions.currency != 'USD' ? (
                      <TextInput
                        w="100%"
                        label="Swift BIC"
                        name="intermediaryBank.bankBic"
                        id="intermediaryBank.bankBic"
                        placeholder="Enter Swift BIC"
                        maxLength={11}
                        minLength={8}
                        {...form.getInputProps(`wireInstructions.${index}.intermediaryBank.bankBic`)}
                      />
                    ) : null}
                  </Group>
                </Stack>
                <Stack>
                  <Text className="wire-instruction-subtitle">
                    Account with Institution
                  </Text>
                  <Group noWrap>
                    <TextInput
                      w="100%"
                      label="Account Name"
                      name="accountWithInstitutionName"
                      id="accountWithInstitutionName"
                      placeholder="Enter Account Name"
                      {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionName`)}
                    />
                    {wireInstructions.currency == 'USD' ||
                      wireInstructions.currency == '' ? (
                      <TextInput
                        w="100%"
                        label="Bank ABA"
                        name="accountWithInstitutionBankAba"
                        id="accountWithInstitutionBankAba"
                        placeholder="Enter Bank ABA"
                        maxLength={9}
                        minLength={9}
                        {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionBankAba`)}
                      />
                    ) : null}
                  </Group>
                  {wireInstructions.currency != 'USD' ? (
                    <Group noWrap>
                      <TextInput
                        w="100%"
                        label="Swift BIC"
                        name="accountWithInstitutionSwiftBic"
                        id="accountWithInstitutionSwiftBic"
                        minLength={8}
                        maxLength={11}
                        placeholder={
                          wireInstructions.accountWithInstitutionIban &&
                            wireInstructions.accountWithInstitutionIban.length > 0
                            ? 'Disabled for IBAN'
                            : 'Enter Swift BIC'
                        }
                        disabled={
                          wireInstructions.accountWithInstitutionIban &&
                            wireInstructions.accountWithInstitutionIban.length > 0
                            ? true
                            : false
                        }
                        {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionSwiftBic`)}
                      />
                      <TextInput
                        w="100%"
                        label="Account IBAN"
                        name="accountWithInstitutionIban"
                        id="accountWithInstitutionIban"
                        minLength={8}
                        maxLength={11}
                        placeholder={
                          wireInstructions.accountWithInstitutionSwiftBic &&
                            wireInstructions.accountWithInstitutionSwiftBic.length > 0
                            ? 'Disabled for BIC'
                            : 'Enter Account IBAN'
                        }
                        disabled={
                          wireInstructions.accountWithInstitutionSwiftBic &&
                            wireInstructions.accountWithInstitutionSwiftBic.length > 0
                            ? true
                            : false
                        }
                        {...form.getInputProps(`wireInstructions.${index}.accountWithInstitutionIban`)}
                      />
                    </Group>
                  ) : null}
                </Stack>
                <Stack>
                  <Text className="wire-instruction-subtitle">Beneficiary</Text>
                  <Group noWrap>
                    <TextInput
                      w="100%"
                      label=" Beneficiary Customer Name"
                      name="beneficiaryCustomerName"
                      id="beneficiaryCustomerName"
                      placeholder="Enter Beneficiary Customer Name"
                      {...form.getInputProps(`wireInstructions.${index}.beneficiaryCustomerName`)}
                    />

                    <TextInput
                      w="100%"
                      label="Beneficiary Account No."
                      name="beneficiaryAccountNumber"
                      id="beneficiaryAccountNumber"
                      placeholder="Enter Beneficiary Account No."
                      {...form.getInputProps(`wireInstructions.${index}.beneficiaryAccountNumber`)}
                    />
                  </Group>
                  {wireInstructions.currency != 'USD' ? (
                    <TextInput
                      w="49.5%"
                      label="Beneficiary IBAN"
                      name="beneficiaryIban"
                      id="beneficiaryIban"
                      placeholder="Enter Beneficiary IBAN"
                      {...form.getInputProps(`wireInstructions.${index}.beneficiaryIban`)}
                    />
                  ) : null}
                </Stack>
              </Stack>
            )}

            {wireInstructions.paymentInstructionType == 'ISO20022' && (
              <>
                <Group w="100%">
                  <Select
                    w="49%"
                    label="Payment Method"
                    withAsterisk
                    searchable
                    data={PaymentMethod}
                    {...form.getInputProps(`wireInstructions.${index}.paymentMethod`)}
                  />
                </Group>
                <Text className="wire-instruction-subtitle">
                  Intermediary Agent
                </Text>
                <Group w="100%">
                  <TextInput
                    w="49%"
                    label="Receiver BIC"
                    name="receiverBic"
                    id="receiverBic"
                    placeholder="Enter Receiver BIC"
                    {...form.getInputProps(`wireInstructions.${index}.receiverBic`)}
                  />
                  <TextInput
                    w="49%"
                    label="Creditor Agent"
                    name="creditorAgent"
                    id="creditorAgent"
                    placeholder="Enter Creditor Agent"
                    {...form.getInputProps(`wireInstructions.${index}.creditorAgent`)}
                  />
                </Group>
                <Group w="100%">
                  <TextInput
                    w="49%"
                    label="Creditor Agent BIC"
                    name="creditorAgentBic"
                    id="creditorAgentBic"
                    placeholder="Enter Creditor Agent BIC"
                    {...form.getInputProps(`wireInstructions.${index}.creditorAgentBic`)}
                  />
                </Group>
                <Text className="wire-instruction-subtitle">Creditor Agent</Text>
                <Group w="100%">
                  <TextInput
                    w="49%"
                    label="Creditor"
                    name="creditor"
                    id="creditor"
                    placeholder="Enter Creditor"
                    {...form.getInputProps(`wireInstructions.${index}.creditor`)}
                  />
                  <TextInput
                    w="49%"
                    label="Creditor Account #"
                    name="creditorAccount"
                    id="creditorAccount"
                    placeholder="Enter Creditor Account #"
                    {...form.getInputProps(`wireInstructions.${index}.creditorAccount`)}
                  />
                </Group>
                <Group w="100%">
                  <TextInput
                    w="49%"
                    label="IBAN"
                    name="iban"
                    id="iban"
                    placeholder="Enter IBAN"
                    {...form.getInputProps(`wireInstructions.${index}.iban`)}
                  />
                </Group>
              </>
            )}

            <TextInput
              w="100%"
              label="Payment Reference"
              name="paymentReference"
              id="paymentReference"
              placeholder="Enter Payment Reference"
              {...form.getInputProps(`wireInstructions.${index}.paymentReference`)}
            />

            <TextArea
              label="Special Instructions"
              name="specialInstructions"
              id="specialInstructions"
              placeholder="Enter Special Instructions"
              {...form.getInputProps(`wireInstructions.${index}.specialInstructions`)}
            />

            {(!paymentInstruction && index > 0) && (
              <Stack style={{ marginTop: '10px' }}>
                <Group noWrap>
                  <Group noWrap w={'75%'}></Group>
                  <Group noWrap w={'100%'}>
                    <IonIcon
                      name="trash-outline"
                      className="click-ion-icon"
                      style={{ color: "red" }}
                      onClick={() => form.removeListItem('wireInstructions', index)}
                    />
                    <span
                      style={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#111928',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      Remove
                    </span>
                  </Group>
                </Group>
              </Stack>
            )}
          </Stack>
        </form>
        {(!paymentInstruction && form.values.wireInstructions.length > 1) && (
          <Group
            noWrap
            style={{
              backgroundColor: '#D1D5DB',
              color: '#D1D5DB',
              height: '5px',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          ></Group>
        )}
      </div>
    )
  });





  return (
    <FormWrapper
      title={`${paymentInstruction ? 'Edit Wire Instruction' : 'Add Wire Instructions'} ${paymentInstruction ? 'from' : 'to'} Entity`}
    >
      <div className="content">
        <>{fields}</>

        {(!paymentInstruction) && (
          <Stack
            style={{ marginTop: form.values.wireInstructions.length > 0 ? '15px' : '0px', }}
          >
            <Group noWrap>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon
                  name="add-circle-outline"
                  className="click-ion-icon"
                  onClick={() =>
                    form.insertListItem('wireInstructions', {
                      id: undefined,
                      currency: '',
                      entity: { admin: entity?.maker ?? '', id: entity?.id ?? '' },
                      entityName: '',
                      paymentInstructionType: '',
                      paymentReference: '',
                      specialInstructions: '',
                      accountWithInstitutionName: undefined,
                      accountWithInstitutionBankAba: '',
                      accountWithInstitutionSwiftBic: '',
                      accountWithInstitutionIban: '',
                      beneficiaryCustomerName: undefined,
                      beneficiaryAccountNumber: undefined,
                      beneficiaryIban: '',
                      correspondentBank: null,
                      intermediaryBank: null,
                      status: 'Inactive',
                      paymentMethod: undefined,
                      receiverBic: undefined,
                      creditorAgent: undefined,
                      creditorAgentBic: undefined,
                      creditor: undefined,
                      creditorAccount: undefined,
                      iban: undefined,
                    })
                  }
                />
                <span
                  style={{
                    marginLeft: '8px',
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: 'bold',
                    fontSize: '1em',
                  }}
                >
                  Add Wire Instructions
                </span>
              </div>
            </Group>
          </Stack>
        )}

        <Stack>
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              gap: '.5rem',
              justifyContent: 'flex-end',
            }}
          >
            {isStepper ? (
              <>
                <PrimaryButton
                  className="form-button"
                  // disabled={!form.isValid()}  -> check validations
                  onClick={submitForm}
                  loading={isLoading}
                  type="submit"
                  w="100%"
                >
                  Save and Proceed
                </PrimaryButton>
              </>
            ) : (
              <>
                <PrimaryButton
                  className="form-button"
                  w="100%"
                  disabled={!form.isValid()}
                  onClick={submitForm}
                  loading={isLoading}
                  type="submit"
                  text="Save"
                />
                <PrimaryButton
                  className="form-button"
                  w="100%"
                  onClick={() => form.reset()}
                  text="Clear"
                />
              </>
            )}
          </div>
        </Stack>
      </div>
    </FormWrapper>
  )
}

export default WireInstructionsForm
