import EditLendersPositionDrawer from './edit-lenders-position-drawer'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import {
  getAmount,
  getPercentage,
} from 'app/state/ducks/lenders-positions/thunks'
import { CleanedFundData, LendersPosition } from 'app/models/lenders-position-params'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getWireInstructions } from 'app/state/ducks/wire-instructions/selectors'
import { WireInstructionParams } from 'app/models/wire-instruction-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { Stack } from '@mantine/core'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { getColumnDefs } from './table-columnDefs-fund'

interface Params {
  effectiveDate: Date
  currency: string
  lendersPositions: LendersPosition[]
  loading: boolean
}


export default function LenderAllocationTable({ effectiveDate, currency, lendersPositions, loading }: Params) {

  const entities = useSelector(getEntities)
  const contacts = useSelector(getContacts)
  const pulledWireInstructions: WireInstructionParams[] =
    useSelector(getWireInstructions)
  const [drawer, setDrawer] = useState<boolean>(false)
  const [lendersPosition, setLendersPosition] = useState<LendersPosition>()
  const [totalAmount, setTotalAmount] = useState('');
  const totalAmountRef = useRef(totalAmount);


  function handleRowClick(e: any) {
    setDrawer(true)
    setLendersPosition(e)
  }
  const contactIdToName = new Map<string, string>()
  if (Array.isArray(contacts)) {
    for (const contact of contacts) {
      if (contact.id) {
        contactIdToName.set(contact.id, contact.name)
      }
    }
  }

  function cleanFund(
    lendersPositions: LendersPosition[],
  ): CleanedFundData[] {
    return lendersPositions?.map((position: any) => {
      const lpObj: any = {}
      const amount = getAmount(position, effectiveDate)
      const percentage = getPercentage(position, effectiveDate)
      const wireInstructionsIds = position.wireInstructions.map(
        (wireInstruction: { id: string }) => wireInstruction.id
      )
      const wireInstructions = new Set(
        (pulledWireInstructions?.filter(wireInstruction =>
          wireInstructionsIds.includes(wireInstruction.id)
        ) ?? [])
          .map((wireInstruction) => wireInstruction.currency)
      )

      const entity = entities?.find(item => item.id == position.lender.id);
      const fundManagerOrParent = entity?.fundManager || entity?.parent;
      lpObj['Lender'] = entities?.find(item => item.id == position.lender.id)?.entityName
      lpObj['Fund Manager / Parent'] = fundManagerOrParent
      lpObj['Allocation'] = formatNumberToCurrency(Number(amount) ?? 0, currency)
      lpObj['MEI'] = entity?.mei
      lpObj['Percentage'] = percentage + '%'
      lpObj['Wire Instructions'] = [...wireInstructions].join(', ')
      lpObj['Admin Contact'] = contactIdToName.get(
        cleanUUID(position?.adminContactId?.id ?? '')
      )
      lpObj['Credit Contact'] = contactIdToName.get(
        cleanUUID(position?.creditContactId?.id ?? '')
      )
      lpObj['id'] = position.lender.id
      return lpObj
    })
  }

  useEffect(() => {
    totalAmountRef.current = totalAmount;
  }, [totalAmount]);

  const getTotalAmount = useCallback(() => totalAmountRef.current, []);

  useEffect(() => {
    const totalAmount =
      formatNumberToCurrency(
        lendersPositions
          ? lendersPositions.reduce(
            (acc: number, curr: LendersPosition) => acc + getAmount(curr, effectiveDate),
            0
          )
          : 0,
        currency ?? 'USD'
      )
    setTotalAmount(totalAmount)
  }, [lendersPositions])


  return (
    <>
      <Stack w="100%">
        <TableColumnClickExclusion
          tableName='Lender History'
          csvExportEnabled={true}
          columnDefs={getColumnDefs(getTotalAmount)}
          data={cleanFund(lendersPositions)}
          setRow={(position) => {
            const lp = lendersPositions.find(
              (lenderPosition) => lenderPosition.lender.id === position.id
            )
            handleRowClick(lp)
          }}
          isLoading={loading}
        />
      </Stack>

      <EditLendersPositionDrawer
        setOpen={setDrawer}
        open={drawer}
        lendersPosition={lendersPosition}
      />
    </>
  )
}