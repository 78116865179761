import {
  Group,
  Stack
} from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import { FacilityParams } from 'app/models/facility-params'
import FacilityActions from './facility-actions'

interface props {
  facility: FacilityParams
}

export default function FacilityHeader({ facility }: props): JSX.Element {
  return (
    <Stack>
      <Group position="apart" align="center">
        <PageTitle text={facility ? facility.name : ''} />
        <Group noWrap>
          {facility && (
            <FacilityActions row={facility} extended withRedirection />
          )}
        </Group>
      </Group>
    </Stack>
  )
}
