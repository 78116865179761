import React, { } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { ServicingPageData } from './servicing-page-data'
import ServicingHeader from './servicing-header'


export function ServicingPage() {

  return (
    <PageLayout>
      <ServicingHeader />
      <ServicingPageData />
    </PageLayout>
  )
}
