import { AmountWithDate, Fees } from 'app/models/fees-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'

export type FeeState = {
  facilityFees: Fees[],
  schedulerFee: AmountWithDate[],
  fees: Fees[]
}

const initialState: FeeState = { facilityFees: [], schedulerFee: [], fees: []}

const feesReducer = createReducer(initialState)({
  [types.GET_FEES_BY_FACILITY]: (state: FeeState, payload: any) => {
    return { ...state, facilityFees: payload.payload }
  },
  [types.CHANGE_FEES_FOR_FACILITY]: (state: FeeState, payload: any) => {
    return {
      ...state,
      facilityFees: payload.payload,
    }
  },
  [types.EDIT_FEES]: (state: FeeState, payload: any) => {
    const facilityIndex = state.facilityFees.findIndex(
      (fee) => fee.id === payload.payload.id
    )
    if (facilityIndex !== -1) {
      const newArray = [...state.facilityFees]
      newArray[facilityIndex] = payload.payload
      return {
        ...state,
        facilityFees: newArray,
      }
    }
  },
  [types.RESET_FACILITY_FEES]: (state: FeeState) => {
    return { ...state, facilityFees: [] }
  },

  [types.GET_SCHEDULER]: (state: FeeState, payload: any) => {
    return { ...state, schedulerFee: payload.payload }
  },
  [types.GET_ALL_FEES]: (state: FeeState, payload: any) => {
    return { ...state, fees: payload.payload }
  }
})

export default feesReducer
