import { Stack } from '@mantine/core'
import { formatNumberToCurrency } from 'app/utils/util-functions'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { getColumnDefs } from './table-columnDefs-mandatory-payment-summary'

type Props = {
  acceptedAmount: number
  interestDue: number
  totalLender: number
  acceptedLender: number
  currency: string
  totalAmount: number
}

export default function MandatoryPaymentSummary({ acceptedAmount, interestDue, totalLender, acceptedLender, currency, totalAmount }: Props) {
  return (
    <Stack spacing={0}>
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Mandatory Payment Summary
      </div>
      <TableColumnClickExclusion
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        data={[{
          'Accepted Amount': formatNumberToCurrency(acceptedAmount, currency),
          'Total Amount': formatNumberToCurrency(totalAmount, currency),
          'Total Lenders': totalLender,
          'Accepted Lenders': acceptedLender,
          'Interest Amount': formatNumberToCurrency(interestDue, currency)
        }]}
        setRow={() => {
        }}
        withPagination={false}
        minHeight={'100px'}
      />
    </Stack>

  )
}
