import { PageLayout } from 'app/views/components/layout/page-layout'
import LoansPage from './loans-page'

export default function FacilityLoansPage() {
  return (
    <PageLayout>
      <LoansPage />
    </PageLayout>
  )
}
