import { Stack } from '@mantine/core'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { useEffect, useRef, useState } from 'react'
import { FacilityParams } from 'app/models/facility-params'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getLockedFacilities, getFacilityLoader } from 'app/state/ducks/facilities/selectors'
import { getDeals, getLockedDeals } from 'app/state/ducks/deals/selectors'
import { MRT_ColumnDef } from 'mantine-react-table'
import { DealParams } from 'app/models/deal-params'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getLockedColumnDefs from './locked-facility-table-columnDefs'
import { mapEntityIdsToNames } from 'app/utils/util-functions'
import { getKPIData } from '../facilities-kpi-data'

export default function LockedFacilitiesPage() {
  const navigate = useNavigate()

  const allFacilities = useSelector(getLockedFacilities)
  const facilityLoader = useSelector(getFacilityLoader)
  const [globalLoading, setGlobalLoading] = useState(false)
  const lockedDeals = useSelector(getLockedDeals)
  const deals = useSelector(getDeals)
  const entities = useSelector(getEntities)

  const [facilities, setFacilities] = useState<FacilityParams[]>([])
  const [filteredFacilities, setFilteredFacilities] =
    useState<FacilityParams[]>(facilities)
  const [columnDefs, setColumnDefs] = useState<MRT_ColumnDef<FacilityParams>[]>(
    []
  )
  const [allDeals, setAllDeals] = useState<DealParams[]>([])
  const dealsRef = useRef(allDeals);
  const kpiData = getKPIData(facilities, setFilteredFacilities)

  useEffect(() => {
    setGlobalLoading(facilityLoader)
  }, [facilityLoader])

  useEffect(() => {
    setFilteredFacilities(facilities)
  }, [facilities])

  useEffect(() => {
    setFacilities(allFacilities)
    setFilteredFacilities(allFacilities)
  }, [allFacilities])

  useEffect(() => {
    setAllDeals(deals.concat(lockedDeals))
  }, [deals, lockedDeals])

  useEffect(() => {
    dealsRef.current = allDeals;
  }, [allDeals]);

  useEffect(() => {
    if (!entities) {
      return
    }
    setColumnDefs(getLockedColumnDefs(entities))
  }, [entities, allDeals])

  // Function to filter and update deal and entity names
  function cleanFacilityData(
    filteredFacilities: FacilityParams[],
    deals: any[]
  ): FacilityParams[] {
    const entityIdToName = mapEntityIdsToNames(entities);

    return (
      filteredFacilities.map((facts) => {
        const dealName = deals.find((deal) => deal.id === cleanUUID(facts?.dealId.id))?.dealName ?? '';
        const adminEntityName = facts.adminEntity?.id
          ? entityIdToName.get(cleanUUID(facts.adminEntity.id)) ?? ''
          : '';

        return {
          ...facts,
          dealName,
          adminEntityName,
        };
      }).sort((a, b) => a.dealName.localeCompare(b.dealName)) ?? []
    );
  }

  return (
    <Stack>
      <KPI_Section
        kpiData={kpiData}
        isLoading={globalLoading}
      />
      {columnDefs.length != 0 ? (
        <TableColumnClickExclusion
          tableName='Locked_Facilities Table'
          isLoading={globalLoading}
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          columnDefs={columnDefs}
          data={cleanFacilityData(filteredFacilities, deals)}
          setRow={(e: FacilityParams, cellName: string) => {
            if (cellName != 'id') {
              navigate('/dealmanagement/facilities/' + e.id + '/' + e.status)
            }
          }}
        />
      ) : null}
    </Stack>
  )
}
