import { LoanParams } from 'app/models/loan-params'
import { Stack } from '@mantine/core'
import { formatNumberToCurrency, numberOfDaysBetweenTwoDates } from 'app/utils/util-functions'
import TableColumnClickExclusion from '../Table2/TableColumnClickExclusion'
import { getColumnDefs } from './table-columnDefs-loan-summary'

type Props = {
  loan: LoanParams
}

export default function CurrentLoanSummary({ loan }: Props) {
  return (
    <Stack spacing={0}>
      <div
        style={{
          backgroundColor: '#A4CAFE',
          width: '100%',
          padding: '10px',
          color: 'black',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        Current Loan Summary
      </div>
      <TableColumnClickExclusion
        tableName='Loan History'
        csvExportEnabled={true}
        columnDefs={getColumnDefs()}
        data={[{
          'Start Date': loan.startDate,
          'End Date': loan.endDate,
          'Loan Amount': formatNumberToCurrency(Number(loan.amount), loan.currency),
          'Base Rate': loan.interestBaseRate + "%",
          'Margin': loan.margin + "%",
          'All-In Rate': loan.allInRate + "%",
          'Days': Math.round(numberOfDaysBetweenTwoDates(new Date(loan.startDate), new Date(loan.endDate))),
          'Interest Due': formatNumberToCurrency(Number(loan.accrualInterestMaturityDate), loan.currency)
        }]}
        setRow={() => {
        }}
        withPagination={false}
        minHeight={'100px'}
      />
    </Stack>

  )
}
