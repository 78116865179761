import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'


export default function ServicingHeader() {

  return (
    <Group position="apart" style={{ marginBottom: '15px' }}>
      <PageTitle>Servicing</PageTitle>
      <PrimaryButton>Create Activity</PrimaryButton>
    </Group >
  )
}
