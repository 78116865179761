import { Stack } from '@mantine/core'
import { NoticePage } from '../../notices/+notices-page'

export default function LockedNoticesPageData() {

  return (
    <Stack>
      <NoticePage tab='archived' />
    </Stack>
  )
}
