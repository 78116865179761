import { Anchor, Avatar, Badge, Group, Tooltip } from '@mantine/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ClickText } from 'app/views/components/Headers&Text/Text'
import { useNavigate, useParams } from 'react-router-dom'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import MainAccordion from 'app/views/components/Accordion/main-accordion'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import getInitials from 'app/views/components/functions/getInitials'
import { loadOneDeal, setOneDeal } from 'app/state/ducks/deals/thunks'
import { getDeal, getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import { getContacts } from 'app/state/ducks/contacts/selectors'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import { getCalendars } from 'app/state/ducks/holiday-calendars/selectors'
import { loadCalendars } from 'app/state/ducks/holiday-calendars/thunks'
import { formatNumberToCurrency } from 'app/utils/util-functions'

export default function DealAccordion() {
  const { dealId, status } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const entityIdToName = new Map<string, string>()
  const deal: DealParams | undefined = useSelector(getDeal)
  const entities = useSelector(getEntities)
  const contacts = useSelector(getContacts)
  const dealAmendments: DealParams[] = useSelector(getDealAmendments)
  const allDeals: DealParams[] = useSelector(getDeals)
  const holidayCalendars = useSelector(getCalendars)
  const dealFee = deal?.fees ?? []

  useEffect(() => {
    if (!dealId) {
      return
    }

    dispatch(loadEntities())
    dispatch(loadContacts())
    dispatch(loadCalendars())

    if (allDeals) {
      const selectedDeal = allDeals.find(deal => deal.id === dealId)
      if (selectedDeal) {
        dispatch(setOneDeal(selectedDeal))
      } else {
        dispatch(loadOneDeal(dealId, status ?? 'Draft'))
      }
    }
    if (dealAmendments) {
      const amended_deal = dealAmendments.find(
        amendment => amendment.id === dealId
      )
      if (amended_deal) {
        amended_deal.status = amended_deal.status + ' Amendment'
        dispatch(setOneDeal(amended_deal))
      }
    }
  }, [dealId])

  useEffect(() => {
    if (Array.isArray(entities)) {
      for (const entity of entities) {
        if (entity.id) {
          entityIdToName.set(cleanUUID(entity.id), entity.entityName)
        }
      }
    }
  }, [entities])

  function dealCalendars() {
    if (holidayCalendars && deal?.holidayCalendar && holidayCalendars.length > 0 && deal?.holidayCalendar.length > 0) {
      const dealCals = deal?.holidayCalendar.map((calendar: any) => {
        return holidayCalendars.find(hc => hc.id === calendar.id)?.name
      })
      return dealCals.join(', ')
    }
    return ('')
  }

  return (
    <MainAccordion
      key={dealId}
      value="deal accordion"
      title={deal?.dealName ?? ''}
      data={[
        {
          label: 'Amount',
          value: formatNumberToCurrency(Number(deal?.amount) ?? 0, deal?.currency ?? 'USD'),
        }, {
          label: 'Utilized Amount',
          value: formatNumberToCurrency(Number(deal?.utilizedAmount) ?? 0, deal?.currency ?? 'USD'),
        },
        {
          label: 'Start Date',
          value: deal?.startDate,
        },
        {
          label: 'Maturity Date',
          value: deal?.maturity,
        },
        {
          label: "CP's Met",
          value: deal?.precedentDate,
        },
        {
          label: 'Deal ID',
          value: deal?.id,
        },
        {
          label: 'Deal CUSIP/ISIN',
          value: deal?.cusipIsin,
        },
        {
          label: (
            <Anchor
              onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/borrowers`, {
                  state: { deal, entities },
                })
              }
            >
              <Group>
                <ClickText text="Borrowers" />
                <Badge size="xl" color="violet" p="0.8rem">
                  {deal?.borrowers?.length}
                </Badge>
              </Group>
            </Anchor>
          ),
          value: (
            <Group
              onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/borrowers`, {
                  state: { deal, entities },
                })
              }
            >
              <Tooltip.Group openDelay={300} closeDelay={100}>
                <Avatar.Group spacing="sm">
                  {deal?.borrowers?.map((borrower, i) => {
                    const entityName =
                      entities?.find(
                        entity => entity?.id === cleanUUID(borrower?.id)
                      )?.entityName ?? ''
                    return (
                      <Anchor key={'deal-borrowers' + i}>
                        <Tooltip label={entityName} withArrow>
                          <Avatar color="violet" radius="xl">
                            {getInitials(entityName)}
                          </Avatar>
                        </Tooltip>
                      </Anchor>
                    )
                  })}
                </Avatar.Group>
              </Tooltip.Group>
            </Group>
          ),
        },
        {
          label: (
            <Anchor
              onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/guarantors`, {
                  state: { deal, entities },
                })
              }
            >
              <Group noWrap>
                <ClickText text="Guarantors" />
                <Badge size="xl" color="teal" p="0.8rem">
                  {deal?.guarantors?.length}
                </Badge>
              </Group>
            </Anchor>
          ),
          value: (
            <Group
              noWrap
              onClick={() =>
                navigate(`/dealmanagement/deal/${deal?.id}/guarantors`, {
                  state: { deal, entities },
                })
              }
            >
              <Tooltip.Group openDelay={300} closeDelay={100}>
                <Avatar.Group spacing="sm">
                  {deal?.guarantors?.map((guarantor, index) => {
                    const entityName =
                      entities?.find(
                        entity => entity?.id === cleanUUID(guarantor?.id)
                      )?.entityName ?? ''
                    return (
                      <Anchor key={'facility-accordion-lender-' + index}>
                        <Tooltip label={entityName} withArrow>
                          <Avatar size="md" color="teal" radius="xl">
                            {getInitials(entityName)}
                          </Avatar>
                        </Tooltip>
                      </Anchor>
                    )
                  })}
                </Avatar.Group>
              </Tooltip.Group>
            </Group>
          ),
        },
        {
          label: 'Ops Admin',
          value: (
            <Tooltip
              label={
                contacts && deal
                  ? contacts.find(
                    contact => contact.id === cleanUUID(deal?.opsAdmin?.id)
                  )?.name ?? ''
                  : ''
              }
            >
              <Anchor>
                <Avatar size="md" color="cyan" radius="xl">
                  {getInitials(
                    contacts && deal
                      ? contacts.find(
                        contact =>
                          contact.id === cleanUUID(deal?.opsAdmin?.id)
                      )?.name ?? ''
                      : ''
                  )}
                </Avatar>
              </Anchor>
            </Tooltip>
          ),
        },
        {
          label: 'Currencies',
          value: deal?.currency,
        },
        {
          label: 'Holiday Calendar',
          value: dealCalendars(),
        },
        {
          label: <Anchor
            onClick={() =>
              navigate(`/dealmanagement/deal/${deal?.id}/${deal?.status}/pricing-grid`)
            }
          >
            <Group noWrap>
              <ClickText text="Pricing Grid" />
            </Group>
          </Anchor>,
          value: deal?.isPricingGrid.toString(),
        },
        {
          label: dealFee[0]?.feeType ? <Anchor
            onClick={() =>
              navigate(`/dealmanagement/deal/${deal?.id}/${deal?.status}/fees/${dealFee[0]?.feeType}`)
            }
          >
            <Group noWrap>
              <ClickText text="Fees" />
            </Group>
          </Anchor> : 'Fees',
          value: dealFee.length.toString() ?? '0',
        },
        {
          label: 'Administrative Agent',
          value: (
            <Tooltip
              label={
                entities && deal
                  ? entities.find(
                    entity => entity.id === cleanUUID(deal?.adminEntity?.id)
                  )?.entityName ?? ''
                  : ''
              }
            >
              <Anchor>
                <Avatar size="md" color="cyan" radius="xl">
                  {getInitials(
                    entities && deal
                      ? entities.find(
                        entity =>
                          entity.id === cleanUUID(deal?.adminEntity?.id)
                      )?.entityName ?? ''
                      : ''
                  )}
                </Avatar>
              </Anchor>
            </Tooltip>
          ),
        },
        {
          label: 'Administrative Agent MEI',
          value: entities && deal
            ? entities.find(
              entity =>
                entity.id === cleanUUID(deal?.adminEntity?.id)
            )?.mei ?? ''
            : ''
        },
      ].concat(
        deal?.amendment
          ? [
            {
              label: 'Amendment Date',
              value: deal?.amendmentDate ?? '',
            },
          ]
          : []
      )}
    />
  )
}
