import * as types from './types'

export const getLoanRollovers = (payload: any) => ({
  type: types.GET_LOAN_ROLLOVERS,
  payload,
})

export const getApprovedLoanRollovers = (payload: any) => ({
  type: types.GET_APPROVED_LOAN_ROLLOVERS,
  payload,
})

export const addLoanRollover = (payload: any) => ({
  type: types.ADD_LOAN_ROLLOVER,
  payload,
})

export const editLoanRollover = (payload: any) => ({
  type: types.EDIT_LOAN_ROLLOVER,
  payload,
})

export const submitLoanRollover = (payload: any) => ({
  type: types.SUBMIT_LOAN_ROLLOVER,
  payload,
})

export const approveLoanRollover = (payload: any) => ({
  type: types.APPROVE_LOAN_ROLLOVER,
  payload,
})

export const rejectLoanRollover = (payload: any) => ({
  type: types.REJECT_LOAN_ROLLOVER,
  payload,
})

export const deleteLoanRollover = (payload: any) => ({
  type: types.DELETE_LOAN_ROLLOVER,
  payload,
})
