import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Tooltip, Avatar } from '@mantine/core'
import { PaymentParams } from 'app/models/payments-params'
import { ReactNode } from 'react'
// import getInitials from 'app/views/components/functions/getInitials'
import { PaymentTransactionType } from 'app/models/dropdown-options'
import getInitials from 'app/views/components/functions/getInitials'
import { StatusPill } from 'app/views/components/pill/status-pill'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export function cols(
): MRT_ColumnDef<PaymentParams>[] {
  return [
    {
      size: 40,
      accessorKey: 'payment.facilityName',
      header: 'Facility Name',
      Header: <TableHead header={'Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      )
    },
    {
      size: 40,
      accessorKey: 'payment.borrowerName',
      header: 'Entity',
      Header: <TableHead header={'Borrower Name'} />,
      Cell: ({ cell }) => (
        <TableRowText text={cell.getValue<string>()} />
      )
    },
    {
      size: 50,
      accessorKey: 'status',
      header: 'Stage',
      Header: <TableHead header={'Stage'} />,
      Cell: ({ cell, row }: any) => (
        <StatusPill status={cell.getValue()} payment={row.original} />
      ),
    },
    {
      size: 50,
      accessorFn: row => {
        return PaymentTransactionType.find(p => p.value == row.payment.transactionType)?.label ?? '';
      },
      accessorKey: 'payment.transactionType',
      header: 'Transaction',
      Header: <TableHead header={'Transaction'} />,
      Cell: ({ row }) => {
        return (
          <TableRowText
            text={
              PaymentTransactionType.find(p => p.value == row.original.payment.transactionType)
                ?.label ?? ''
            }
          />
        )
      },
    },
    {
      size: 50,
      accessorKey: 'payment.currency',
      header: 'Currency',
      Header: <TableHead header={'Currency'} />,
      Cell: ({ row }) => <TableRowText text={row.original.payment.currency ?? 'USD'} />,
    },
    {
      size: 50,
      accessorKey: 'payment.amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      filterVariant: 'range',
      Cell: ({ cell, row }: any) => (
        <TableRowText
          text={formatNumberToCurrency(cell.getValue(), row.original.payment.currency ?? 'USD')}
        />
      ),
    },
    {
      size: 50,
      accessorKey: 'payment.effectiveDate',
      header: 'Effective Date',
      Header: <TableHead header={'Effective Date'} />,
      accessorFn: row => {
        return stringToDate(row.payment.effectiveDate);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
      ),
    },
    {
      size: 50,
      accessorKey: 'payment.opsAdminName',
      header: 'User',
      Header: <TableHead header={'User'} />,
      Cell: ({ cell }) => (
        <Tooltip label={cell.getValue<string>()}>
          <Avatar radius="xl" color="cyan" size="lg">
            {getInitials(cell.getValue<string>()?.toString())}
          </Avatar>
        </Tooltip>
      )
    },
  ]
}
