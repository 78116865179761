import { Menu } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useDispatch } from 'react-redux'
import {
    approveNotice,
    submitNotice,
    rejectNotice,
} from './notice-daml-actions'
import { NoticeParams } from 'app/models/notices-params'
import { preview } from 'app/state/ducks/notices/thunks'
import { ErrorNotification } from 'app/views/components/notifications/notification'
import IonIcon from '@reacticons/ionicons'

type Props = {
    row: NoticeParams
}

export default function NoticeActions({
    row,
}: Props) {
    const dispatch = useDispatch()

    const previewNotice = async (row: NoticeParams) => {
        const newTab = window.open('', '_blank');
        if (!newTab) {
            ErrorNotification({
                title: 'Cannot open new Tab in this browser',
                message: 'Please use other browser or change settings',
            })
            return
        }
        const response = await preview(row.id, row.status)
        if (!response.success) {
            newTab.close()
            ErrorNotification({
                title: 'Failed to preview notice',
                message: response.payload,
            })
            return
        }

        newTab.document.write(decodeHtml(response.payload.html));
    }

    const decodeHtml = (html: string) => {
        return html.replace(/=E2=82=AC/g, '€'); // Euro sign
    };

    async function submitCurrentNotice() {
        await submitNotice(row, dispatch)
    }

    async function approveCurrentNotice() {
        await approveNotice(row, dispatch)
    }

    async function rejectCurrentNotice() {
        await rejectNotice(row, dispatch)
    }

    return (
        <>
            <Menu shadow="md" width={200} zIndex={1000}>
                <Menu.Target>
                    <div style={{ width: 'fit-content' }}>
                        <PrimaryDropDown>Actions</PrimaryDropDown>
                    </div>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item
                        icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                        onClick={() => previewNotice(row)}
                    >
                        Preview
                    </Menu.Item>
                    {row?.status?.toLowerCase() == 'draft' ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => submitCurrentNotice()}
                        >
                            Submit For Approval
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'submitted' ? (
                        <Menu.Item
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => approveCurrentNotice()}
                        >
                            Approve
                        </Menu.Item>
                    ) : null}

                    {row?.status?.toLowerCase() == 'submitted' ? (
                        <Menu.Item
                            color="red"
                            icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
                            onClick={() => rejectCurrentNotice()}
                        >
                            Reject
                        </Menu.Item>
                    ) : null}
                </Menu.Dropdown>
            </Menu>
        </>
    )
}
