import { Accordion, Group, Stack, Text } from '@mantine/core'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'

interface Row {
  label: string | ReactNode
  value: ReactNode | string | number | Date
}

interface Props {
  title: string
  value?: string
  data?: Row[]
}

export default function MainAccordion({ title, data, value }: Props) {
  const businessDate = useSelector(getBusinessDate)
  return (
    <Accordion.Item value={value ?? title ?? ''}>
      <Accordion.Control>
        <Text color="white" fw={600} fz="lg">
          {title ?? ''}
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack>
          {data?.map(({ label, value }: Row, index) => {
            let valueDisplay: ReactNode
            let labelDisplay: ReactNode

            if (value instanceof Date) {
              value = new Date(value ?? businessDate).toLocaleDateString('en-US')
            }
            if (typeof value === 'object') {
              valueDisplay = value
            }
            if (typeof value === 'string' || typeof value === 'number') {
              valueDisplay = (
                <Text
                  fz="1rem"
                  fw={400}
                  color="#111928"
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                  }}
                >
                  {value}
                </Text>
              )
            }

            if (typeof label === 'string' || typeof label === 'number') {
              labelDisplay = (
                <Text
                  fz="1.1rem"
                  fw={800}
                  color="#6B7280"
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                  }}
                >
                  {label}
                </Text>
              )
            }
            if (typeof label === 'object') {
              labelDisplay = label
            }

            return (
              <Group position="apart" key={index} noWrap>
                {labelDisplay}
                {valueDisplay}
              </Group>
            )
          })}
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  )
}
