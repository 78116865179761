import { Title } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  text?: string
  children?: ReactNode
}

export default function PageTitle({ text, children }: Props) {
  return (
    <Title
      order={1}
      fw={600}
      style={{
        fontSize: '3rem',
        fontFamily: 'Plus Jakarta Sans',
        color: '#001565',
      }}
    >
      {text ?? children ?? ''}
    </Title>
  )
}
