import { FeePaymentParams } from 'app/models/fee-payment-params'
import * as types from './types'
import { createReducer } from 'app/state/utils'


export interface FeePaymentState {
  feePayment: FeePaymentParams[]
}
const initialState: FeePaymentState = {feePayment:[]}

const feePaymentsReducer = createReducer(initialState)({
  [types.GET_FEE_PAYMENTS]: (state: any, payload: any) => {
    return { ...state, feePayment: payload.payload }
  },

  [types.ADD_FEE_PAYMENT]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }
    return {
      ...state,
      feePayment: [payload.payload, ...state.feePayment],
    }
  },

  [types.APPROVE_FEE_PAYMENT]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }
    const index = state.feePayment.findIndex(
      (feePayment: { id: any }) => feePayment.id == payload.payload.id
    )
    if (index >= 0) {
      state.feePayment.splice(index, 1)
    }
    return {
      ...state,
      feePayment: [...state.feePayment],
    }
  },
  [types.EDIT_FEE_PAYMENT]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }
    const index = state.feePayment.findIndex(
      (feePayment: { id: any }) => feePayment.id == payload.payload.id
    )
    const newArray = [...state.feePayment]
    if (index >= 0) {
      newArray[index] = {...payload.payload, status: 'Draft'}
    }
    return {
      ...state,
      feePayment: newArray,
    }
  },
  
  [types.REJECT_FEE_PAYMENT]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }

    const updatedFeePayment = state.feePayment.find(
      (feePayment: { id: any }) => feePayment.id === payload.payload.id
    )
    if (updatedFeePayment) {
      updatedFeePayment.status = 'Draft'
    }
    return {
      ...state,
      feePayment: [...state.feePayment],

    }
  },
  [types.SUBMIT_FEE_PAYMENT]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }

    const updatedFeePayment = state.feePayment.find(
      (feePayment: { id: any }) => feePayment.id === payload.payload.id
    )
    if (updatedFeePayment) {
      updatedFeePayment.status = 'Submitted'
    }
    return {
      ...state,
    }
  },

  [types.DELETE_FEE_PAYMENT]: (state: any, payload: any) => {
    if (payload.error) {
      return state
    }

    const index = state.feePayment.findIndex(
      (feePayment: { id: any }) => feePayment.id == payload.payload.id
    )
    if (index >= 0) {
      state.feePayment.splice(index, 1)
    }
    return {
      ...state,
      feePayment: [...state.feePayment],
    }
  },
})

export default feePaymentsReducer
