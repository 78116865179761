import {
    Group,
    Stack,
    Text,
} from '@mantine/core'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity } from 'app/state/ducks/entities/selectors'
import { loadOneEntity } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import NotesAndAttachment from 'app/views/components/notes-and-attachemnt/notes-and-attachment-full'
import SectionTitle from 'app/views/components/Headers&Text/SectionTitle'
import { EntityProfileOptions, EntityTypeOptions, kycStatusOptions } from 'app/models/dropdown-options'
import { entityTypeWithoutFundManager } from 'app/models/entity-params'


export default function EntityPage() {
    const { entityId, status } = useParams()
    const entity = useSelector(getEntity)

    const dispatch = useDispatch()


    useEffect(() => {
        if (entityId) {
            dispatch(loadOneEntity(entityId, status ?? 'Inactive'))
            dispatch(loadNoteByContract(entityId, ContractType.Entities))
        }
    }, [entityId])

    const isFundManager = !entityTypeWithoutFundManager.includes(entity?.entityType);

    return (
        <Stack w="100%">
            <Stack w="100%" className='sectionRemoveGap'>
                <SectionTitle>Entity Overview</SectionTitle>
                <Stack w='100%' className='sectionRemoveGap sectionBody'>
                    <Group w='100%' noWrap className='sectionRemoveGap' align='top'>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Entity Details</Text>
                            <Group w='100%' noWrap>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>Entity Type</Text>
                                    <Text className='sectionItem'>{EntityTypeOptions.find(option => option.value === entity?.entityType)
                                        ?.label || 'N/A'}</Text>
                                    <Text className='sectionItemTitle'>Institution Type</Text>
                                    <Text className='sectionItem'>Institution Type</Text>
                                    <Text className='sectionItemTitle'>{isFundManager
                                        ? 'Fund Manager'
                                        : 'Parent'}</Text>
                                    <Text className='sectionItem'>{isFundManager
                                        ? entity?.fundManager
                                        : entity?.parent}</Text>
                                </Stack>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>Entity Profile</Text>
                                    <Text className='sectionItem'>{entity?.entityProfile
                                        .map(
                                            profile =>
                                                EntityProfileOptions.find(option => option.value === profile)
                                                    ?.label || ''
                                        )
                                        .filter(Boolean)
                                        .join(', ') ?? 'N/A'}</Text>
                                    <Text className='sectionItemTitle'>Kyc Status</Text>
                                    <Text className='sectionItem'>{kycStatusOptions.find(
                                        option => option.value === entity?.kycStatus
                                    )?.label || 'Pending'}</Text>
                                    <Text className='sectionItemTitle'>{isFundManager
                                        ? 'Fund Manager MEI'
                                        : 'Parent MEI'}</Text>
                                    <Text className='sectionItem'>{isFundManager
                                        ? entity?.fundManagerMei
                                        : entity?.parentMei}</Text>
                                </Stack>
                            </Group>
                        </Stack>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Address</Text>
                            <Group w='100%' noWrap>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>Address</Text>
                                    <Text className='sectionItem'>{entity?.address}</Text>
                                    <Text className='sectionItemTitle'>City</Text>
                                    <Text className='sectionItem'>{entity?.city}</Text>
                                    <Text className='sectionItemTitle'>ZIP/Postal Code</Text>
                                    <Text className='sectionItem'>{entity?.zipCode}</Text>
                                </Stack>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>Address Line 2</Text>
                                    <Text className='sectionItem'>{entity?.address2 ?? 'N/A'}</Text>
                                    <Text className='sectionItemTitle'>State/Region</Text>
                                    <Text className='sectionItem'>{entity?.state}</Text>
                                    <Text className='sectionItemTitle'>Country of Incorporation</Text>
                                    <Text className='sectionItem'>{entity?.countryOfIncorporation}</Text>
                                </Stack>
                            </Group>
                        </Stack>
                    </Group>
                    <Group w='100%' noWrap className='sectionRemoveGap' align='top'>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Identifiers</Text>
                            <Group w='100%' noWrap align='top'>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>CRN #</Text>
                                    <Text className='sectionItem'>{entity?.crn}</Text>
                                    <Text className='sectionItemTitle'>EIN</Text>
                                    <Text className='sectionItem'>{entity?.ein}</Text>
                                    <Text className='sectionItemTitle'>NAIC Code</Text>
                                    <Text className='sectionItem'>XXXXXXX</Text>
                                    <Text className='sectionItemTitle'>Internal Entity ID</Text>
                                    <Text className='sectionItem'>{entity?.id}</Text>
                                </Stack>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>MEI</Text>
                                    <Text className='sectionItem'>{entity?.mei}</Text>
                                    <Text className='sectionItemTitle'>GIIN</Text>
                                    <Text className='sectionItem'>{entity?.giin}</Text>
                                    <Text className='sectionItemTitle'>LEI</Text>
                                    <Text className='sectionItem'>{entity?.legalEntityId}</Text>
                                </Stack>
                            </Group>
                        </Stack>
                        <Stack w='50%' className='sectionDetailBody'>
                            <Text className='sectionDetailHeader'>Tax</Text>
                            <Group w='100%' noWrap>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>Entity Regerence</Text>
                                    <Text className='sectionItem'>Entity Type</Text>
                                    <Text className='sectionItemTitle'>US Treaty Passport #</Text>
                                    <Text className='sectionItem'>{entity?.ukTreatyPassportNumber}</Text>
                                    <Text className='sectionItemTitle'>US TaxForm Status</Text>
                                    <Text className='sectionItem'>{entity?.taxFormStatus}</Text>
                                </Stack>
                                <Stack w='50%'>
                                    <Text className='sectionItemTitle'>US TaxForm Type</Text>
                                    <Text className='sectionItem'>{entity?.usTaxFormType}</Text>
                                    <Text className='sectionItemTitle'>US Treaty Passport Exp Date</Text>
                                    <Text className='sectionItem'>{entity?.ukTreatyPassportExpiryDate}</Text>
                                    <Text className='sectionItemTitle'>Country Of Tax Residence</Text>
                                    <Text className='sectionItem'>{entity?.countryOfTaxResidence}</Text>
                                </Stack>
                            </Group>
                        </Stack>
                    </Group>
                </Stack>
            </Stack>
            <Group w="100%" noWrap>
                <NotesAndAttachment
                    itemId={entityId ?? ''}
                    type={ContractType.Entities}
                    itemAdmin={entity?.maker ?? ''}
                />
            </Group>
        </Stack>
    )
}