import { Menu, Modal } from '@mantine/core'
import PrimaryDropDown from 'app/views/components/DropDown/DropDown'
import { useState } from 'react'
import { getDeals } from 'app/state/ducks/deals/selectors'
import { DealParams } from 'app/models/deal-params'
import { useDispatch, useSelector } from 'react-redux'
import {
  approveDeal,
  deleteDeal,
  rejectDeal,
  submitDeal,
} from './deal-daml-actions'
import {
  deleteDealAmendment,
  submitDealAmendment,
  approveDealAmendment,
  rejectDealAmendment,
} from '../deal-amendment-details/deal-amendment-daml-actions'
import { getDealAmendments } from 'app/state/ducks/deal-amendments/selectors'
import HistoryForm from './history/history'
import { useNavigate } from 'react-router-dom'
import { UpdatedFacilitiesScreen } from '../../facilities/updated-facilities-screen'
import DealTerminationForm from '../locked-deals/deal-termination-form'
import IonIcon from '@reacticons/ionicons'
import DealsForm from './deal-form'

type Props = {
  row: DealParams
  withRedirection?: boolean
  extended?: boolean
}

export default function DealActions({ row, withRedirection, extended }: Props) {
  const [openEdit, setOpenEdit] = useState(false)
  const [deal, setDeal] = useState<DealParams | undefined>(undefined)
  const [dealAmendment, setDealAmendment] = useState<DealParams | null>(null)
  const [openFacility, setOpenFacility] = useState(false)
  const [amendment, setAmendment] = useState<string | null>(null)
  const [openHistory, setOpenHistory] = useState(false)
  const [openTerminate, setOpenTerminate] = useState(false)
  const navigate = useNavigate()
  const deals: DealParams[] = useSelector(getDeals)
  const dealAmendments: DealParams[] = useSelector(getDealAmendments)
  const dispatch = useDispatch()

  function editDeal(dealId: string) {
    setDeal(deals.find(f => f.id == dealId))
    setOpenEdit(true)
  }

  function amendDeal(dealId: string) {
    setDeal(deals.find(f => f.id == dealId))
    const amended_deal = dealAmendments?.find(
      amendment => amendment.id === dealId
    )
    setDealAmendment(amended_deal ?? null)
    setAmendment('amend')
    setOpenEdit(true)
  }

  function terminateDeal(dealId: string) {
    setDeal(deals.find(f => f.id == dealId))
    const amended_deal = dealAmendments?.find(
      amendment => amendment.id === dealId
    )
    setDealAmendment(amended_deal ?? null)
    setOpenTerminate(true)
  }

  function displayHistory(): any {
    setDeal(row)
    setOpenHistory(true)
  }

  async function submitCurrentDeal() {
    const result = await submitDeal(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Submitted`)
    }
  }

  async function approveCurrentDeal() {
    const result = await approveDeal(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Approved`)
    }
  }

  async function rejectCurrentDeal() {
    const result = await rejectDeal(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Draft`)
    }
  }
  async function submitCurrentDealAmendment() {
    const result = await submitDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Subbmitted`)
    }
  }
  async function approveCurrentDealAmendment() {
    const result = await approveDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Approved`)
    }
  }
  async function rejectCurrentDealAmendment() {
    const result = await rejectDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Draft`)
    }
  }
  async function deleteCurrentDeal() {
    await deleteDeal(row, dispatch)
    if (withRedirection) {
      navigate(`/dealmanagement`)
    }
  }
  async function deleteCurrentDealAmendment() {
    const result = await deleteDealAmendment(row, dispatch)
    if (result && withRedirection) {
      navigate(`/dealmanagement/deal/${row.id}/Approved`)
    }
  }

  return (
    <div>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openEdit}
        onClose={() => setOpenEdit(false)}
        centered={true}
        size="77%"
      >
        <DealsForm
          closeModal={() => setOpenEdit(false)}
          deal={deal ?? {}}
          useStepper={false}
          amendment={amendment}
          dealAmendment={dealAmendment}
        />
      </Modal>
      <Menu shadow="md" width={200} position="bottom" withinPortal>
        <Menu.Target>
          <div style={{ width: 'fit-content' }}>
            <PrimaryDropDown>Actions</PrimaryDropDown>
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
              onClick={() => editDeal(row.id)}
            >
              Edit
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
            row?.amendmentType?.toLowerCase() === 'termination' ? (
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  terminateDeal(row.id)
                }}
              >
                Terminate
              </Menu.Item>
            ) : (
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  amendDeal(row.id)
                }}
              >
                Amendment
              </Menu.Item>
            )
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => submitCurrentDealAmendment()}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => submitCurrentDeal()}
            >
              Submit For Approval
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => approveCurrentDealAmendment()}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && !row?.amendment ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => approveCurrentDeal()}
            >
              Approve
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'approved' ||
            row?.amendment?.status?.toLowerCase() === '' ? (
            <>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  amendDeal(row.id)
                }}
              >
                Amendment
              </Menu.Item>
              <Menu.Item
                icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
                onClick={() => {
                  terminateDeal(row.id)
                }}
              >
                Terminate
              </Menu.Item>
            </>
          ) : null}
          {extended ? (
            <Menu.Item
              icon={<IonIcon className="action-ion-icon" name='settings-outline' />}
              onClick={() => {
                setOpenFacility(true)
              }}
            >
              Create Facility
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() != 'approved' ||
            row?.amendment?.status?.toLowerCase() === 'draft' ? (
            <>
              <Menu.Divider />
              <Menu.Label>Danger zone</Menu.Label>
            </>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='trash-outline' />}
              onClick={() => deleteCurrentDealAmendment()}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'draft' && !row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => deleteCurrentDeal()}
            >
              Delete
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => rejectCurrentDealAmendment()}
            >
              Reject
            </Menu.Item>
          ) : null}

          {row?.status?.toLowerCase() == 'submitted' && !row?.amendment ? (
            <Menu.Item
              color="red"
              icon={<IonIcon className="action-ion-icon" name='swap-horizontal-outline' />}
              onClick={() => rejectCurrentDeal()}
            >
              Reject
            </Menu.Item>
          ) : null}
          <Menu.Item
            onClick={() => displayHistory()}
            icon={<IonIcon className="action-ion-icon" name='arrow-undo-circle-outline' />}
          >
            History
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Modal
        size="90%"
        opened={openHistory}
        onClose={() => setOpenHistory(false)}
      >
        {deal && (
          <HistoryForm
            dealId={deal.id ?? ''}
            close={() => setOpenHistory(false)}
          />
        )}
      </Modal>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        size="xl"
        // style={{ height: '80%' }}
        opened={openTerminate}
        onClose={() => setOpenTerminate(false)}
      >
        {deal && (
          <DealTerminationForm
            deal={dealAmendment ?? deal}
            close={() => setOpenTerminate(false)}
          />
        )}
      </Modal>
      <Modal
        className="modal-body modal-body-template fit-content-modal"
        opened={openFacility}
        onClose={() => setOpenFacility(false)}
        centered={true}
        size="77%"
      >
        <UpdatedFacilitiesScreen
          deal={row}
          facility={undefined}
          onClose={() => setOpenFacility(false)}
        />
      </Modal>
    </div>
  )
}
