import React from 'react'
import { PageLayoutUpdated } from 'app/views/components/layout/page-layout-updated'
import PositionsHeader from './positions-header'
import { PositionsDrillPage } from './position-drill-page'


export function PositionsLandingPage(tab: { tab: string }) {
  return (
    <PageLayoutUpdated>
      <PositionsHeader />
      <PositionsDrillPage tab={tab.tab} />
    </PageLayoutUpdated>
  )
}
