import { FeeState } from './reducer'

export const getFacilityFees = (state: { fees: FeeState }) =>
  state.fees.facilityFees

export const getFeeScheduler = (state: { fees: FeeState }) =>
  state.fees.schedulerFee

export const getAllFees = (state: { fees: FeeState }) =>
  state.fees.fees
