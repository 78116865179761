import { Group } from "@mantine/core";
import { DealParams } from "app/models/deal-params";
import PageTitle from "app/views/components/Headers&Text/PageTitle";
import DealActions from "./deal-actions";

interface props {
  deal: DealParams;
}

export default function DealHeader({ deal }: props) {
  return (
    <Group noWrap position="apart">
      <PageTitle text={deal ? deal.dealName ?? 'Deal' : ''} />
      <Group noWrap>
        {deal && <DealActions row={deal} withRedirection extended />}
      </Group>
    </Group>
  )
}
