import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Tooltip, Avatar, Anchor } from '@mantine/core'
import { ReactNode } from 'react'
import TradeClosingActions from './trade-closing-actions'
import getInitials from 'app/views/components/functions/getInitials'
import { EntityParams } from 'app/models/entity-params'
import { FacilityParams } from 'app/models/facility-params'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import StatusPill from '../deals-management/common/pill-status'
import { TradeClosingPageData } from './+trade-closing-page'
import { formatNumberToCurrency, stringToDate } from 'app/utils/util-functions'

function TableHead({ header }: { header: string }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {header}
    </Text>
  )
}

function TableRowText({ text }: { text: ReactNode }) {
  return (
    <Text
      style={{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '1.5em',
      }}
    >
      {text}
    </Text>
  )
}

export default function getColumnDefs(
  entities: EntityParams[],
  facilities: FacilityParams[]
): MRT_ColumnDef<TradeClosingPageData>[] {
  return [
    {
      size: 40,
      accessorFn: row => {
        const facilityId = row.facilityId.id ?? ''
        const fn = facilities.find(facility => facility.id === cleanUUID(facilityId))?.name ?? ''
        return fn
      },
      accessorKey: 'facilityId',
      header: 'Facility Name',
      Header: <TableHead header={'Facility Name'} />,
      Cell: ({ row }) => {
        const facilityId = row.original?.facilityId?.id ?? ''
        const facilityName =
          facilities.find(facility => facility.id === cleanUUID(facilityId))
            ?.name ?? ''
        return <TableRowText text={facilityName} />
      },
    },
    {
      size: 50,
      accessorKey: 'effectiveDate',
      header: 'Effective Date',
      Header: <TableHead header={'Effective Date'} />,
      accessorFn: row => {
        return stringToDate(row.effectiveDate);
      },
      mantineFilterTextInputProps: {
        placeholder: 'Date',
      },
      filterFn: 'lessThanOrEqualTo',
      filterVariant: 'date',
      sortingFn: 'datetime',
      Cell: ({ cell }) => (
        <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
      ), //render Date as a string
    },
    {
      size: 50,
      accessorKey: 'from',
      header: 'Seller',
      Header: <TableHead header={'Seller'} />,
      Cell: ({ cell }) => {
        const from = cell.getValue<{ id: string; admin: string }[]>()
        return (
          <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
              {from?.map((from, index) => {
                const entityName = entities.find(
                  entity => entity.id === cleanUUID(from.id)
                )?.entityName
                return (
                  <Anchor key={index + 'seller'}>
                    <Tooltip label={entityName} withArrow>
                      <Avatar size="lg" color="teal" radius="xl">
                        {getInitials(entityName)}
                      </Avatar>
                    </Tooltip>
                  </Anchor>
                )
              })}
            </Avatar.Group>
          </Tooltip.Group>
        )
      },
    },
    {
      size: 50,
      accessorKey: 'to',
      header: 'Buyer',
      Header: <TableHead header={'Buyer'} />,
      Cell: ({ cell }) => {
        const to = cell.getValue<{ id: string; admin: string }[]>()
        return (
          <Tooltip.Group openDelay={300} closeDelay={100}>
            <Avatar.Group spacing="sm">
              {to?.map((to, index) => {
                const entityName = entities.find(
                  entity => entity.id === cleanUUID(to.id)
                )?.entityName
                return (
                  <Anchor key={index + 'buyer'}>
                    <Tooltip label={entityName} withArrow>
                      <Avatar size="lg" color="teal" radius="xl">
                        {getInitials(entityName)}
                      </Avatar>
                    </Tooltip>
                  </Anchor>
                )
              })}
            </Avatar.Group>
          </Tooltip.Group>
        )
      },
    },
    {
      size: 50,
      accessorKey: 'amount',
      header: 'Amount',
      Header: <TableHead header={'Amount'} />,
      Cell: ({ row }) => {
        const facility = facilities.find(
          facility => facility.id === cleanUUID(row.original.facilityId.id)
        )
        return (
          <TableRowText
            text={formatNumberToCurrency(Number(row.original.amount), facility?.baseCurrency ?? 'USD')}
          />
        )
      },
    },
    {
      size: 40,
      accessorKey: 'status',
      header: 'Status',
      Header: <TableHead header={'Status'} />,
      Cell: ({ cell }: any) => {
        return <StatusPill state={cell.getValue()} />
      },
    },
    {
      size: 40,
      accessorKey: 'id',
      header: 'Actions',
      Header: <TableHead header={'Actions'} />,
      enableColumnActions: false,
      enableColumnFilter: false,
      Cell: cell => <TradeClosingActions row={cell.row.original} />,
    },
    // {
    //   accessorKey: 'attachments',
    //   header: 'Attachments',
    //   Header: <TableHead header={'Attachments'} />,
    //   size: 50,
    //   Cell: ({ cell }) => <TableRowText text={cell.getValue<string>()} />,
    // },
  ]
}
