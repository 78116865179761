import React, { useMemo } from 'react';
import { Group } from '@mantine/core';
import { MantineReactTable } from 'mantine-react-table';
import type { MRT_ColumnDef } from 'mantine-react-table';
import IonIcon from '@reacticons/ionicons';
import ExportToCsv from '../ExportToCsv/ExportToCsv';
import { CustomLoaderPage } from '../loader/loader-page';

type Props<T extends Record<string, T>> = {
  tableName: string
  csvExportEnabled?: boolean
  columnDefs: MRT_ColumnDef<T>[]
  data?: T[]
  setRow: (e: any, z: string) => any
  enableColumnOrdering?: boolean
  enableGrouping?: boolean
  enableColumnFilters?: boolean
  enableColumnActions?: boolean
  enableFilters?: boolean
  enableSorting?: boolean
  enableFullScreenToggle?: boolean
  enableDensityToggle?: boolean
  enableHiding?: boolean
  enableTopToolbar?: boolean
  minHeight?: string
  overflow?: string
  withPagination?: boolean
  headerBackgroundColor?: string
  isLoading?: boolean
}

export default function TableColumnClickExclusion<
  T extends Record<string, any>
>({
  tableName,
  csvExportEnabled,
  columnDefs,
  data,
  setRow,
  enableColumnOrdering,
  enableGrouping,
  enableColumnFilters,
  enableColumnActions,
  enableFilters,
  enableSorting,
  enableFullScreenToggle,
  enableDensityToggle,
  enableHiding,
  enableTopToolbar,
  minHeight,
  overflow,
  headerBackgroundColor,
  withPagination = true,
  isLoading = false,
}: Props<T>) {
  const columns = useMemo<MRT_ColumnDef<T>[]>(() => columnDefs, [])

  const exportData = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((row) => {
      const formattedRow = columns.map((col) => {
        const value = row[col.accessorKey as keyof T];
        const key = col.header === 'Actions' ? col.accessorKey : col.header;

        return {
          [`${key}`]: value
        };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {});

      return formattedRow;
    });
  }, [data, columns]);

  if (isLoading) {
    return <CustomLoaderPage />
  }

  return (
    <Group sx={{
      overflowX: 'auto',
      maxWidth: '100%',
    }}>
      <MantineReactTable
        mantineTableHeadCellProps={{
          sx: {
            backgroundColor: headerBackgroundColor ? headerBackgroundColor : 'none', // Set the background color of header cells to red
          },
        }}
        defaultColumn={{
          maxSize: 400,
          minSize: 50,
          size: 150,
        }}
        enableColumnResizing
        columns={columns}
        data={data ?? []}
        mantineTableBodyProps={{
          sx: {
            //stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5',
            },
          },
        }}
        enableColumnOrdering={enableColumnOrdering ?? true}
        enableGrouping={enableGrouping ?? true}
        enableColumnFilters={enableColumnFilters ?? true}
        enableColumnActions={enableColumnActions ?? true}
        enableFilters={enableFilters ?? true}
        enableSorting={enableSorting ?? true}
        enableFullScreenToggle={enableFullScreenToggle ?? true}
        enableStickyHeader
        enableDensityToggle={enableDensityToggle ?? true}
        enableHiding={enableHiding ?? true}
        enableTopToolbar={enableTopToolbar ?? true}
        mantineTableContainerProps={{
          sx: {
            overflow: overflow ?? 'scroll',
            minHeight: minHeight ?? '250px',
            maxWidth: '100%',
          },
        }}
        mantineBottomToolbarProps={{
          mih: "5.5rem"
        }}
        renderBottomToolbarCustomActions={() => (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {csvExportEnabled && (
              <ExportToCsv
                csvName={tableName}
                exportObj={exportData}
                style={{ width: '150px', height: '35px', backgroundColor: '#32439A', color: '#fff' }}
              />
            )}
            <div style={{ marginLeft: 'auto' }}>
              {/* Pagination component */}
            </div>
          </div>
        )}
        mantineTopToolbarProps={{
          sx: {
            maxWidth: '100%',
          }
        }}
        mantineFilterDateInputProps={{
          firstDayOfWeek: 0,
        }}
        mantineTableBodyCellProps={({ cell, row }) => ({
          onClick: () => {
            setRow(row.original, cell.column.id)
          },
        })}
        icons={{
          IconDotsVertical: () => <IonIcon name='funnel-outline' />,
        }}
        // PAGINATION
        enableBottomToolbar={withPagination}
        enablePagination={withPagination}
        autoResetPageIndex={true}
        paginationDisplayMode='pages'
        mantinePaginationProps={{
          rowsPerPageOptions: ["10", "15", "20", "25"],
          size: 'xl',
          radius: 'xl',
          siblings: 1,
          withEdges: false,
        }}
        initialState={{ pagination: { pageSize: 25, pageIndex: 0 } }}

      />
    </Group>
  )
}
