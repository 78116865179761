import { Group, Stack, Title } from '@mantine/core'
import { ReactNode } from 'react'

interface Props {
  text?: string
  children?: ReactNode
}

export default function PageTitleUpdated({ text, children }: Props) {
  return (
    <Stack w="100%">
      <Group noWrap w="100%">
        <Title
          order={1}
          fw={600}
          className='pageTitleUpdated'
        >
          {text ?? children ?? ''}
        </Title>
      </Group>
      <Group noWrap w="100%" className='pageTitleGap'>
      </Group>
    </Stack>
  )

}