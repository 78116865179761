import * as types from './types'
import { createReducer } from 'app/state/utils'
import { LoanIncreaseParams } from 'app/models/loan-increase-params'

export interface LoanIncreaseState {
  loanIncreases: LoanIncreaseParams[]
}

const initialState: LoanIncreaseState = {
  loanIncreases: [],
}

const loanIncreasesReducer = createReducer(initialState)({
  [types.GET_LOAN_INCREASES]: (state: any, payload: any) => {
    return { ...state, loanIncreases: payload.payload }
  },

  [types.EDIT_LOAN_INCREASE]: (state: any, payload: any) => {
    const index = state.loanIncreases.findIndex(
      (loanIncrease: { id: any }) => loanIncrease.id === payload.payload.data.id
    )
    const newArray = [...state.loanIncreases]
    newArray[index] = { ...payload.payload.data, status: 'Draft' }
    return {
      ...state,
      loans: newArray,
    }
  },

  [types.ADD_LOAN_INCREASE]: (state: any, payload: any) => {
    return {
      ...state,
      loanIncreases: [
        { ...payload.payload.data, status: 'Draft' },
        ...state.loanIncreases,
      ],
    }
  },

  [types.SUBMIT_LOAN_INCREASE]: (state: any, payload: any) => {
    const obj = state.loanIncreases.find(
      (loanIncrease: { id: any }) => loanIncrease.id == payload.payload.id
    )
    obj.status = 'Submitted'
    return {
      ...state,
      loanIncreases: [...state.loanIncreases],
    }
  },

  [types.APPROVE_LOAN_INCREASE]: (state: any, payload: any) => {
    const obj = state.loanIncreases.find(
      (loanIncrease: { id: any }) => loanIncrease.id == payload.payload.id
    )
    obj.status = 'Approved'

    return {
      ...state,
      loanIncreases: [...state.loanIncreases],
    }
  },

  [types.REJECT_LOAN_INCREASE]: (state: any, payload: any) => {
    const obj = state.loanIncreases.find(
      (loanIncrease: { id: any }) => loanIncrease.id == payload.payload.id
    )
    obj.status = 'Draft'
    return {
      ...state,
      loanIncreases: [...state.loanIncreases],
    }
  },

  [types.DELETE_LOAN_INCREASE]: (state: any, payload: any) => {
    const index = state.loanIncreases.findIndex(
      (loanIncrease: { id: any }) => loanIncrease.id == payload.payload.id
    )
    if (index >= 0) {
      state.loanIncreases.splice(index, 1)
    }
    return {
      ...state,
      loanIncreases: [...state.loanIncreases],
    }
  },
})

export default loanIncreasesReducer