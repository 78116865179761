import { PageLayout } from 'app/views/components/layout/page-layout'
import ReleaseQueueHeader from '../notices/release-queue-header'
import LockedNoticesPageData from './release-queue-notices-page-data'

export function LockedNoticesPage() {

  return (
    <PageLayout>
      <ReleaseQueueHeader />
      <LockedNoticesPageData />
    </PageLayout>
  )
}
