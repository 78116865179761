import { PageLayout } from 'app/views/components/layout/page-layout'
import FacilityContentPage from './facility-content-page'

export default function SingleFacilityPage() {
  return (
    <PageLayout>
      <FacilityContentPage />
    </PageLayout>
  )
}
