import { MRT_ColumnDef } from 'mantine-react-table'
import { Text, Tooltip, Avatar } from '@mantine/core'
import { NoticeParams } from 'app/models/notices-params'
import { ReactNode } from 'react'
import getInitials from 'app/views/components/functions/getInitials'
import NoticeActions from './notice-actions'
import { StatusPill } from 'app/views/components/pill/status-pill'
import { stringToDate } from 'app/utils/util-functions'
import { NoticeTypeOptions } from 'app/models/dropdown-options'
import { formatNumberToCurrency } from 'app/utils/util-functions'


function TableHead({ header }: { header: string }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {header}
        </Text>
    )
}

function TableRowText({ text }: { text: ReactNode }) {
    return (
        <Text
            style={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '1.5em',
            }}
        >
            {text}
        </Text>
    )
}

export function cols(
): MRT_ColumnDef<NoticeParams>[] {
    return [
        {
            size: 40,
            accessorKey: 'facilityName',
            header: 'Facility Name',
            Header: <TableHead header={'Name'} />,
            Cell: ({ cell }) => (
                <TableRowText text={cell.getValue<string>()} />
            )
        },
        {
            size: 50,
            accessorKey: 'status',
            header: 'Stage',
            Header: <TableHead header={'Stage'} />,
            Cell: ({ cell }: any) => (
                <StatusPill status={cell.getValue()} />
            ),
        },
        {
            size: 50,
            accessorKey: 'facilityCusipIsin',
            header: 'Facility CUSIP/ISIN',
            Header: <TableHead header={'Facility CUSIP/ISIN'} />,
            Cell: ({ cell }) => (
                <TableRowText text={cell.getValue<string>()} />
            ),
        },
        {
            size: 50,
            accessorFn: row => {
                const noticeType = NoticeTypeOptions.find(p => p.value == row.noticeType);
                if (['NewBorrowingNotice', 'NewBorrowingBorrowerInvoice'].includes(noticeType?.value ?? '')) {
                    const index =
                        row.metaData.tag === "NewBorrowingMetaData"
                            ? row.metaData.value.newBorrowingGlobal.loanIndexRate
                            : row.metaData.value.newBorrowingInvoiceGlobal.loanIndexRate
                    return noticeType?.label.replace("{index}", index) ?? ''
                }
                return noticeType?.label ?? '';
            },
            accessorKey: 'noticeType',
            header: 'Notice Type',
            Header: <TableHead header={'Notice Type'} />,
            Cell: ({ row }) => {
                const noticeType = NoticeTypeOptions.find(p => p.value == row.original.noticeType);
                let cel = noticeType?.label ?? '';
                if (['NewBorrowingNotice', 'NewBorrowingBorrowerInvoice'].includes(noticeType?.value ?? '')) {
                    const index =
                        row.original.metaData.tag === "NewBorrowingMetaData"
                            ? row.original.metaData.value.newBorrowingGlobal.loanIndexRate
                            : row.original.metaData.value.newBorrowingInvoiceGlobal.loanIndexRate
                    cel = noticeType?.label.replace("{index}", index) ?? ''
                }
                return <TableRowText text={cel} />
            },
        },
        {
            size: 50,
            accessorKey: 'effectiveDate',
            header: 'Effective Date',
            Header: <TableHead header={'Effective Date'} />,
            accessorFn: row => {
                const noticeType = NoticeTypeOptions.find(p => p.value == row.noticeType);
                let effectiveDate;

                // If it's a ConversionNotice, extract effective date of the conversion transaction (otherwise, it pulls the effective date of the original loan)
                if (noticeType?.value === "ConversionNotice") {
                    effectiveDate = row.metaData.tag === "ConversionMetaData" ? row.metaData.value.conversionGlobal?.conversionEffectiveDate : row.metaData.value.conversionInvoiceGlobal?.conversionEffectiveDate;
                    return stringToDate(String(effectiveDate));
                    
                // If not a ConversionNotice, return the regular row amount
                } else {
                    return stringToDate(row.effectiveDate);
                }
            },
            mantineFilterTextInputProps: {
                placeholder: 'Date',
            },
            filterFn: 'lessThanOrEqualTo',
            filterVariant: 'date',
            sortingFn: 'datetime',
            Cell: ({ cell }) => (
                <Text fz="1.5em">{cell.getValue<Date>()?.toLocaleDateString()}</Text>
            ),
        },
        {
            size: 50,
            accessorKey: 'amount',
            header: 'Amount',
            Header: <TableHead header={'Amount'} />,
            filterVariant: 'range',
            accessorFn: row => {
                const noticeType = NoticeTypeOptions.find(p => p.value == row.noticeType);
    
                // If it's a ConversionNotice, extract conversionAmount (otherwise, it pulls the amount remaining on the old loan)
                if (noticeType?.value === "ConversionNotice") {
                    const conversionAmount = row.metaData.tag === "ConversionMetaData" 
                        ? Number(row.metaData.value.conversionGlobal?.conversionAmount.replace(/[$,]/g, ''))  // convert from formatted currency to number
                        : Number(row.metaData.value.conversionInvoiceGlobal?.conversionAmount.replace(/[$,]/g, '')) // convert from formatted currency to number
                    
                    return conversionAmount || 0;
                }
            
                // If not a ConversionNotice, return the regular row amount
                return row.amount || 0;
            },
            Cell: ({ cell, row }: any) => (
                <TableRowText
                    text={formatNumberToCurrency(cell.getValue(), row.amount ?? 'USD')}
                />
            ),
        },
        {
            size: 50,
            accessorKey: 'receiver',
            header: 'Receiver',
            Header: <TableHead header={'Receiver'} />,
            filterVariant: 'range',
            Cell: ({ cell }: any) => (
                <TableRowText text={cell.getValue()} />
            ),
        },
        {
            size: 40,
            accessorKey: 'id',
            header: 'Actions',
            Header: <TableHead header={'Actions'} />,
            enableColumnActions: false,
            enableColumnFilter: false,
            Cell: cell => <NoticeActions row={cell.row.original} />,
        },
        {
            size: 50,
            accessorKey: 'numberOfNotices',
            header: 'No of Notices',
            Header: <TableHead header={'No of Notices'} />,
            Cell: ({ cell }) => (
                <Tooltip label={cell.getValue<string>()}>
                    <Avatar radius="xl" color="cyan" size="lg">
                        {getInitials(cell.getValue<string>()?.toString())}
                    </Avatar>
                </Tooltip>
            )
        },
    ]
}
