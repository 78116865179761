import { ContactParams } from 'app/models/contact-params'
import { getContacts, getContactsLoader } from 'app/state/ducks/contacts/selectors'
import { loadContacts } from 'app/state/ducks/contacts/thunks'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContactsModal } from '../entity-contacts/contacts-modal'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import { contactCols } from '../entity-contacts/contacts-columnDefs'

interface Props {
  entityId?: string
}

export default function ManageEntityContacts({ entityId }: Props) {
  const dispatch = useDispatch()
  const contacts: ContactParams[] = useSelector(getContacts)
  const contactsLoader = useSelector(getContactsLoader)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [contact, setContact] = useState<undefined | ContactParams>(undefined)

  useEffect(() => {
    setGlobalLoading(contactsLoader)
  }, [contactsLoader])

  useEffect(() => {
    dispatch(loadContacts())
  }, [])

  return (
    <>
      {contacts ? (
        <TableColumnClickExclusion
          tableName='Contacts Table'
          isLoading={globalLoading}
          data={contacts.filter(
            p => cleanUUID(p?.entity?.id) === entityId
          ).sort((a, b) => a.name.localeCompare(b.name)) ?? []}
          columnDefs={contactCols}
          setRow={(e: ContactParams, cellName: string) => {
            if (cellName != 'id') {
              setContact(e)
              setEdit(true)
            }
          }}
        />
      ) : null}
      <ContactsModal open={edit} setOpen={setEdit} contact={contact} entityId={entityId} />
    </>
  )
}