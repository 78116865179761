import {
    Anchor,
    Breadcrumbs,
    Group,
    Stack,
} from '@mantine/core'
import IonIcon from '@reacticons/ionicons'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getEntity } from 'app/state/ducks/entities/selectors'
import { loadOneEntity } from 'app/state/ducks/entities/thunks'
import { ContractType } from 'app/models/common-types'
import EntityHeader from './entity-header'
import { loadNoteByContract } from 'app/state/ducks/notes/thunks'
import EntityActions from '../entity-actions'
import SegmentControlUpdated from 'app/views/components/segments/segment-control-updated'
import ContactsPage from '../entity-contacts/contacts-page'
import WireInstructionsPage from '../entity-wire-instructions/wire-instructions-page'
import PositionsPage from '../entity-positions/positions-page'
import EntityPage from './entity-page'


interface props {
    tab: string
}

export function EntityDrillPage({ tab }: props) {
    const { entityId, status } = useParams()

    const tabs: { [key in props['tab']]: { route: string; label: string; active: boolean; pageRender: JSX.Element } } = {
        entityBase: { route: `/entitymanagement/entity/${entityId}/${status}`, label: 'ENTITY', active: tab === 'entityBase' ? true : false, pageRender: <EntityPage /> },
        entityContacts: { route: `/entitymanagement/contacts/${entityId}/${status}`, label: 'CONTACTS', active: tab === 'entityContacts' ? true : false, pageRender: <ContactsPage /> },
        entityWI: { route: `/entitymanagement/wireinstructions/${entityId}/${status}`, label: 'WIRE INSTRUCTIONS', active: tab === 'entityWI' ? true : false, pageRender: <WireInstructionsPage /> },
        entityPositions: { route: `/entitymanagement/positions/${entityId}/${status}`, label: 'POSITIONS', active: tab === 'entityPositions' ? true : false, pageRender: <PositionsPage /> },
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const entity = useSelector(getEntity)

    const items = [
        { title: 'Entity Management', href: '/entitymanagement' },
        { title: entity?.entityName },
    ].map((item, index) => (
        <Anchor
            onClick={item.href ? () => navigate(item.href) : () => null}
            key={index}
            color="#374151"
            fw={500}
            fz="14px"
        >
            {item.title}
        </Anchor>
    ))

    useEffect(() => {
        if (entityId) {
            dispatch(loadOneEntity(entityId, status ?? 'Inactive'))
            dispatch(loadNoteByContract(entityId, ContractType.Entities))
        }
    }, [entityId])

    return (
        <Stack w="100%">
            <Breadcrumbs
                separator={<IonIcon name="chevron-forward-outline" />}
                mt="xs"
            >
                {items}
            </Breadcrumbs>
            <EntityHeader entity={entity} />
            <Group w="100%" position="apart" className='tabsGapsActivity'>
                <Group position="right">
                    <SegmentControlUpdated tabs={tabs} lockedTabs={{}} />
                </Group>
                <Group noWrap>
                    <EntityActions row={entity} primaryInput={true} extended withRedirection />
                </Group>
            </Group>
            {tabs[tab].pageRender}
        </Stack>
    )
}