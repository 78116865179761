export const tabs = {
  facility: (id: string, status: string) =>
    `/dealmanagement/facilities/${id}/${status}`,
  loans: (id: string, status: string) =>
    `/dealmanagement/facilities/${id}/${status}/loans`,
  lettersOfCredit: (id: string, status: string) =>
    `/dealmanagement/facilities/${id}/${status}/locs`
}

export const lockedTabs = {}

export const activeTabs = {
  tab: tabs.facility,
  locked: '',
}
