import { Group } from '@mantine/core'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'


export default function TradeClosingHeader() {
  const navigate = useNavigate()

  function navigateToTradeClosingNew() {
    navigate('/tradeclosing/new-assignment')
  }

  return (
    <Group position="apart" style={{ marginBottom: '15px' }}>
      <PageTitle>Trade Closing</PageTitle>
      <Group>
        <PrimaryButton
          style={{ width: 'fit-content' }}
          onClick={navigateToTradeClosingNew}
        >
          Create Assignment
        </PrimaryButton>
      </Group>
    </Group>
  )
}
