import * as types from './types'

export const getFeesByFacility = (payload: any) => ({
  type: types.GET_FEES_BY_FACILITY,
  payload,
})

export const changeFeesForFacility = (payload: any) => ({
  type: types.CHANGE_FEES_FOR_FACILITY,
  payload,
})

export const editFees = (payload: any) => ({
  type: types.EDIT_FEES,
  payload,
})

export const deleteFees = (payload: any) => ({
  type: types.DELETE_FEES,
  payload,
})

export const resetFacilityFees = () => ({
  type: types.RESET_FACILITY_FEES,
  payload: null,
})

export const getScheduler = (payload: any) => ({
  type: types.GET_SCHEDULER,
  payload,
})

export const getAllFees = (payload: any) => ({
  type: types.GET_ALL_FEES,
  payload,
})
