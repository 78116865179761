import React, { useEffect, useState } from 'react'
import { PageLayout } from 'app/views/components/layout/page-layout'
import { Group } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { ManageEntities } from 'app/views/pages/accounts-management/entities/manage-entities'
import PrimaryButton from 'app/views/components/buttons/PrimaryButton'
import PageTitle from 'app/views/components/Headers&Text/PageTitle'
import MultiPartForm from './entities/multipart-form-modal'

export default function EntityManagementPage() {
  const [openEntity, setOpenEntity] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadEntities())
  }, [])

  return (
    <PageLayout>
      <Group position="apart" style={{ marginBottom: '15px' }}>
        <PageTitle>Entity Management</PageTitle>
        <Group style={{ paddingRight: '15px' }}>
          <PrimaryButton onClick={() => setOpenEntity(true)}>
            Create Entity
          </PrimaryButton>
        </Group>
      </Group>
      <ManageEntities />
      <MultiPartForm setOpen={setOpenEntity} open={openEntity} />
    </PageLayout>
  )
}
