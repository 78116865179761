import { PageLayout } from 'app/views/components/layout/page-layout'
import ReleaseQueueHeader from './notices/release-queue-header'
import { ReleaseQueue } from './release-queue'

export function ReleaseQueuePage() {

  return (
    <PageLayout>
      <ReleaseQueueHeader button='Create Payment' />
      <ReleaseQueue />
    </PageLayout>
  )
}
