import { Group, SegmentedControl, Stack, SegmentedControlItem } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

type TabItem = {
  route: string;
  label: string;
  active?: boolean;  // Add the 'active' property
};

type Props = {
  tabs: Record<string, TabItem>;
  lockedTabs: {
    active?: string;
    terminated?: string;
  };
};

export default function SegmentControlUpdated({ tabs, lockedTabs }: Props) {
  const navigate = useNavigate();

  // Prepare the data for the SegmentedControl
  const tabData: SegmentedControlItem[] = Object.entries(tabs).map(([, value]) => ({
    value: value.route,
    label: value.label,
  }));

  const lockedTabsData: SegmentedControlItem[] = Object.entries(lockedTabs).map(([key, value]) => ({
    value: value ?? '',
    label: key.charAt(0).toUpperCase() + key.slice(1),
  }));

  // Find the active tab by checking the 'active' property
  const activeTabRoute = Object.values(tabs).find((tab) => tab?.active === true)?.route || '';

  function changeTab(e: string): void {
    navigate(e + (lockedTabs.active || ''));
  }

  function changeLockedTab(e: string): void {
    navigate(activeTabRoute + e);
  }

  return (
    <Stack align="stretch" w="100%">
      <Group noWrap w="100%">
        <SegmentedControl
          size="lg"
          onChange={changeTab}
          value={activeTabRoute}  // Use the active tab route from the updated logic
          data={tabData}
          classNames={{
            root: 'segmented-root',
            label: 'segmented-label',
            indicator: 'segmented-indicator',
            controlActive: 'segmented-control-active',
            control: 'segmented-control',
          }}
        />

        <SegmentedControl
          className='segmented-control-updated'
          size="lg"
          onChange={changeLockedTab}
          value={lockedTabs.active}
          data={lockedTabsData}
          classNames={{
            root: 'segmented-root',
            label: 'segmented-label',
            indicator: 'segmented-indicator',
            controlActive: 'segmented-control-active',
            control: 'segmented-control',
          }}
        />
      </Group>
    </Stack>
  );
}
