import { PageLayout } from 'app/views/components/layout/page-layout'
import FacilityLocContent from './locs-page'

export default function FacilityLoCsPage() {
  return (
    <PageLayout>
      <FacilityLocContent />
    </PageLayout>
  )
}
