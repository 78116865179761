import * as types from './types'

export const getLoanRateSettings = (payload: any) => ({
  type: types.GET_LOAN_RATE_SETTINGS,
  payload,
})

export const getApprovedLoanRateSettings = (payload: any) => ({
  type: types.GET_APPROVED_LOAN_RATE_SETTINGS,
  payload,
})

export const addLoanRateSetting = (payload: any) => ({
  type: types.ADD_LOAN_RATE_SETTING,
  payload,
})

export const editLoanRateSetting = (payload: any) => ({
  type: types.EDIT_LOAN_RATE_SETTING,
  payload,
})

export const submitLoanRateSetting = (payload: any) => ({
  type: types.SUBMIT_LOAN_RATE_SETTING,
  payload,
})

export const approveLoanRateSetting = (payload: any) => ({
  type: types.APPROVE_LOAN_RATE_SETTING,
  payload,
})

export const rejectLoanRateSetting = (payload: any) => ({
  type: types.REJECT_LOAN_RATE_SETTING,
  payload,
})

export const deleteLoanRateSetting = (payload: any) => ({
  type: types.DELETE_LOAN_RATE_SETTING,
  payload,
})
