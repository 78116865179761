import { useEffect, useState } from 'react'
import { Stack } from '@mantine/core'
import KPI_Section from 'app/views/components/KPI/KPI-horizontal-section'
import { columnDefs } from './deals-table-columnDefs'
import { useDispatch, useSelector } from 'react-redux'
import { loadEntities } from 'app/state/ducks/entities/thunks'
import { useNavigate } from 'react-router-dom'
import { DealParams } from 'app/models/deal-params'
import { getEntities } from 'app/state/ducks/entities/selectors'
import { getDeals, getDealLoader } from 'app/state/ducks/deals/selectors'
import { getDealAmendments, getDealAmendmentLoader } from 'app/state/ducks/deal-amendments/selectors'
import { getKPIData } from './deals-kpi-data'
import { mapEntityIdsToNames } from 'app/utils/util-functions'
import cleanUUID from 'app/views/components/functions/cleanUUID'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'

export default function DealManagmentPage() {
  const allDeals = useSelector(getDeals)
  const dealLoader = useSelector(getDealLoader)
  const dealAmendmentLoader = useSelector(getDealAmendmentLoader)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [deals, setDeals] = useState<DealParams[]>([])
  const dealAmendments = useSelector(getDealAmendments)
  const [filteredDeals, setFilteredDeals] = useState<DealParams[]>(deals)
  const dispatch = useDispatch()
  const entities = useSelector(getEntities)
  const navigate = useNavigate()
  const kpiData = getKPIData(deals, setFilteredDeals)

  useEffect(() => {
    dispatch(loadEntities())
  }, [])

  useEffect(() => {
    setGlobalLoading(dealLoader || dealAmendmentLoader)
  }, [dealLoader, dealAmendmentLoader])

  useEffect(() => {
    setDeals(allDeals)
    setFilteredDeals(allDeals)
    if (
      allDeals &&
      dealAmendments &&
      allDeals.length > 0 &&
      dealAmendments.length > 0
    ) {
      const amendedDeals = allDeals.map(deal => {
        const amendment = dealAmendments.find(amendment => {
          if (amendment.id === deal.id) {
            return amendment
          }
        })
        if (amendment) {
          deal = amendment
          deal.amendment = true
        }
        return deal
      })
      setDeals(amendedDeals)
      setFilteredDeals(amendedDeals)
    }
  }, [allDeals, dealAmendments])

  // Function to filter and update entity and borrowers names
  function cleanDealData(
    filteredDeals: DealParams[],
  ): DealParams[] {
    const entityIdToName = mapEntityIdsToNames(entities);

    return (
      filteredDeals.map((deals) => {
        const adminEntityName = deals.adminEntity?.id
          ? entityIdToName.get(cleanUUID(deals.adminEntity.id)) ?? ''
          : '';
        const borrowerNames = deals.borrowers.map((borrower: { id: string }) => {
          return entityIdToName.get(cleanUUID(borrower.id)) ?? '';
        });

        return {
          ...deals,
          adminEntityName,
          borrowerNames,
        };
      }).sort((a, b) => a.dealName.localeCompare(b.dealName)) ?? []
    );
  }

  return (
    <Stack>
      <KPI_Section
        kpiData={kpiData}
        isLoading={globalLoading}
      />

      {columnDefs.length != 0 ? (
        <TableColumnClickExclusion
          tableName='Deals Table'
          isLoading={globalLoading}
          withPagination={true}
          csvExportEnabled={true}
          enableTopToolbar={false}
          headerBackgroundColor='#F0EEEE'
          columnDefs={columnDefs}
          data={cleanDealData(filteredDeals)}
          setRow={(e: any, cellName: string) => {
            if (cellName != 'id') {
              navigate('/dealmanagement/deal/' + e.id + '/' + e.status)
            }
          }}
        />
      ) : null}
    </Stack>
  )
}
