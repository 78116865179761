import * as types from './types'

export const getFeePayments = (payload: any) => ({
  type: types.GET_FEE_PAYMENTS,
  payload,
})

export const addFeePayment = (payload: any) => ({
  type: types.ADD_FEE_PAYMENT,
  payload,
})

export const approveFeePayment = (payload: any) => ({
  type: types.APPROVE_FEE_PAYMENT,
  payload,
})
export const deleteFeePayment = (payload: any) => ({
  type: types.DELETE_FEE_PAYMENT,
  payload,
})
export const submitFeePayment = (payload: any) => ({
  type: types.SUBMIT_FEE_PAYMENT,
  payload,
})
export const rejectFeePayment = (payload: any) => ({
  type: types.REJECT_FEE_PAYMENT,
  payload,
})
export const editFeePayment = (payload: any) => ({
  type: types.EDIT_FEE_PAYMENT,
  payload,
})
