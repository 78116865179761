import { CloseButton, Group, Stack, TextInput } from '@mantine/core'
import { useState } from 'react'
import { TableData, jobsComponentCols } from './job-log-table-columnDefs'
import TableColumnClickExclusion from 'app/views/components/Table2/TableColumnClickExclusion'
import CheckboxGroup from './checkbox-group'
import FilterMenu from './filter-menu'
import IonIcon from '@reacticons/ionicons'


interface Props {
  // leave any for now until we have a better understanding of the data
  index?: any
  closeJobExpand: () => void
}

type CheckboxState = {
  [key: string]: boolean;
};

const jobLogStyle = {
  backgroundColor: '#A4CAFE',
  color: 'black',
  padding: '10px',
  marginTop: '10px',
  height: "35px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
};

export function JobsExpand({ closeJobExpand }: Props) {
  const checkboxes = ['Accruals', 'Accounting', 'Feeds', 'Fed Feeds', 'Reporting', 'Scripts']
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState('All');
  const [tableData,] = useState<TableData[]>([])


  // Initialize state with all checkboxes set to false
  const [, setCheckedState] = useState<CheckboxState>(
    checkboxes.reduce((acc: CheckboxState, checkbox) => {
      acc[checkbox] = false;
      return acc;
    }, {})
  );

  const handleCheckboxChange = (newCheckedState: Record<string, boolean>) => {
    setCheckedState(newCheckedState);
  };


  return (
    <Stack>
      <Group grow spacing="40%">
        <div style={jobLogStyle}>
          <h6 style={{ margin: 0 }}>Job Logs</h6>
          <CloseButton onClick={closeJobExpand} size="lg" />
        </div>
      </Group>
      <CheckboxGroup options={['Accruals', 'Accounting', 'Feeds', 'Fed Feeds', 'Reporting', 'Scripts']} onCheckedChange={handleCheckboxChange} />
      <Group grow spacing="40%">
        <div style={jobLogStyle}>
          <h6 style={{ margin: 0 }}>Job Details</h6>
        </div>
      </Group>
      <Group noWrap mb="10px" mt="5px" onClick={e => e.stopPropagation()}>
        <TextInput
          w="50%"
          rightSection={<IonIcon size='large' name='search-outline' aria-disabled />}
          size="lg"
          value={search}
          onChange={e => {
            setSearch(e.target.value)
          }}
        />
        <FilterMenu selected={selected} onSelect={setSelected} />
      </Group>
      <TableColumnClickExclusion
        tableName='Job Table'
        withPagination={false}
        columnDefs={jobsComponentCols}
        data={tableData ?? []}
        setRow={(e: any, cellName: string) => {
          if (cellName != 'id') {
            //   navigate('/entitymanagement/entity/' + e.id + '/' + e.status)
          }
        }}
      />
    </Stack>
  )
}