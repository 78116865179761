import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Signup from '../app/views/pages/signup'
import { ProtectedRoute } from '../app/views/components/protected-pages-components/+protected-route'
import { NotFoundPage } from '../app/views/pages/error/+not-found-page'
import ProfilePage from '../app/views/pages/profile/+profile-page'
import UsersPage from '../app/views/pages/users/+users-page'
import EntityManagementPage from '../app/views/pages/accounts-management/+account-management-page'
import { ReleaseQueuePage } from '../app/views/pages/release-queue/+release_queue_page'
import UserManagementPage from 'app/views/pages/user-management/+user-management-page'
import { ServicingPage } from '../app/views/pages/servicing/+servicing_page'
import { TradeClosingPage } from '../app/views/pages/trade-closing/+trade-closing-page'
import { ReportingPage } from 'app/views/pages/reports/+reports_page'
import SingleTradeAssignmentPage from 'app/views/pages/trade-closing/single-trade-assignment/+single-trade-assignment-page'
import IndexRateOptionManagementPage from 'app/views/pages/index-rate-options-management/+iro-management-page'
import FeesPage from 'app/views/components/Fees/+manage-fees-facility-page'
import HolidayCalendarsPage from 'app/views/pages/holiday-calendars/+holiday-calendars-page'
import AmortizationSchedulePage from 'app/views/components/amortization-scheduler/manage-amortization-page'
import SimulationsPage from 'app/views/pages/simulations/+simulations_page'
import JobManagementPage from 'app/views/pages/job-management/+job-management-page'
import InterestPaymentSchedulePage from 'app/views/components/interest-payment-schedule/+manage-interest-payment-page'
import PIKSchedulerPage from 'app/views/components/pik-scheduler/+manage-pik-page'
import NoticePreview from 'app/views/pages/notices/preview/+notice-preview-page'
import { ServicingScheduledPage } from 'app/views/pages/servicing/servicing-scheduled/+servicing-scheduled-page'
import { ServicingUnscheduledPage } from 'app/views/pages/servicing/servicing-unscheduled/+servicing-unscheduled-page'
import { NoticesPage } from 'app/views/pages/release-queue/notices/+realease-queue-notices-page'
import { LockedNoticesPage } from 'app/views/pages/release-queue/locked-notices/+realease-queue-locked-notices-page'
import { LockedReleaseQueuePage } from 'app/views/pages/release-queue/locked-release-queue/+locked-release-queue-page'
import { MyWorkLandingPage } from 'app/views/pages/mywork/+mywork_landing_page'
import { PositionsLandingPage } from 'app/views/pages/positions/+positions_landing_page'
import { EntityLandingPage } from 'app/views/pages/accounts-management/entities/entity-details/+entity_landing_page'
import { DealManagmentPage } from 'app/views/pages/deals-management/deals-management-landing-components/+deals-management-page'
import { LockedDealManagmentPage } from 'app/views/pages/deals-management/deals-management-landing-components/+locked-deals-management-page'
import SingleDealPage from 'app/views/pages/deals-management/deals/deal-details/+deal-page'
import { PricingGridPage } from 'app/views/pages/deals-management/pricing-grid/+pricing-grid-page'
import { DealFeesPage } from 'app/views/pages/deals-management/deal-fees/+deal-fees-page'
import SingleFacilityPage from 'app/views/pages/deals-management/facilities/facility-details/+facility-page'
import FacilityLoansPage from 'app/views/pages/deals-management/loans/facility-loans/+facility-loans-page'
import FacilityLoCsPage from 'app/views/pages/deals-management/letter-of-credit/facility-loc/+facility-locs-page'
import LenderAllocationPage from 'app/views/pages/deals-management/lender-allocations/+funds-page'
import LenderAllocationFundManagerPage from 'app/views/pages/deals-management/lender-allocations/+fund-manager-page'
import BorrowersPage from 'app/views/pages/deals-management/facilities/facility-details/+borrowers-page'
import BorrowersPageDeal from 'app/views/pages/deals-management/deals/deal-details/+borrowers-deal-page'
import GuarantorsPageDeal from 'app/views/pages/deals-management/deals/deal-details/+guarantors-deal-page'

export function RightPedalRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/mywork" replace={true} />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/profile"
          element={<ProtectedRoute component={ProfilePage} />}
        />
        <Route
          path="/users"
          element={<ProtectedRoute component={UsersPage} />}
        />
        <Route
          path="/mywork"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="todo" />}
        />
        <Route
          path="/mywork/todo"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="todo" />}
        />
        <Route
          path="/mywork/payments"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="payments" />}
        />
        <Route
          path="/mywork/unscheduled"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="unscheduled" />}
        />
        <Route
          path="/mywork/scheduled"
          element={<ProtectedRoute component={MyWorkLandingPage} tab="scheduled" />}
        />
        <Route
          path="/entitymanagement"
          element={<ProtectedRoute component={EntityManagementPage} />}
        />
        <Route
          path="/entitymanagement/entity/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityBase" />}
        />
        <Route
          path="/entitymanagement/contacts/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityContacts" />}
        />
        <Route
          path="/entitymanagement/wireinstructions/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityWI" />}
        />
        <Route
          path="/entitymanagement/positions/:entityId/:status"
          element={<ProtectedRoute component={EntityLandingPage} tab="entityPositions" />}
        />
        <Route
          path="/positions"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsDeal" />}
        />
        <Route
          path="/positions/deal"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsDeal" />}
        />
        <Route
          path="/positions/facility"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsFacility" />}
        />
        <Route
          path="/positions/entity"
          element={<ProtectedRoute component={PositionsLandingPage} tab="positionsEntity" />}
        />

        <Route
          path="/dealmanagement/deal/:dealId/:status"
          element={<ProtectedRoute component={SingleDealPage} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/:status/pricing-grid"
          element={<ProtectedRoute component={PricingGridPage} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/:status/fees/:feeType"
          element={<ProtectedRoute component={DealFeesPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status"
          element={<ProtectedRoute component={SingleFacilityPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/loans"
          element={<ProtectedRoute component={FacilityLoansPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/locs"
          element={<ProtectedRoute component={FacilityLoCsPage} />}
        />
        <Route
          path="/dealmanagement/:contractType/:contractId/:status/lenders"
          element={<ProtectedRoute component={LenderAllocationPage} />}
        />
        <Route
          path="/dealmanagement/:contractType/:contractId/:status/lenders/fund-manager"
          element={<ProtectedRoute component={LenderAllocationFundManagerPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/fees"
          element={<ProtectedRoute component={FeesPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/fees/:feeType"
          element={<ProtectedRoute component={FeesPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/amortization-schedule"
          element={<ProtectedRoute component={AmortizationSchedulePage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/:loanId/:loanStatus/interest-payment-schedule"
          element={<ProtectedRoute component={InterestPaymentSchedulePage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/:status/:loanId/:loanStatus/pik-schedule"
          element={<ProtectedRoute component={PIKSchedulerPage} />}
        />
        <Route
          path="/dealmanagement/facilities/:facilityId/borrowers"
          element={<ProtectedRoute component={BorrowersPage} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/borrowers"
          element={<ProtectedRoute component={BorrowersPageDeal} />}
        />
        <Route
          path="/dealmanagement/deal/:dealId/guarantors"
          element={<ProtectedRoute component={GuarantorsPageDeal} />}
        />
        <Route
          path="/dealmanagement"
          element={<ProtectedRoute component={DealManagmentPage} tab="deals" />}
        />
        <Route
          path="/dealmanagement/facilities"
          element={<ProtectedRoute component={DealManagmentPage} tab="facilities" />}
        />
        <Route
          path="/dealmanagement/terminated"
          element={<ProtectedRoute component={LockedDealManagmentPage} tab="dealsLocked" />}
        />
        <Route
          path="/dealmanagement/facilities/terminated"
          element={<ProtectedRoute component={LockedDealManagmentPage} tab="facilitiesLocked" />}
        />

        <Route
          path="/releasequeue"
          element={<ProtectedRoute component={ReleaseQueuePage} />}
        />
        <Route
          path="/releasequeue/archived"
          element={<ProtectedRoute component={LockedReleaseQueuePage} />}
        />
        <Route
          path="/releasequeue/notices"
          element={<ProtectedRoute component={NoticesPage} />}
        />
        <Route
          path="/releasequeue/notices/archived"
          element={<ProtectedRoute component={LockedNoticesPage} />}
        />
        <Route
          path="/releasequeue/notice-preview/:id"
          element={<ProtectedRoute component={NoticePreview} />}
        />
        <Route
          path="/assetservicing"
          element={<ProtectedRoute component={ServicingPage} />}
        />
        <Route
          path="/assetservicing/scheduled"
          element={<ProtectedRoute component={ServicingScheduledPage} />}
        />
        <Route
          path="/assetservicing/unscheduled"
          element={<ProtectedRoute component={ServicingUnscheduledPage} />}
        />
        <Route
          path="/tradeclosing"
          element={<ProtectedRoute component={TradeClosingPage} />}
        />
        <Route
          path="/tradeclosing/parent"
          element={<ProtectedRoute component={TradeClosingPage} />}
        />
        <Route
          path="/tradeclosing/subtrade"
          element={<ProtectedRoute component={TradeClosingPage} />}
        />
        <Route
          path="/tradeclosing/new-assignment"
          element={<ProtectedRoute component={SingleTradeAssignmentPage} />}
        />
        <Route
          path="/tradeclosing/assignment/:status/:id"
          element={<ProtectedRoute component={SingleTradeAssignmentPage} />}
        />
        <Route
          path="/reports"
          element={<ProtectedRoute component={ReportingPage} />}
        />
        <Route
          path="/usermanagement"
          element={<ProtectedRoute component={UserManagementPage} />}
        />
        <Route
          path="/indexrateoptions"
          element={<ProtectedRoute component={IndexRateOptionManagementPage} />}
        />
        <Route
          path="/holidaycalendars"
          element={<ProtectedRoute component={HolidayCalendarsPage} />}
        />
        <Route
          path="/simulations"
          element={<ProtectedRoute component={SimulationsPage} />}
        />
        <Route
          path="/jobmanagement"
          element={<ProtectedRoute component={JobManagementPage} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}