import { Group, ScrollArea, Select, Stack } from '@mantine/core'
import CustomDatePicker from '../date-picker/date-picker-logic'
import { useForm, isNotEmpty } from '@mantine/form'
import { frequencyOptions } from 'app/models/dropdown-options'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ErrorNotification,
  SuccessNotification,
} from '../notifications/notification'
import { saveFacilityAmendment } from 'app/state/ducks/facility-amendments/thunks'
import { getBusinessDate } from 'app/state/ducks/business-date/selectors'
import { PikScheduler } from 'app/models/facility-params'
import { saveFacility } from 'app/state/ducks/facilities/thunks'
import { calculatePikScheduler, formatDateToUTC, stringToDate } from 'app/utils/util-functions'
import Table2 from '../Table2/Table2'
import { getColumnDefs } from './pik-scheduler-colum-def'
import FormWrapper from '../Form/FormWrapper'

type Props = {
  facility: any
  setFacility: any
  amendment?: string | null | undefined
}

export interface ChildPikSchedulerRef {
  handleClick: () => void
}

const ManagePikScheduler: React.ForwardRefRenderFunction<
  ChildPikSchedulerRef,
  Props
> = ({ facility, setFacility, amendment }, ref) => {
  const businessDate = useSelector(getBusinessDate)
  const updatedFrequencyOptions = frequencyOptions.slice(0, -1);

  const dispatch = useDispatch()
  const maturityDate: Date = facility?.maturity
    ? stringToDate(facility?.maturity)
    : businessDate
  const [facilityPikScheduler, setFacilityPikScheduler] = useState<PikScheduler>();
  const [pikScheduler, setPikScheduler] = useState<{ pikDate: string | Date }[]>([]);

  const form = useForm({
    initialValues: {
      pikFrequency: facilityPikScheduler?.pikFrequency ?? '',
      firstPikDate:
        facilityPikScheduler?.firstPikDate &&
          facilityPikScheduler?.firstPikDate != ''
          ? stringToDate(facilityPikScheduler?.firstPikDate)
          : businessDate,
    },

    validate: {
      pikFrequency: isNotEmpty('Frequency cannot be empty'),
      firstPikDate: value =>
        isNotEmpty('Pik date cannot be empty') && value <= maturityDate
          ? null
          : 'Must Be Before Maturity Date',
    },
    transformValues: values => ({
      ...values,
      firstPikDate: formatDateToUTC(values.firstPikDate)
    }),
  })

  useEffect(() => {
    setFacilityPikScheduler(facility?.pikScheduler);
  }, [facility])

  useEffect(() => {
    if (!facilityPikScheduler) {
      return
    }
    form.setValues({
      pikFrequency: facilityPikScheduler?.pikFrequency ?? '',
      firstPikDate:
        facilityPikScheduler?.firstPikDate &&
          facilityPikScheduler?.firstPikDate != ''
          ? stringToDate(facilityPikScheduler?.firstPikDate)
          : businessDate,
    });
  }, [facilityPikScheduler]);

  useEffect(() => {
    if (!form.values.pikFrequency || !form.values.firstPikDate) {
      return
    }
    setPikScheduler(calculatePikScheduler(form.values.pikFrequency, form.values.firstPikDate, maturityDate))
  }, [form.values])

  const handleClick = async () => {
    let response: any
    const transformedPikScheduler = form.getTransformedValues()

    if (amendment) {
      response = await dispatch(
        saveFacilityAmendment(
          {
            ...facility,
            amendmentDate: facility.amendmentDate ?? formatDateToUTC(businessDate),
            amendmentAmortization: facility.amendmentAmortization ?? null,
            amendmentFees: facility.amendmentFees ?? [],
            amendmentLenders: facility.amendmentLenders ?? [],
            pikScheduler: transformedPikScheduler,
          },
          facility.amendmentType ? facility : null
        )
      )
    } else {
      response = await dispatch(
        saveFacility(
          {
            ...facility,
            pikScheduler: transformedPikScheduler,
          },
          facility.id
        )
      )
    }
    if (response?.success === false) {
      ErrorNotification({
        title: ' Facility Action Failed',
        message: response?.payload || 'Check Fields and try again',
      })
      return
    }

    if (
      Object.keys(response.payload).length !== 0 ||
      response?.success === true
    ) {
      setFacility(response.payload)
      SuccessNotification({
        title: 'Successful Facility Creation',
        message:
          'You can add Lender Allocations, Interest Rate Options, Fees and Amortization Schedules',
      })
    }
  }

  // Expose the handleClick method to the parent component
  useImperativeHandle(ref, () => ({
    handleClick,
  }))


  return (
    <FormWrapper title={''}>
      <Stack>
        <Group noWrap w="100%">
          <Select
            w="100%"
            searchable
            withAsterisk
            label="Frequency"
            placeholder="Select Frequency"
            data={updatedFrequencyOptions}
            {...form.getInputProps('pikFrequency')}
          />
          <CustomDatePicker
            w="100%"
            label={'First PIK Date'}
            date={form.values.firstPikDate}
            setDate={(value: any) =>
              form.setFieldValue('firstPikDate', value)
            }
            holidayCalendars={
              facility?.holidayCalendar ?? []
            }
          />
        </Group>

        <Group noWrap w="100%">
          <ScrollArea h={900} type="always">
            <Table2
              withPagination={false}
              enableColumnOrdering={false}
              enableGrouping={false}
              enableColumnFilters={false}
              enableColumnActions={false}
              enableFilters={false}
              enableSorting={false}
              enableFullScreenToggle={false}
              columnDefs={getColumnDefs()}
              data={pikScheduler}
              setRow={() => { }}
            />
          </ScrollArea>
        </Group>
      </Stack>
    </FormWrapper>
  )
}

export default React.forwardRef(ManagePikScheduler)
